import {useState,useEffect}from 'react';
import axiosInstance from '../utils/axios';


export const usePaginate=(data,setData,itemsPerPage,url)=>{
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading,setIsLoading]=useState(false);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(()=>{
        setIsLoading(true);
        const getAllData =async()=>{
          const response =await axiosInstance(url+`?page=${currentPage}&limit=${itemsPerPage}`);
          if (response.status ===200){
            setData(response.data.data.results)
            setTotalPages(response.data.data.totalPages);

            setIsLoading(false)
          }
    
        }
        getAllData()
    
      },[setData,itemsPerPage,currentPage])

      const handlePageChange = (event, value) => {
        setCurrentPage(value);
      };




    return {data,itemsPerPage,handlePageChange,totalPages,isLoading,currentPage}
}
