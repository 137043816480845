import React, { useState } from 'react';
import New from './New';
import Unverified from './Unverified';
import Verified from './Verified';
import Deleted from './Deleted';
import { Tab, Tabs, Paper } from '@mui/material';

const Components = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Paper elevation={3} style={{ marginTop: '1rem' }}>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor='primary'
        textColor='primary'
        // centered
      >
        <Tab label={<span className='New'>New</span>} />
        <Tab label={<span className='Verified'>Verified</span>} />
        <Tab label={<span className='Rejected'>Rejected</span>} />
        {/* <Tab label={<span className='Deleted'>Deleted</span>} /> */}
      </Tabs>

      {selectedTab === 0 && (
        <div>
          <New />
        </div>
      )}
      {selectedTab === 1 && (
        <div>
          <Verified />
        </div>
      )}
      {selectedTab === 2 && (
        <div>
          <Unverified />
        </div>
      )}
    </Paper>
  );
};

export default Components;
