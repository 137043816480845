import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { toast } from 'react-toastify';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

const options = ['admin', 'client', 'employee'];
const userType = ['individual', 'organization'];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const UpdateModal = ({
  users,
  setUsers,
  update_id,
  toggleShowModal,
  showUpdateModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUsersNames] = useState(update_id.username);
  const [emails, setEmails] = useState(update_id.email);
  const [roles, setRoles] = useState();
  const [errors, setErrors] = useState([]);
  const [user_type, setUserType] = useState();
  const [limit_credits, setLimitCredits] = useState();

  const handleSubmit = (e) => {
    // console.log(update_id._id);
    // console.log('val', e.target.value);

    axiosInstance
      .put(`v1/users/${update_id._id}`, {
        username: userName,
        email: emails,
        role: roles,
        user_type: user_type,
        limit_credits,
      })
      .then((resp) => {
        console.log(resp);
        if (resp.status === 200) {
          const updatedUser = [...users];
          const index = updatedUser.findIndex(
            (i) => i._id === resp.data.data._id,
          );
          let {
            _id,
            username,
            email,
            role,
            updatedAt,
            createdAt,
            limit_credits,
            user_type,
            payment_type,
            email_verified,
          } = resp.data.data;
          const newall = {
            _id: _id,
            username: username,
            email: email,
            role: role,
            user_type: user_type,
            updatedAt: updatedAt,
            createdAt: createdAt,
            limit_credits: limit_credits,
            payment_type: payment_type,
            email_verified: email_verified,
          };
          updatedUser.splice(index, 1, newall);
          setUsers(updatedUser);
          toggleShowModal();
          toast.success('User updated updated');
        }
      })
      .catch((errors) => {
        console.log(errors.response.data.message);
        if (errors) {
          // setIsLoading(false);
          setErrors(errors.response.data.message);
        }
      });
  };

  return (
    <div>
      {' '}
      <Dialog
        open={showUpdateModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={toggleShowModal}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>{'Update User'}</DialogTitle>
        {isLoading ? (
          <Box style={{ position: 'relative' }}>
            <CircularProgress
              size={40}
              left={-20}
              top={10}
              status={'loading'}
              style={{ marginLeft: '50%' }}
            />
          </Box>
        ) : (
          <>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'></DialogContentText>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 2 },
                }}
              >
                <FormControl>
                  {errors.map((error) => {
                    return <Alert severity='error'>{error.message}</Alert>;
                  })}

                  <TextField
                    label='Username'
                    placeholder='Enter username'
                    type='text'
                    value={userName}
                    onChange={(e) => setUsersNames(e.target.value)}
                  />
                  <br />

                  <TextField
                    label='Email'
                    placeholder='Enter Email'
                    type='email'
                    value={emails}
                    onChange={(e) => setEmails(e.target.value)}
                  />
                  <br />

                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={roles ? roles : update_id.role}
                    label='User type'
                    onChange={(event: SelectChangeEvent) => {
                      console.log(event.target.value);
                      setRoles(event.target.value);
                    }}
                  >
                    {options.map((option) => {
                      return <MenuItem value={option}>{option}</MenuItem>;
                    })}
                  </Select>
                  <br />
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={user_type ? user_type : update_id.user_type}
                    label='User type'
                    onChange={(event: SelectChangeEvent) => {
                      console.log(event.target.value);
                      setUserType(event.target.value);
                    }}
                  >
                    {userType.map((option) => {
                      return <MenuItem value={option}>{option}</MenuItem>;
                    })}
                  </Select>
                  <br />
                  {update_id.payment_type === 'post-paid' ? (
                    <>
                      {' '}
                      <TextField
                        label='Limit Credit'
                        placeholder='Enter limit credit'
                        type='text'
                        value={
                          limit_credits
                            ? limit_credits
                            : update_id.limit_credits
                        }
                        onChange={(e) => setLimitCredits(e.target.value)}
                      />
                    </>
                  ) : null}
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSubmit}>Update</Button>
              <Button onClick={toggleShowModal}>Cancel</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default UpdateModal;
