import React from 'react';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import employeeBase from '../../../../utils/employee';
const ConfirmDelete = ({
  showDelete,
  toggleDelete,
  deleteId,
  department,
  setDepartment,
}) => {
  const token = `${process.env.REACT_APP_ACCESS_TOKEN}`;

  const confirmDelete = () => {
    employeeBase
      .post(`department/eraseDepartment?accessToken=${token}`, {
        departmentId: deleteId,
      })
      .then((resp) => {
        if (resp.status === 200) {
          const newlist = department.filter((item) => item._id !== deleteId);
          setDepartment(newlist);
          toggleDelete();
          toast.error('Department Deleted Successfully');
        }
      });
  };
  return (
    <div>
      {' '}
      <Dialog
        open={showDelete}
        onClose={toggleDelete}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Deleting event alert'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure want to delete the department?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmDelete}>Confirm</Button>
          <Button onClick={toggleDelete} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDelete;
