import React,{useState,useEffect} from 'react'
import { useFetch } from '../../../hooks/useFetch';
import {Box, Grid, Card, Container, Typography,CardHeader,CardContent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axiosInstance from '../../../utils/axios';



const Dashboard = () => {
  const [stats,setStats]=useState([]);
  // useEffect(()=>{
  //   const getStat =async()=>{
  //     const response =await axiosInstance("v1/api-keys/stats/")
  //     if (response.status ===200){
  //       setStats(response.data.data)
        
  //     }

  //   }
  //   getStat();
  //   }
  // ,[])
  const{data,isLoading}=useFetch(stats,setStats,'v1/api-keys/stats/')
  console.log(stats)
  return (
    <>
     <Box style={{position: 'relative'}}>
     <h3>Dashboard</h3>
      <Grid container alignItems="flex-end" justify="center" sx={{gap:3,marginTop:2}}>
      <Box  sx={{
            width: 200,
            height: 200,
            py:9.5,
            textAlign: 'center',
            backgroundColor: "#03a9f4",
            color:'white',
            '&:hover': {
              backgroundColor: '#0C5FCA',
              opacity: [0.9, 0.8, 0.7],
            },

          }} >
            {stats.totalKeys ?<h1>{stats.totalKeys}</h1>: null}
           <Typography
           sx={{
            
            py:5,
            textAlign: 'center'

          }}
           >Total keys</Typography>
    
    </Box>
    <Box  sx={{
            width: 200,
            height: 200,
            py:9.5,
            textAlign: 'center',
            backgroundColor: "#03a9f4",
            color:'white',
            '&:hover': {
              backgroundColor: '#0C5FCA',
              opacity: [0.9, 0.8, 0.7],
            },

          }} >
            {stats.highestCounterAlias?<h1>{stats.highestCounterAlias}</h1>:null}
           <Typography
           sx={{
            
            py:5,
            textAlign: 'center'

          }}
           >User with highest api hit</Typography>
    
    </Box>
    <Box  sx={{
            width: 200,
            height: 200,
            py:9.5,
            textAlign: 'center',
            backgroundColor: "#03a9f4",
            color:'white',
            '&:hover': {
              backgroundColor: '#0C5FCA',
              opacity: [0.9, 0.8, 0.7],
            },

          }} >
        {stats.latestCreatedAlias ?<h1>{stats.latestCreatedAlias} </h1>:null}   
           <Typography
           sx={{
            
            py:5,
            textAlign: 'center'

          }}
           > Latest Created Alias</Typography>
    
    </Box>
    
    </Grid>
      

     </Box>

    
      
    </>
  )
}

export default Dashboard