import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import employeeBase from '../../../../utils/employee';

const CreateDepartment = ({
  department,
  setDepartment,
  showDepartment,
  toggleCreateDepartment,
  setCombinedImage,
  combinedImage,
}) => {
  const [actualname, setActualName] = useState('');
  const [abbr, setAbbr] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [logo, setSelectedImage] = useState();
  const token = `${process.env.REACT_APP_ACCESS_TOKEN}`;

  const handleSubmit = () => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    employeeBase
      .post(
        `department/addDepartment?accessToken=${token}`,
        { actualname, logo, abbr },
        config,
      )
      .then((resp) => {
        console.log(resp.data.data);
        if (resp.status == 200) {
          const actualname = resp.data.data.actualname;
          const abbr = resp.data.data.abbr;
          const logo = resp.data.data.logo;
          const newList = [
            ...department,
            {
              actualname: actualname,
              abbr: abbr,
              logo: logo,
            },
          ];

          toggleCreateDepartment();
          setDepartment(newList);
          toggleCreateDepartment();
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log('error', error);
        // setErrors(error.response.data.message);
      });
  };
  return (
    <div>
      <Dialog
        open={showDepartment}
        keepMounted
        onClose={toggleCreateDepartment}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>{'Add Department'}</DialogTitle>
        {isLoading ? (
          <Box style={{ position: 'relative' }}>
            <CircularProgress
              size={40}
              left={-20}
              top={10}
              status={'loading'}
              style={{ marginLeft: '50%' }}
            />
          </Box>
        ) : (
          <>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'></DialogContentText>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 2 },
                }}
              >
                <FormControl>
                  <TextField
                    label='Actual name'
                    placeholder='Enter actual name'
                    id='demo-helper-text-aligned'
                    value={actualname}
                    onChange={(e) => setActualName(e.target.value)}
                    // error={errors.some((error) => error.path[0] === 'name')}
                    // helperText={
                    //   errors
                    //     .find((error) => error.path[0] === 'name')
                    //     ?.message.replace(/"/g, '') || ''
                    // }
                  />
                  <br />
                  <TextField
                    label='Abbr'
                    placeholder='Enter abbr'
                    id='demo-helper-text-aligned'
                    value={abbr}
                    onChange={(e) => setAbbr(e.target.value)}
                    // error={errors.some(
                    //   (error) => error.path[0] === 'description',
                    // )}
                    // helperText={
                    //   errors
                    //     .find((error) => error.path[0] === 'description')
                    //     ?.message.replace(/"/g, '') || ''
                    // }
                  />
                  <br />
                  <input
                    type='file'
                    accept='image/*'
                    onChange={(e) => setSelectedImage(e.target.files[0])}
                  />
                  <br />

                  {logo && (
                    <img
                      src={URL.createObjectURL(logo)}
                      alt='Selected Image'
                      style={{ maxWidth: '100%' }}
                    />
                  )}
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSubmit}>Create</Button>
              <Button onClick={toggleCreateDepartment}>Cancel</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default CreateDepartment;
