import React,{useState} from 'react'
import{Avatar, Box,Typography,Container,Button,CssBaseline,TextField,FormControlLabel,Checkbox,Link,Grid} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import image from "./Galli_color.png"
import axiosBase from "../utils/axios"
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" marginTop="3rem">
      {'Copyright © Galli Express Private Limited 2023 '}
      <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem',marginTop:".25rem"  }}>
        <Link href="https://gallimaps.com/">Visit Website</Link>
        <Link href="https://gallimap.com/terms.html">Terms and Conditions</Link>
        <Link href="https://gallimap.com/privacy.html">Privacy Policy</Link>
      </div>
    </Typography>
  );
}

const theme = createTheme();

const ForgotPassword = () => {
    const [email,setEmail]=useState('');
    const [error,setError]=useState('');
    const navigate  =useNavigate();


    const handleSubmit =(evt)=>{
        evt.preventDefault();
        axiosBase.post("v1/forgot-password", { email }).then(resp=>{
          if(resp.status===200){
            toast.success('Reset link send Sucessfully in email')
            setTimeout(() => {
              navigate('/')
  
              window.location.reload();
            }, 3000);
    

    
          }
         
        }).catch(errors => {
        //   console.log(errors.response.data.message)
          setError(errors.response.data.message)
    
        })
      }

  return (
    <div>
 <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1,width: 100, height: 100  }} src={image}>
          </Avatar>
          <Typography variant="subtitle1" component="h3">
             Enter email address to reset password
         </Typography>
          
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="Email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
            />
            {error?<Alert severity="error">{error}</Alert>:null}
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 ,bgcolor:"#e37547 ","&:hover":{bgcolor:"#812c19"}}}
              
            >
               Submit
            </Button>
            <Grid container>
              <Grid item xs>  
                <Link href="/" variant="body2">
                  Back to Login
                </Link>
              </Grid>
             
            </Grid>
          
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>    </div>
  )
}

export default ForgotPassword