import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import employeeBase from '../../../../utils/employee';

const RejectBusiness = ({
  detailData,
  showRejectBusiness,
  toggleRejectBusiness,
}) => {
  const token = `${process.env.REACT_APP_ACCESS_TOKEN}`;
  //   console.log('detailData', detailData._id);
  const confirm = () => {
    employeeBase
      .post(`business/removeBusiness/${detailData._id}?accessToken=${token}`)
      .then((resp) => {
        if (resp) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <Dialog
        open={showRejectBusiness}
        onClose={toggleRejectBusiness}
        // maxWidth='md'
        fullWidth
      >
        <DialogTitle>Reject the business</DialogTitle>
        <DialogContent>
          Are you sure want to reject the business ?
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleRejectBusiness}>Cancel</Button>
          <Button onClick={confirm} color='primary'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RejectBusiness;
