import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import employeeBase from '../../../../utils/employee';
const InspectPlace = ({
  detailData,
  rejected,
  setRejected,
  requests,
  setRequests,
  verified,
  setVerified,
  setTotalVerifiedResults,
  totalVerifiedResults,
  setTotalRejectedResults,
  totalRejectedResults,
  totalResults,
  setTotalResults,
  toggleInspect,
  showInspectPlace,
  totalInspectResults,
  setTotalInspectResults,
  inspect,
  setInspect,
}) => {
  const token = `${process.env.REACT_APP_ACCESS_TOKEN}`;
  const confirm = () => {
    employeeBase
      .post(`place/inquirePlace/${detailData._id}?accessToken=${token}`)
      .then((resp) => {
        if (resp.status === 200) {
          if (detailData.fromrequest === true) {
            const filtered = requests.filter((i) => i._id !== detailData._id);
            setRequests(filtered);
            const addeedInspected = [...inspect, detailData];
            setInspect(addeedInspected);
            setTotalResults(totalResults - 1);
            setTotalInspectResults(totalInspectResults + 1);
          } else if (detailData.fromrejected === true) {
            const filtered = rejected.filter((i) => i._id !== detailData._id);
            setRejected(filtered);
            const addeedInspected = [...inspect, detailData];
            setInspect(addeedInspected);
            setTotalInspectResults(totalInspectResults + 1);
            setTotalRejectedResults(totalRejectedResults - 1);
          }
          toggleInspect();
        }
      });
  };
  return (
    <div>
      {' '}
      <Dialog
        open={showInspectPlace}
        onClose={toggleInspect}
        // maxWidth='md'
        fullWidth
      >
        <DialogTitle>Inspect the place</DialogTitle>
        <DialogContent>
          Are you sure want to Inspect the place for the addition of places in
          the map?
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleInspect}>Cancel</Button>
          <Button onClick={confirm} color='primary'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InspectPlace;
