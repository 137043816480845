import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const UpdateModal = ({plans,setPlans,showUpdateModal,update_id,toggleShowModal}) => {
  const [name,setName]=useState();
  const [description,setDescription]=useState();
  const [credit_points,setCreditPoints]=useState();
  const [free_credit_points,setFreeCreditPoints]=useState();
  const [isLoading,setIsLoading]=useState(false);
  const [errors, setErrors] = useState([]);
  const [price,setPrices]=useState();

  const handleSubmit = () => {
    axiosInstance.put(`/v1/plans/${update_id._id}`, {name,description,credit_points,free_credit_points,price}).then(resp => {
      setIsLoading(true);
      if (resp.status===200) {
        setIsLoading(false);

        const updatePlans = [...plans];
        const index = updatePlans.findIndex(i=>i._id===resp.data.data._id)
        
        let {_id,name,description,price,createdAt,updatedAt,credit_points,free_credit_points}=resp.data.data;
        const newall={
          _id:_id,
          name: name,
          description:description,
          price: price,
          credit_points:credit_points,
          free_credit_points:free_credit_points,
          createdAt:createdAt,
          updatedAt:updatedAt,
        }
        updatePlans.splice(index,1,newall);
        setPlans(updatePlans);
        toggleShowModal();
        toast.success('Service updated')
      }
    }).catch(errors => {
      // console.log(errors.response.data.message);
      if (errors) {
        setIsLoading(false);

        setErrors(errors.response.data.message)
      }

    })
  }
  return (
    <div>  <Dialog
    open={showUpdateModal}
    TransitionComponent={Transition}
    keepMounted
    onClose={toggleShowModal}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle>{"Update Service"}</DialogTitle>
    {isLoading?<Box style={{position: 'relative'}}>
       <CircularProgress
       size={40}
       left={-20}
       top={10}
       status={'loading'}
       style={{marginLeft: '50%'}}
       />
     </Box>:<>
      <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
      </DialogContentText>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > :not(style)': { m: 2 },
          }}
        >
          <FormControl>
            {errors.map(error => {
              return <Alert severity="error">{error.message}</Alert>

            })}



            <TextField
             label="Name"               
             placeholder='Enter name'
              id="demo-helper-text-aligned"
              value={name?name:update_id.name}
              onChange={(e)=>setName(e.target.value)}
            />
            <br/>

            <TextField
               label="Description"               
              placeholder='Enter description'
              id="demo-helper-text-aligned-no-helper"
              value={description?description:update_id.description}
              onChange={(e)=>setDescription(e.target.value)}

            />
            <br/>
            <TextField
             placeholder='Enter price'
             label="Price"
              id="demo-helper-text-aligned-no-helper"
              value={price?price:update_id.price}
              onChange={(e)=>setPrices(e.target.value)}

            />
            <br/>
             <TextField
               placeholder='Enter Credit points'
               label="Credit points"
               id="demo-helper-text-aligned-no-helper"
               value={credit_points?credit_points:update_id.credit_points}
               onChange={(e) => setCreditPoints(e.target.value)}

             />
           
           
          </FormControl>


        </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleSubmit}>Update</Button>
      <Button onClick={toggleShowModal}>Cancel</Button>
    </DialogActions>
    </>
    }
   
  </Dialog></div>
  )
}

export default UpdateModal