import React from 'react';
import axiosInstance from '../../../utils/axios';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DeleteModal = ({
  keys,
  showDeleteModal,
  toggleDeleteModal,
  setKeys,
  delete_id,
}) => {
  const confirmDelete = () => {
    axiosInstance
      .delete(`/v1/api-keys/${delete_id}/`)
      .then((resp) => {
        console.log(resp.data.message);
        if (resp.status === 200) {
          const newList = keys.filter((key) => key._id !== delete_id);
          setKeys(newList);
          toggleDeleteModal();
          toast.error(resp.data.message);
        }
      })
      .catch((errors) => {
        console.log(errors);
      });
  };
  return (
    <div>
      {' '}
      <Dialog
        open={showDeleteModal}
        onClose={toggleDeleteModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Delete Api-Key alert'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure want to delete
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmDelete}>Confirm</Button>
          <Button onClick={toggleDeleteModal} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteModal;
