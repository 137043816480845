import React from 'react'

const Test = () => {
  return (
    <div>
    <div id="map"></div>
    <div id="pano"></div>
    <input type="text" id="sharelink" hidden></input>

    

    </div>
  )
}

export default Test