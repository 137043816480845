import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Typography,
  Container,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import image from './Galli_color.png';
import axiosBase from '../utils/axios';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { toast } from 'react-toastify';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import jwt_decode from 'jwt-decode';

function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      marginTop='3rem'
    >
      {'Copyright © Galli Express Private Limited 2023 '}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '2rem',
          marginTop: '.25rem',
        }}
      >
        <Link href='https://gallimaps.com/'>Visit Website</Link>
        <Link href='https://gallimap.com/terms.html'>Terms and Conditions</Link>
        <Link href='https://gallimap.com/privacy.html'>Privacy Policy</Link>
      </div>
    </Typography>
  );
}

const theme = createTheme();
const saveToken = (token) => {
  window.localStorage.setItem('galli_token', token);
};

const saveRefreshToken = (refreshToken) => {
  window.localStorage.setItem('galli_Refreshtoken', refreshToken);
};

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState('');
  const [errorMessage, setErrorMessage] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = (evt) => {
    evt.preventDefault();
    axiosBase
      .post('v1/login', { email, password })
      .then((resp) => {
        // console.log('token',resp.data.data.token)
        if (resp.status === 200) {
          const decoded = jwt_decode(resp.data.data.token);
          // console.log("hidevocer",decoded);
          if (decoded.email_verified === true) {
            saveToken(resp.data.data.token);
            saveRefreshToken(resp.data.data.refreshToken);
            navigate('/');
            // window.location.reload();

            toast.success('Login Sucessfully');
          } else if (decoded.email_verified === false) {
            toast.error('Verify through email sent in your email address');
          }
        }
      })
      .catch((errors) => {
        // console.log(errors)
        if (errors.response.status === 400) {
          setErrorMessage(errors.response.data.message);
        } else if (errors.response.status === 401) {
          setErrors(errors.response.data.message);
        }
      });
  };
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, width: 100, height: 100 }} src={image}></Avatar>
            <Typography component='h1' variant='h5'>
              Galli Maps Sign in
            </Typography>

            {errors ? <Alert severity='error'>{errors}</Alert> : null}
            <Box
              component='form'
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email'
                name='Email'
                autoComplete='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
                error={errorMessage.some((error) => error.path[0] === 'email')}
                helperText={
                  errorMessage
                    .find((error) => error.path[0] === 'email')
                    ?.message.replace(/"/g, '') || ''
                }
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type={showPassword ? 'text' : 'password'}
                id='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete='current-password'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errorMessage.some(
                  (error) => error.path[0] === 'password',
                )}
                helperText={
                  errorMessage
                    .find((error) => error.path[0] === 'password')
                    ?.message.replace(/"/g, '') || ''
                }
              />
              <FormControlLabel
                control={<Checkbox value='remember' color='primary' />}
                label='Remember me'
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: '#e37547 ',
                  '&:hover': { bgcolor: '#812c19' },
                }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href='forgotPassword' variant='body2'>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href='signup' variant='body2'>
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default Login;
