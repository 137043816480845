import React, { useState, useEffect } from 'react';
import Login from './admin/Login';
import Dashboard from './pages/Admin/Dashboard/Dashboard';
import CDashboard from './pages/Client/Dashboard/CDashboard';
import User from './pages/Admin/User/User';
import Apikey from './pages/Admin/ApiKey/Apikey';
import Sidebar from './components/Sidebar.tsx';
import Nopath from './pages/Nopath';
import Service from './pages/Admin/Service/Service';
import Signup from './admin/Signup';
import Transaction from './pages/Admin/Transaction/Transaction';
import Test from './pages/test/Test';
import Plan from './pages/Admin/Plans/Plan';
import Rate from './pages/Admin/Rate/Rate';
import CApiKey from './pages/Client/ApiKey/CApiKey';
import AddBusinessRequest from './pages/Employee/Dashboard/Business/AddBusinessRequest';
import AddPlaceRequest from './pages/Employee/Dashboard/Place/AddPlaceRequest';
import {
  HashRouter,
  BrowserRouter as Router,
  useLocation,
  Route,
  Routes,
  Outlet,
  Navigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChangePassword from './pages/Admin/User/ChangePassword';
import jwt_decode from 'jwt-decode';
import EarlyAccess from './pages/Admin/EarlyAccess/EarlyAccess';
import Contact from './pages/Admin/Contact/Contact';
import Esewa from './pages/Client/ApiKey/Esewa';
import Esewa_fail from './pages/Client/ApiKey/Esewa_fail';
import CTransaction from './pages/Client/Transaction/CTransaction';
import UserCreditHistory from './pages/Client/Credit History/UserCreditHistory';
import ForgotPassword from './admin/ForgotPassword';
import ResetPassword from './admin/ResetPassword';
import VerifyEmail from './admin/VerifyEmail';
import Invoice from './pages/Client/Invoice/Invoice';
import AddDepartment from './pages/Employee/Dashboard/Department/AddDepartment.js';
import { useEmployeeAuth } from './hooks/useEmployeeAuth';
import { useClientAuth } from './hooks/useClientAuth';
import Rate_Service from './pages/Client/Rate/Rate_Service';
import AllInvoice from './pages/Admin/Invoice/AllInvoice';
import Packages from './pages/Admin/Packages/Packages';
import { useAdminClient } from './hooks/useAdminClient';
import Municipality from './pages/Admin/Municaplity/Municipality.js';
import Event from './pages/Admin/Events/Event.js';
import EmployeeEvent from './pages/Employee/Dashboard/Event/Event.js';
const useAuth = () => {
  if (!!window.localStorage.getItem('galli_token')) {
    return true;
  } else {
    return false;
  }
};
const PrivateRoute = () => {
  const location = useLocation();
  const auth = useAuth();
  return auth && auth !== 'undefined' ? (
    <Outlet />
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
};
const useAdminAuth = () => {
  const token = window.localStorage.getItem('galli_token');
  const decoded = jwt_decode(token);
  // console.log(decoded);
  if (decoded.role === 'admin' || decoded.role === 'superadmin') {
    return true;
  } else {
    return false;
  }
};
const AdminRoute = () => {
  const location = useLocation();
  const auth = useAdminAuth();
  const employee = useEmployeeAuth();

  return auth && auth !== 'undefined' ? (
    <Outlet />
  ) : employee && employee !== 'undefined' ? (
    <Navigate to='/addPlaceRequest' state={{ from: location }} replace />
  ) : (
    <Navigate to='/cdashboard' state={{ from: location }} replace />
  );
};

const EmployeeRoute = () => {
  const location = useLocation();
  const employee = useEmployeeAuth();
  return employee && employee !== 'undefined' ? (
    <Outlet />
  ) : (
    <Navigate to='/cdashboard' state={{ from: location }} replace />
  );
};

const AdminClientRoute = () => {
  const location = useLocation();
  const adminClient = useAdminClient();
  return adminClient && adminClient !== 'undefined' ? (
    <Outlet />
  ) : (
    <Navigate to='/cdashboard' state={{ from: location }} replace />
  );
};

const ClientRoute = () => {
  const location = useLocation();
  const client = useClientAuth();
  return client && client !== 'undefined' ? (
    <Outlet />
  ) : (
    <Navigate to='/cdashboard' state={{ from: location }} replace />
  );
};

const App = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme='colored'
      />

      <Router>
        {/* <HashRouter> */}
        <Routes>
          <Route path='/' element={<PrivateRoute />}>
            <Route path='/' element={<Sidebar />}>
              {/* Admin route */}
              <Route path='/' element={<AdminRoute />}>
                <Route path='/' element={<Dashboard />} />
                <Route path='/apiKey' element={<Apikey />} />
                <Route path='/user' element={<User />} />
                <Route path='/plans' element={<Plan />} />
                <Route path='/transactions' element={<Transaction />} />
                <Route path='/earlyaccess' element={<EarlyAccess />} />
                <Route path='/events' element={<Event />} />
                <Route path='/municipality' element={<Municipality />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/service' element={<Service />} />
                <Route path='/rate' element={<Rate />} />
                <Route path='/allInvoice' element={<AllInvoice />} />
              </Route>
              <Route path='/' element={<AdminClientRoute />}>
                <Route path='/packages' element={<Packages />} />
              </Route>

              <Route path='/changePassword' element={<ChangePassword />} />
              <Route path='/test' element={<Test />} />

              <Route path='/' element={<EmployeeRoute />}>
                <Route path='/addPlaceRequest' element={<AddPlaceRequest />} />
                <Route
                  path='/addBusinessRequest'
                  element={<AddBusinessRequest />}
                />
                <Route path='/addDepartment' element={<AddDepartment />} />
                <Route path='/employeeEvent' element={<EmployeeEvent />} />
              </Route>

              <Route path='/' element={<ClientRoute />}>
                <Route path='/cdashboard' element={<CDashboard />} />
                <Route path='/capikey' element={<CApiKey />} />
                <Route path='/rate_service' element={<Rate_Service />} />
                <Route path='/invoice' element={<Invoice />} />

                {/* CTransaction */}
                <Route path='/ctransaction' element={<CTransaction />} />
                <Route path='/esewa' element={<Esewa />} />
                <Route path='/esewa_fail' element={<Esewa_fail />} />
                <Route
                  path='/userCreditHistory'
                  element={<UserCreditHistory />}
                />
              </Route>

              <Route path='*' element={<Nopath />} />
            </Route>
          </Route>
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/forgotPassword' element={<ForgotPassword />} />
          <Route path='/reset-password/:id' element={<ResetPassword />} />
          <Route path='/verify-email/:id' element={<VerifyEmail />} />
          {/* routesfor employee */}
        </Routes>

        {/* </HashRouter> */}
      </Router>
    </>
  );
};

export default App;
