import React, { useEffect, useState } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Button from '@mui/material/Button';
import axiosInstance from '../../../utils/axios';
import { AiOutlineDownload } from 'react-icons/ai';
import { Document, Page } from 'react-pdf';
import { FaCheck } from 'react-icons/fa';
import NepaliDate from 'nepali-date-converter';

const Invoice = () => {
  const [invoice, setInvoice] = useState([]);
  const {} = useFetch(invoice, setInvoice, '/v1/users/me/invoices');
  const [pdfContent, setPdfContent] = useState(null);
  const [finalInvoice, setFinalInvoice] = useState([]);
  const [results, setResults] = useState([]);

  console.log('invoice', invoice);

  useEffect(() => {
    const newInvoice = invoice.map((item) => {
      return {
        from_date: item.from_date,
        to_date: item.to_date,
        _id: item._id,
        invoice_date: item.invoice_date,
        status: item.status,
        newFromDate: new NepaliDate(new Date(item.from_date)),
        newToDate: new NepaliDate(new Date(item.to_date)),
      };
    });
    setFinalInvoice(newInvoice);
  }, [invoice]);
  console.log('finalinvoice', finalInvoice);

  useEffect(() => {
    const newInvoice = finalInvoice.map((item) => {
      const formattedToDate = item.newToDate.format(' DD, MMMM YYYY', 'en');
      const formattedDate = item.newFromDate.format(' DD, MMMM YYYY', 'en');
      return {
        from_date: item.from_date,
        to_date: item.to_date,
        _id: item._id,
        invoice_date: item.invoice_date,
        status: item.status,
        newFromDate: formattedDate,
        newToDate: formattedToDate,
      };
    });
    setResults(newInvoice);
  }, [finalInvoice]);
  console.log('result', results);

  const download = (_id) => {
    axiosInstance
      .get(`v1/invoices/${_id}/download`, {
        responseType: 'blob', // Set the response type to 'blob'
      })
      .then((resp) => {
        if (resp.status === 200) {
          console.log(resp);
          setPdfContent(resp.data);
          const url = window.URL.createObjectURL(resp.data);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'invoice.pdf';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      })
      .catch((error) => {
        // Handle errors, e.g., display an error message
        console.error('Error fetching PDF:', error);
      });
  };
  console.log('pdfContent', pdfContent);

  return (
    <div>
      {pdfContent && (
        <Document file={{ data: pdfContent }}>
          <Page pageNumber={1} />
        </Document>
      )}
      <TableContainer style={{ marginTop: '1.3rem' }}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>SN</TableCell>
              <TableCell>Invoice date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Download</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {results.map((key, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {key.newFromDate} to {key.newToDate}
                </TableCell>

                <TableCell>
                  {/* {key.status === 'paid' ? (
                    <>
                      {key.status} <FaCheck style={{ color: 'green' }} />
                    </>
                  ) : (
                    key.status
                  )} */}
                  Pending
                </TableCell>
                <TableCell>
                  <Button>
                    <AiOutlineDownload
                      onClick={(e) => download(key._id)}
                      className='text-red'
                      style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                      title='Download pdf'
                    />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Invoice;
