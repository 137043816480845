import React, { useState, useEffect } from 'react';
// import { useFetch } from '../../../hooks/useFetch'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
// import { usePaginate } from '../../../hooks/usePagintation';
import { Pagination } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { RxCross2 } from 'react-icons/rx';
import Button from '@mui/material/Button';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CreateUser from './CreateUser';
import { useToggle } from '../../../hooks/useToggle';
import axiosInstance from '../../../utils/axios';
import { usePaginate } from '../../../hooks/usePaginate';
import DeleteModal from './DeleteModal';
import { AiFillDelete } from 'react-icons/ai';
import Input from '@mui/material/Input';

function formatDate(dateString) {
  const formattedDate = new Date(dateString).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
  return formattedDate;
}

const EarlyAccess = () => {
  const [earlyAccess, setEarlyAccess] = useState([]);
  const {
    data,
    itemsPerPage,
    handlePageChange,
    totalPages,
    isLoading,
    currentPage,
  } = usePaginate(earlyAccess, setEarlyAccess, 10, `/v1/early-access`);
  const [show, toggle, setShow] = useToggle();
  const [user, setUser] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const [delete_id, setDeleteId] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  const deleteKey = (_id) => {
    setShowDeleteModal(true);
    setDeleteId(_id);
  };

  const createAll = (key) => {
    setShow(true);
    setUser(key);
  };
  console.log('user', user);

  // const handlePageChange = (event, value) => {
  //   setCurrentPage(value);
  // };

  return (
    <div>
      {showDeleteModal ? (
        <DeleteModal
          earlyAccess={earlyAccess}
          showDeleteModal={showDeleteModal}
          toggleDeleteModal={toggleDeleteModal}
          delete_id={delete_id}
          setEarlyAccess={setEarlyAccess}
        />
      ) : null}
      {show ? (
        <CreateUser
          user={user}
          show={show}
          toggle={toggle}
          earlyAccess={earlyAccess}
          setEarlyAccess={setEarlyAccess}
        />
      ) : null}

      {/* <Input
        type='search'
        className='form-control'
        style={{ width: 300, float: 'right' }}
        placeholder='Search email or phone number'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      /> */}

      {isLoading ? (
        <Box style={{ position: 'relative' }}>
          <CircularProgress
            size={40}
            left={-20}
            top={10}
            status={'loading'}
            style={{ marginLeft: '50%' }}
          />
        </Box>
      ) : (
        <>
          <TableContainer
            style={{
              border: '1px solid #ccc',
              marginTop: 20,
              overflowX: 'auto',
            }}
          >
            <Table style={{ minWidth: 650 }} size='small'>
              <TableHead style={{ backgroundColor: '#F4EFED ' }}>
                <TableRow>
                  <TableCell>Orgainzation</TableCell>
                  <TableCell>Applicant</TableCell>
                  <TableCell>Designation</TableCell>
                  <TableCell> Contact</TableCell>
                  <TableCell> Email</TableCell>
                  <TableCell> Products</TableCell>
                  <TableCell>Services</TableCell>
                  <TableCell>Registered</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell> Create</TableCell>
                  <TableCell> Delete</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {earlyAccess.map((key) => (
                  <TableRow key={key._id}>
                    <TableCell
                      style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                    >
                      {key.organization}
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                    >
                      {key.applicant}
                    </TableCell>
                    <TableCell>{key.designation}</TableCell>
                    <TableCell>{key.contact}</TableCell>
                    <TableCell>{key.email}</TableCell>
                    <TableCell>{key.products}</TableCell>
                    <TableCell>{key.services.join(',')}</TableCell>
                    <TableCell>
                      {key.registered ? (
                        <Button>
                          <DoneOutlineIcon />{' '}
                        </Button>
                      ) : (
                        <Button>
                          <RxCross2 size='30' />
                        </Button>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                    >
                      {formatDate(key.createdAt)}
                    </TableCell>

                    <TableCell>
                      <Button
                        color='primary'
                        variant='outlined'
                        size='small'
                        onClick={(e) => createAll(key)}
                        style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                      >
                        Create keys
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button>
                        <AiFillDelete
                          onClick={(e) => deleteKey(key._id)}
                          style={{
                            fontSize: '1.4rem',
                            cursor: 'pointer',
                            color: 'red',
                          }}
                          title='Delete'
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color='primary'
          />
        </>
      )}
    </div>
  );
};

export default EarlyAccess;
