import React, { useState, useEffect } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { usePaginate } from '../../../hooks/usePagintation';
import { Pagination } from '@mui/material';
import Input from '@mui/material/Input';

const Transaction = () => {
  const [transactions, setTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const { data, isLoading } = useFetch(
    transactions,
    setTransactions,
    '/v1/payments/',
  );

  useEffect(() => {
    const newResult = transactions.filter((val) => {
      if (searchTerm === '') {
        return val;
      } else if (
        val.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.username.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return val;
      }
      return null;
    });
    setSearchResult(newResult);
  }, [transactions, searchTerm]);
  const { totalPages, currentPage, pageData, handlePageChange } = usePaginate(
    searchResult,
    5,
  );

  return (
    <div>
      <Input
        type='search'
        className='form-control'
        style={{ width: 300, float: 'right' }}
        placeholder='Search by username or email '
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <TableContainer style={{ border: '1px solid #ccc', marginTop: 20 }}>
        <Table style={{ minWidth: 650 }}>
          <TableHead style={{ backgroundColor: '#F4EFED ' }}>
            <TableRow>
              <TableCell>User name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell> Type</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Updated At</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {pageData.map((key) => (
              <TableRow key={key._id}>
                <TableCell>{key.username}</TableCell>
                <TableCell>{key.email}</TableCell>
                <TableCell>
                  {key.amount !== null ? key.amount : 'N/A'}
                </TableCell>
                <TableCell>{key.type}</TableCell>
                <TableCell>
                  {key.createdAt !== null ? key.createdAt.split('T')[0] : ''}
                </TableCell>
                <TableCell>
                  {key.updatedAt !== null ? key.updatedAt.split('T')[0] : ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color='primary'
      />
    </div>
  );
};

export default Transaction;
