import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import Button from '@mui/material/Button';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CreateApiKey from './CreateApiKey.tsx';
import { RxCross2 } from 'react-icons/rx';
import { GrUpdate } from 'react-icons/gr';
import { AiFillDelete } from 'react-icons/ai';
import { usePaginate } from '../../../hooks/usePagintation.js';
import { useSearchAndPaginate } from '../../../hooks/useSearchAndPagination.js';
import { Pagination } from '@mui/material';
import { useSearch } from '../../../hooks/useSearch.js';
import Input from '@mui/material/Input';
import DeleteModal from './DeleteModal.js';
import RegenerateModal from './RegenerateModal.js';
import { useFetch } from '../../../hooks/useFetch.js';
import CircularProgress from '@mui/material/CircularProgress';
import { AiOutlineUser } from 'react-icons/ai';
import UserDetail from './UserDetail.js';
import { GrDocumentUpdate } from 'react-icons/gr';
import UpdateModal from './UpdateModal.tsx';
import SendMail from './SendMail.js';
import { AiOutlineMail } from 'react-icons/ai';
import ReactToPdf from 'react-to-pdf';
import { BsFilePdf } from 'react-icons/bs';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

const Apikey = () => {
  const [open, setOpen] = React.useState(false);
  const [keys, setKeys] = useState([]);
  const [delete_id, setDeleteId] = useState();
  const [regenerate_id, setRegenerateId] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const [showRegenerateModal, setRegenerateModal] = useState(false);
  const toggleRegenerateModal = () => setRegenerateModal(!showRegenerateModal);
  const [showUserDetail, setUserDetail] = useState(false);
  const toggleUserDetail = () => setUserDetail(!showUserDetail);
  const [user, setUser] = useState([]);
  const [services, setService] = useState([]);
  const { data, isLoading } = useFetch(keys, setKeys, '/v1/api-keys');
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const toggleShowModal = () => setShowUpdateModal(!showUpdateModal);
  const [update_id, setUpdateId] = useState([]);
  const { totalPages, currentPage, searchResult, handlePageChange } =
    useSearchAndPaginate(keys, searchTerm, 10);
  // const { searchResult } = useSearch(keys, searchTerm);
  // console.log('keys', keys);
  const [mail_id, setMailId] = useState('');
  const [access, setAccess] = useState('');
  // const { totalPages, currentPage, pageData, handlePageChange } = usePaginate(
  //   searchResult,
  //   10,
  // );
  const [showMailModal, setShowMailModal] = useState(false);
  const toggleShowMailModal = () => setShowMailModal(!showMailModal);
  const componentRef = useRef();
  const [isAccessKeyVisible, setAccessKeyVisible] = useState(true);
  const [alias, setAlias] = useState('');
  const [totalHits, setHits] = useState([]);
  const [realHits, setRealHits] = useState([]);

  const handleAccessKeyVisibility = () => {
    setAccessKeyVisible(!isAccessKeyVisible);
  };

  console.log('totalHits', totalHits);

  const calculateSum = () => {
    let sum = 0;
    for (const key in totalHits) {
      if (typeof totalHits[key] === 'number') {
        sum += totalHits[key];
      }
    }
    return sum;
  };
  const sum = calculateSum();

  console.log('sum', sum);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteKey = (_id) => {
    setShowDeleteModal(true);
    setDeleteId(_id);
    // console.log('_id',_id)
  };
  // console.log('11_id',delete_id)

  const regenerateApiKey = (_id) => {
    // console.log('_id',_id)
    setRegenerateId(_id);
    setRegenerateModal(true);
  };
  const userDetail = (key) => {
    setUserDetail(true);
    setUser(key.user);
    setService(key.services);
    setAccess(key.access_key);
    setAlias(key.alias);
  };
  console.log('services', access);
  const updateApi = (key) => {
    setUpdateId(key);
    setShowUpdateModal(true);
  };
  const sendEmail = (_id) => {
    setShowMailModal(true);
    setMailId(_id);
  };
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [10, 10],
    compress: true,
    // putTotalPages: totalPages,
  };

  return (
    <>
      {open ? (
        <CreateApiKey
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          keys={keys}
          setKeys={setKeys}
          handleClickOpen={handleClickOpen}
        />
      ) : null}
      {showDeleteModal ? (
        <DeleteModal
          toggleDeleteModal={toggleDeleteModal}
          delete_id={delete_id}
          keys={keys}
          setKeys={setKeys}
          showDeleteModal={showDeleteModal}
        />
      ) : null}
      {showRegenerateModal ? (
        <RegenerateModal
          showRegenerateModal={showRegenerateModal}
          toggleRegenerateModal={toggleRegenerateModal}
          keys={keys}
          setKeys={setKeys}
          regenerate_id={regenerate_id}
        />
      ) : null}
      {showUserDetail ? (
        <UserDetail
          showUserDetail={showUserDetail}
          toggleUserDetail={toggleUserDetail}
          alias={alias}
          user={user}
          services={services}
          totalHits={totalHits}
          setHits={setHits}
          realHits={realHits}
          setRealHits={setRealHits}
          access={access}
        />
      ) : null}
      {showUpdateModal ? (
        <UpdateModal
          showUpdateModal={showUpdateModal}
          toggleShowModal={toggleShowModal}
          keys={keys}
          setKeys={setKeys}
          update_id={update_id}
        />
      ) : null}

      {showMailModal ? (
        <SendMail
          showMailModal={showMailModal}
          toggleShowMailModal={toggleShowMailModal}
          mail_id={mail_id}
        />
      ) : null}

      <Button variant='outlined' onClick={handleClickOpen}>
        Create ApiKey
      </Button>

      <Input
        type='search'
        className='form-control'
        style={{ width: 300, float: 'right' }}
        placeholder='Search email or username or alias '
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {isLoading ? (
        <Box style={{ position: 'relative' }}>
          <CircularProgress
            size={40}
            left={-20}
            top={10}
            status={'loading'}
            style={{ marginLeft: '50%' }}
          />
        </Box>
      ) : (
        <div ref={componentRef}>
          <div style={{ textAlign: 'right' }}>
            <ReactToPdf
              options={options}
              targetRef={componentRef}
              filename='apikey'
              x={0.5}
              y={0.5}
              scale={0.7}
            >
              {({ toPdf }) => (
                <BsFilePdf
                  style={{ fontSize: '2rem', cursor: 'pointer', color: 'red' }}
                  onClick={toPdf}
                />
              )}
            </ReactToPdf>
          </div>

          <TableContainer
            style={{ border: '1px solid #ccc', overflowX: 'auto' }}
          >
            <Table style={{ minWidth: 650 }} size='small'>
              <TableHead style={{ backgroundColor: '#F4EFED ' }}>
                <TableRow>
                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    Alias
                  </TableCell>
                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    Domain Name
                  </TableCell>
                  <TableCell> Expire</TableCell>
                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    Access key
                  </TableCell>
                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    Created At
                  </TableCell>
                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    Expiry Date
                  </TableCell>
                  <TableCell> Delete</TableCell>
                  <TableCell> Regenerate</TableCell>
                  <TableCell> Detail</TableCell>
                  <TableCell> Update</TableCell>
                  <TableCell> Send Mail</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {searchResult.map((key) => (
                  <TableRow key={key._id}>
                    <TableCell
                      style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                    >
                      {key.alias}
                    </TableCell>
                    <TableCell>{key.domain_name}</TableCell>
                    <TableCell>
                      {key.expire ? (
                        <Button>
                          <DoneOutlineIcon />{' '}
                        </Button>
                      ) : (
                        <Button>
                          <RxCross2 size='30' />
                        </Button>
                      )}
                    </TableCell>

                    <TableCell
                      style={{
                        minWidth: '100px',
                        whiteSpace: 'nowrap',
                        overflowX: 'auto',
                      }}
                    >
                      {' '}
                      {isAccessKeyVisible ? (
                        <>
                          <AiFillEyeInvisible
                            onClick={handleAccessKeyVisibility}
                            className='text-blue'
                            style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                            title='Show Access Key'
                          />{' '}
                        </>
                      ) : (
                        <>
                          <AiFillEye
                            onClick={handleAccessKeyVisibility}
                            className='text-blue'
                            style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                            title='Hide Access Key'
                          />
                          {key.access_key}
                        </>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                    >
                      {key.createdAt !== null
                        ? key.createdAt.split('T')[0]
                        : ''}
                    </TableCell>
                    <TableCell style={{ minWidth: '20px' }}>
                      {key.expiration_date !== null
                        ? key.expiration_date.split('T')[0]
                        : ''}
                    </TableCell>

                    <TableCell>
                      <Button>
                        <AiFillDelete
                          onClick={(e) => deleteKey(key._id)}
                          style={{
                            fontSize: '1.4rem',
                            cursor: 'pointer',
                            color: 'red',
                          }}
                          title='Delete'
                        />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button>
                        <GrUpdate
                          onClick={(e) => regenerateApiKey(key._id)}
                          className='text-blue'
                          style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                          title='Regenerate'
                        />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button>
                        <AiOutlineUser
                          onClick={(e) => userDetail(key)}
                          className='text-blue'
                          style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                          title='User Detail'
                        />
                      </Button>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <Button>
                        <GrDocumentUpdate
                          onClick={(e) => updateApi(key)}
                          style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                          title='Update'
                        />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button>
                        <AiOutlineMail
                          onClick={(e) => sendEmail(key._id)}
                          style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                          title='Send Mail'
                        />
                      </Button>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color='primary'
          />
        </div>
      )}
    </>
  );
};

export default Apikey;
