import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import axiosInstance from '../../../utils/axios';
import { useFetch } from '../../../hooks/useFetch';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CreateUser = ({ user, toggle, show, setEarlyAccess, earlyAccess }) => {
  const [services, setServices] = useState([]);
  const { data } = useFetch(services, setServices, '/v1/services');
  const [expiration_date, setExpirationDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    const expiration_date = new Date();
    expiration_date.setFullYear(expiration_date.getFullYear() + 1);
    setExpirationDate(expiration_date);
  }, [1]);
  const create = () => {
    setIsLoading(true);
    axiosInstance
      .post('/v1/register', {
        username: user.applicant,
        email: user.email,
        password: 'galli@123',
        role: 'client',
      })
      .then((resp) => {
        console.log('hell0', resp.data.data);
        if (resp.status === 201) {
          axiosInstance
            .post('/v1/api-keys/', {
              alias: user.organization,
              domain_name: '*',
              expiration_date,
              services: services,
              user: resp.data.data._id,
            })
            .then((resp) => {
              if (resp.status === 201) {
                axiosInstance.post(
                  `/v1/api-keys/${resp.data.data._id}/sendMail/`,
                );
                axiosInstance
                  .patch(`/v1/early-access/${user._id}`, { registered: true })
                  .then((resp) => {
                    // console.log("respnew",resp)
                    if (resp.status === 200) {
                      const updatedEarly = [...earlyAccess];
                      const index = updatedEarly.findIndex(
                        (i) => i._id === resp.data.data._id,
                      );
                      let {
                        _id,
                        applicant,
                        contact,
                        designation,
                        email,
                        organization,
                        products,
                        registered,
                        services,
                      } = resp.data.data;
                      const newEarly = {
                        _id: _id,
                        applicant: applicant,
                        contact: contact,
                        designation: designation,
                        email: email,
                        organization: organization,
                        products: products,
                        registered: registered,
                        services: services,
                      };
                      updatedEarly.splice(index, 1, newEarly);
                      setEarlyAccess(updatedEarly);
                      toggle();
                      setIsLoading(false);
                    }
                  });
                console.log(user._id);
              }
            });
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setErrors(error.response.data.message);
        setIsLoading(false);
      });
  };
  return (
    <div>
      <Modal
        open={show}
        onClose={toggle}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          {errors.map((error) => {
            return <Alert severity='error'>{error.message}</Alert>;
          })}
          {isLoading ? (
            <Box style={{ position: 'relative' }}>
              <CircularProgress
                size={40}
                left={-20}
                top={10}
                status={'loading'}
                style={{ marginLeft: '50%' }}
              />
            </Box>
          ) : (
            <>
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Are you sure want to
              </Typography>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                  position: 'relative',
                  overflow: 'auto',
                  maxHeight: 300,
                  '& ul': { padding: 0 },
                }}
                subheader={<li />}
              >
                <li>
                  <ul>
                    <ListItem>Create User</ListItem>

                    <ListItem>Create Api key</ListItem>
                    <ListItem>Send mail to a user</ListItem>
                  </ul>
                </li>
              </List>
            </>
          )}

          <Button onClick={toggle}>Cancel</Button>
          <Button onClick={create}>Create</Button>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateUser;
