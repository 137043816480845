import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AddSubService = ({toggleAddSub,showAddSub,ids,services,setServices}) => {
  const [name,setName]=useState('');
  const [description,setDescription]=useState("");
  const [credit_points,setCreditPoints]=useState();
  const [isLoading,setIsLoading]=useState(false);
  const [errors, setErrors] = useState([]);


  
  const handleSubmit = () => {
    axiosInstance.post(`/v1/service/${ids}/sub-services/`, { name, description, credit_points }).then(resp => {
     console.log(resp)
      setIsLoading(true);
      if (resp.status===200) {
        setIsLoading(false);

        const updatedServices = [...services];
        const index = updatedServices.findIndex(i=>i._id===resp.data.data._id)
        let {_id,name,description,rate,createdAt,updatedAt,sub_services}=resp.data.data;
        const newServices = {
          _id:_id,
          name: name,
          description:description,
          rate: rate,
          createdAt:createdAt,
          updatedAt:updatedAt,
          sub_services:sub_services
         

        }
        updatedServices.splice(index,1,newServices);
        setServices(updatedServices);
        toggleAddSub();
        toast.success('Sub-Services added successfully')
      }
    }).catch(errors => {
      // console.log(errors.response.data.message);
      if (errors) {
        setIsLoading(false);

        setErrors(errors.response.data.message)
      }

    })
  }
 
  return (
    <div>
    <Dialog
     open={showAddSub}
     TransitionComponent={Transition}
     keepMounted
     onClose={toggleAddSub}
     aria-describedby="alert-dialog-slide-description"
   >
     <DialogTitle>{"Create Service"}</DialogTitle>
     {isLoading?<Box style={{position: 'relative'}}>
        <CircularProgress
        size={40}
        left={-20}
        top={10}
        status={'loading'}
        style={{marginLeft: '50%'}}
        />
      </Box>:<>
       <DialogContent>
       <DialogContentText id="alert-dialog-slide-description">
       </DialogContentText>

         <Box
           sx={{
             display: 'flex',
             alignItems: 'center',
             '& > :not(style)': { m: 2 },
           }}
         >
           <FormControl>
             {errors.map(error => {
               return <Alert severity="error">{error.message}</Alert>

             })}

             <TextField
               label="Name"               
               placeholder='Enter name'
               id="demo-helper-text-aligned"
               value={name}
               onChange={(e) => setName(e.target.value)}
             />
             <br/>
             <TextField
               label="Description"               
               placeholder='Enter description'
               id="demo-helper-text-aligned-no-helper"
               value={description}
               onChange={(e) => setDescription(e.target.value)}

             />
             <br/>
             <TextField
               placeholder='Enter credit points'
               label="Credit Points"
               id="demo-helper-text-aligned-no-helper"
               value={credit_points}
               onChange={(e) => setCreditPoints(e.target.value)}

             />
            
            
           </FormControl>


         </Box>
     </DialogContent>
     <DialogActions>
       <Button onClick={handleSubmit}>Create</Button>
       <Button onClick={toggleAddSub}>Cancel</Button>
     </DialogActions>
     </>
     }
    
   </Dialog>
 </div>
  )
}

export default AddSubService