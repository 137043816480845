import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/axios';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useFetch } from '../../../hooks/useFetch';

const Rate_Service = () => {
  const [rate, setRate] = useState([]);
  const [newDetail, setDetail] = useState([]);
  const [date, setDate] = useState('');
  const [services, setServices] = useState([]);
  const { data, isLoading } = useFetch(services, setServices, '/v1/services');

  useEffect(() => {
    const getAllData = async () => {
      const response = await axiosInstance('/v1/rates?limit=1');
      if (response.status === 200) {
        setRate(response.data.data.results);
      }
    };
    getAllData();
  }, []);
  // console.log('freeRate', rate);
  useEffect(() => {
    const newDate = rate.map((rates) => {
      return rates.createdAt;
    });
    setDate(newDate);
  }, [rate]);

  // console.log(date);

  useEffect(() => {
    const finalDetail = rate.map((rates) => {
      return rates.details;
    });

    setDetail(finalDetail);
  }, [rate]);
  // console.log('newDetail', newDetail);

  return (
    <div style={{ display: 'flex' }}>
      <TableContainer style={{ marginTop: '1.3rem' }}>
        <Table style={{ width: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{ fontSize: 20 }}>
                Service credit points on per 1000 request
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>SN</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Credit points</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {services.map((key, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{key.name}</TableCell>
                <TableCell>{key.credit_points * 1000}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer style={{ marginTop: 20 }}>
        <Table style={{ minWidth: 650 }}>
          <TableHead style={{ backgroundColor: '#F4EFED ' }}></TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell style={{ fontSize: 20 }}>
              Price per range requests
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>Request Minimum</TableCell>
            <TableCell>Request Maximum</TableCell>
            <TableCell> Rate Per Cp</TableCell>
          </TableRow>

          <TableBody>
            <>
              {newDetail &&
                newDetail.map((item, index) => (
                  <>
                    {item.map((items, index) => (
                      <>
                        <TableRow key={index}>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>{items.minimum_cp}</TableCell>
                          <TableCell>{items.maximum_cp}</TableCell>
                          <TableCell>
                            {index === 0 ? <>Rs {items.rate_per_cp}</> : null}
                            {index === 1 ? (
                              <>
                                {((item[0].rate_per_cp - items.rate_per_cp) /
                                  item[0].rate_per_cp) *
                                  100}
                                % Discount
                              </>
                            ) : null}

                            {index === 2 ? <>Please contact the sales</> : null}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </>
                ))}

              <TableRow>
                <TableCell colSpan={5}>
                  <hr
                    style={{
                      height: '2px',
                      backgroundColor: 'solid #ccc',
                      border: 'none',
                    }}
                  />
                </TableCell>
              </TableRow>
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Rate_Service;
