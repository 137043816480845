import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

const CreateCategory = ({
  toggleCategory,
  showCreateCategories,
  setCategories,
  categories,
}) => {
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const handleSubmit = () => {
    axiosInstance
      .post('/v1/services/categories', { name })
      .then((resp) => {
        setIsLoading(true);
        if (resp.status === 201) {
          setIsLoading(false);
          // window.location.reload();
          let { name, createdAt, updatedAt } = resp.data.data;
          const newCategories = [
            ...categories,
            {
              name: name,
              createdAt: createdAt,
              updatedAt: updatedAt,
            },
          ];
          setCategories(newCategories);
          toggleCategory();
          toast.success('Api Keys created successfully');
        }
      })
      .catch((errors) => {
        // console.log(errors.response.data.message);
        if (errors) {
          setIsLoading(false);

          setErrors(errors.response.data.message);
        }
      });
  };

  return (
    <div>
      <Dialog
        open={showCreateCategories}
        keepMounted
        onClose={toggleCategory}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>{'Create Categories'}</DialogTitle>
        {isLoading ? (
          <Box style={{ position: 'relative' }}>
            <CircularProgress
              size={40}
              left={-20}
              top={10}
              status={'loading'}
              style={{ marginLeft: '50%' }}
            />
          </Box>
        ) : (
          <>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'></DialogContentText>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 2 },
                }}
              >
                {errors.map((error) => {
                  return <Alert severity='error'>{error.message}</Alert>;
                })}
                <FormControl>
                  <TextField
                    label='Name'
                    placeholder='Enter name'
                    id='demo-helper-text-aligned'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <br />
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSubmit}>Create</Button>
              <Button onClick={toggleCategory}>Cancel</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default CreateCategory;
