import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  Typography,
  Container,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import image from './Galli_color.png';
import axiosBase from '../utils/axios';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { toast } from 'react-toastify';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      marginTop='3rem'
    >
      {'Copyright © Galli Express Private Limited 2023 '}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '2rem',
          marginTop: '.25rem',
        }}
      >
        <Link href='https://gallimaps.com/'>Visit Website</Link>
        <Link href='https://gallimap.com/terms.html'>Terms and Conditions</Link>
        <Link href='https://gallimap.com/privacy.html'>Privacy Policy</Link>
      </div>
    </Typography>
  );
}

const theme = createTheme();

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState('');
  const [errorMessage, setErrorMessage] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  const url = location.pathname;
  const parts = url.split('/');
  const value = parts[parts.length - 1];

  // console.log(value);

  const navigate = useNavigate();
  const handleSubmit = (evt) => {
    evt.preventDefault();
    axiosBase
      .post('v1/reset-password', { password, confirmPassword, token: value })
      .then((resp) => {
        // console.log('token')
        if (resp.status === 200) {
          toast.success('Password reset Sucessfully');
          setTimeout(() => {
            navigate('/');

            window.location.reload();
          }, 3000);
        }
      })
      .catch((errors) => {
        // console.log(errors.response.data.message);
        if (errors.response.status === 400) {
          setErrors(errors.response.data.message);
        } else if (errors.response.status === 402) {
          setErrorMessage(errors.response.data.message);
        }
      });
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, width: 100, height: 100 }} src={image}></Avatar>
            <Typography component='h1' variant='h5'>
              Galli Maps Sign in
            </Typography>
            {errorMessage.map((error) => {
              return <Alert severity='error'>{error.message}</Alert>;
            })}
            {errors ? <Alert severity='error'>{errors}</Alert> : null}
            <Box
              component='form'
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type={showPassword ? 'text' : 'password'}
                id='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete='current-password'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Confirm password'
                type={showPassword ? 'text' : 'password'}
                id='password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                autoComplete='current-password'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: '#e37547 ',
                  '&:hover': { bgcolor: '#812c19' },
                }}
              >
                Confirm
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href='/' variant='body2'>
                    Back to Login
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default ResetPassword;
