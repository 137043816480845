import React, { useState } from 'react'
import axiosInstance from '../../../utils/axios'
import {  toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

const SendMail = ({toggleShowMailModal,showMailModal,mail_id}) => {
    const [isLoading,setIsLoading]=useState(false);
    
    const confirm =()=>{
        setIsLoading(true);
        axiosInstance.post(`/v1/api-keys/${mail_id}/sendMail/`).then(resp=>{
            if(resp.status=200){
            toggleShowMailModal();
            toast.success("Mail send Successfully")
            setIsLoading(false);

        }  
        })

    }
    
    return (<>
   <Dialog
    open={showMailModal}
    onClose={toggleShowMailModal}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  > {isLoading?<DialogTitle style={{position:"fixed"  }}>
  <CircularProgress
  size={40}
  left={-20}
  top={10}
  status={'loading'}
  style={{marginLeft: '50%'}}
  />
</DialogTitle>:<>
    <DialogTitle id="alert-dialog-title">
      {"Are you sure want to send mail"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Are you sure send mail.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={confirm}>Confirm</Button>
      <Button onClick={toggleShowMailModal} autoFocus>
        Cancel
      </Button>
    </DialogActions>
    </>}
    
  </Dialog>


    </>
    
  )
}

export default SendMail