import React, { useState } from 'react';
import { Button, Modal, TextField } from '@material-ui/core';
import { FormControl } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import axiosInstance from '../../../utils/axios';
import KhaltiCheckout from "khalti-checkout-web";



const Khalti = ({ toggleKhalti, showKhalti,amount,points }) => {
  const [mobile, setMobile] = useState('');
  const [transaction_pin,setTransactionPin]=useState('')
  const uniqueId = uuidv4();


  const handleSubmit = () => {
    axiosInstance.post('https://khalti.com/api/v2/payment/initiate/',{mobile,transaction_pin,amount,product_identity:`${uniqueId}`,public_key:"test_public_key_f83b0f67341a4bb287771bd969510a5c",product_name:"credit_points"})
    .then(resp=>console.log(resp))
    toggleKhalti();
  };

  // const handleSubmit=()=>{
  //   let config = {
  //     // replace this key with yours
  //     "publicKey": "test_public_key_dc74e0fd57cb46cd93832aee0a390234",
  //     "productIdentity": "1234567890",
  //     "productName": "Drogon",
  //     "productUrl": "http://gameofthrones.com/buy/Dragons",
  //     "eventHandler": {
  //         onSuccess (payload) {
  //             // hit merchant api for initiating verfication
  //             console.log(payload);
  //         },
  //         // onError handler is optional
  //         onError (error) {
  //             // handle errors
  //             console.log(error);
  //         },
  //         onClose () {
  //             console.log('widget is closing');
  //         }
  //     },
  //     "paymentPreference": ["KHALTI", "EBANKING","MOBILE_BANKING", "CONNECT_IPS", "SCT"],
  // };
  
  // let checkout = new KhaltiCheckout(config);
  // let btn = document.getElementById("payment-button");
  // btn.onclick = function () {
  //     // minimum transaction amount must be 10, i.e 1000 in paisa.
  //     checkout.show({amount: 1000});
  // }
  // }

  return (
    <div>
      <Modal open={showKhalti} onClose={toggleKhalti}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', padding: '20px' }}>
          <p>Enter the registered mobile number </p>
          <FormControl>
          <TextField
              label="Mobile number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
            <br/>

            <TextField
              label="Transaction pin"
              value={transaction_pin}
              onChange={(e) => setTransactionPin(e.target.value)}
            />
                        <br/>

             <Button onClick={handleSubmit} variant="contained" color="primary" type="submit">
              Submit
            </Button>

          </FormControl>

           
           
        </div>
      </Modal>
    </div>
  );
};

export default Khalti;
