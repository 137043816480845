import React, { useState } from 'react';
import CreateService from './CreateService.tsx';
import { useFetch } from '../../../hooks/useFetch';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { AiFillDelete } from 'react-icons/ai';
import Button from '@mui/material/Button';
import DeleteModal from './DeleteModal';
import UpdateModal from './UpdateModal.tsx';
import { GrDocumentUpdate, GrServicePlay, GrAdd } from 'react-icons/gr';
import SubServices from './SubServices.js';
import { useToggle } from '../../../hooks/useToggle';
import AddSubService from './AddSubService.tsx';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/system/Unstable_Grid/Grid.js';
import CreateCategory from './CreateCategory.js';
import { useAdminAuth } from '../../../hooks/useAdminAuth.js';
const Service = () => {
  const [open, setOpen] = React.useState(false);
  const [services, setServices] = useState([]);
  const { data, isLoading } = useFetch(services, setServices, '/v1/services');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [delete_id, setDeleteId] = useState();
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const toggleShowModal = () => setShowUpdateModal(!showUpdateModal);
  const [update_id, setUpdateId] = useState([]);
  const [show, toggle, setShow] = useToggle();
  const [subService, setSubService] = useState([]);
  const [showAddSub, toggleAddSub, setShowAddSub] = useToggle();
  const [ids, setIds] = useState('');
  const [categories, setCategories] = useState([]);
  const auth = useAdminAuth();
  const { newData } = useFetch(
    categories,
    setCategories,
    '/v1/services/categories',
  );
  // console.log('categories', categories);
  console.log('categories', services);

  const [showCreateCategories, setCreateCategories] = useState(false);
  const toggleCategory = () => setCreateCategories(!showCreateCategories);
  const deleteService = (_id) => {
    setShowDeleteModal(true);
    setDeleteId(_id);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const updateService = (key) => {
    setUpdateId(key);
    setShowUpdateModal(true);
  };

  const showSubService = (key) => {
    setShow(true);
    setSubService(key.sub_services);
  };
  const addSubService = (key) => {
    setShowAddSub(true);
    setIds(key._id);
  };

  const addCategory = () => {
    setCreateCategories(true);
  };

  return (
    <div>
      {open ? (
        <CreateService
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          services={services}
          setServices={setServices}
          handleClickOpen={handleClickOpen}
          categories={categories}
        />
      ) : null}
      {showDeleteModal ? (
        <DeleteModal
          toggleDeleteModal={toggleDeleteModal}
          delete_id={delete_id}
          services={services}
          setServices={setServices}
          showDeleteModal={showDeleteModal}
        />
      ) : null}
      {showUpdateModal ? (
        <UpdateModal
          services={services}
          setServices={setServices}
          showUpdateModal={showUpdateModal}
          toggleShowModal={toggleShowModal}
          update_id={update_id}
        />
      ) : null}

      {show ? (
        <SubServices show={show} toggle={toggle} subService={subService} />
      ) : null}
      {showCreateCategories ? (
        <CreateCategory
          showCreateCategories={showCreateCategories}
          toggleCategory={toggleCategory}
          categories={categories}
          setCategories={setCategories}
        />
      ) : null}
      {showAddSub ? (
        <AddSubService
          toggleAddSub={toggleAddSub}
          showAddSub={showAddSub}
          ids={ids}
          services={services}
          setServices={setServices}
        />
      ) : null}
      {auth && auth !== 'undefined' ? (
        <div>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>
              {/* Create Service Button */}
              <Button variant='outlined' onClick={handleClickOpen}>
                Create Service
              </Button>
            </Grid>
            <Grid item>
              {/* Create Category Button */}
              <Button variant='outlined' onClick={addCategory}>
                Create Category
              </Button>
            </Grid>
          </Grid>
        </div>
      ) : null}

      {isLoading ? (
        <Box style={{ position: 'relative' }}>
          <CircularProgress
            size={40}
            left={-20}
            top={10}
            status={'loading'}
            style={{ marginLeft: '50%' }}
          />
        </Box>
      ) : (
        <Grid container spacing={5}>
          <Grid item xs={9}>
            <TableContainer
              style={{
                border: '1px solid #ccc',
                marginTop: 20,
                overflowX: 'auto',
              }}
            >
              <Table style={{ minWidth: 650 }}>
                <TableHead style={{ backgroundColor: '#F4EFED ' }} size='small'>
                  <TableRow>
                    <TableCell>SN</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell
                      style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                    >
                      Credit points per 1000 requests
                    </TableCell>
                    {auth && auth !== 'undefined' ? (
                      <>
                        {' '}
                        <TableCell
                          style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                        >
                          Created At
                        </TableCell>
                        <TableCell
                          style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                        >
                          Updated At
                        </TableCell>
                        <TableCell>Delete </TableCell>
                        <TableCell>Update </TableCell>
                      </>
                    ) : null}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {services.map((key, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell
                        style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                      >
                        {key.name}
                      </TableCell>
                      <TableCell>{key.description}</TableCell>
                      <TableCell>
                        {key.category && key.category.name
                          ? key.category.name
                          : 'N/A'}
                      </TableCell>
                      <TableCell>{key.credit_points * 1000}</TableCell>
                      {auth && auth !== 'undefined' ? (
                        <>
                          <TableCell
                            style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                          >
                            {key.createdAt !== null
                              ? key.createdAt.split('T')[0]
                              : ''}
                          </TableCell>
                          <TableCell
                            style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                          >
                            {key.updatedAt !== null
                              ? key.updatedAt.split('T')[0]
                              : ''}
                          </TableCell>
                          <TableCell>
                            <Button>
                              <AiFillDelete
                                onClick={(e) => deleteService(key._id)}
                                style={{
                                  fontSize: '1.4rem',
                                  cursor: 'pointer',
                                  color: 'red',
                                }}
                                title='Delete'
                              />
                            </Button>

                            {/* <Button>
                          <GrServicePlay
                            onClick={(e) => showSubService(key)}
                            style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                            title='Sub-Service'
                          />
                        </Button>
                        <Button>
                          <GrAdd
                            onClick={(e) => addSubService(key)}
                            style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                            title='Add Sub-Service'
                          />
                        </Button> */}
                          </TableCell>
                          <TableCell>
                            <Button>
                              <GrDocumentUpdate
                                onClick={(e) => updateService(key)}
                                style={{
                                  fontSize: '1.4rem',
                                  cursor: 'pointer',
                                }}
                                title='Update'
                              />
                            </Button>
                          </TableCell>
                        </>
                      ) : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={3}>
            <TableContainer
              style={{
                border: '1px solid #ccc',
                marginTop: 20,
                // marginLeft: '10rem',
              }}
            >
              <Table style={{ minWidth: 300, width: '100%' }}>
                <TableHead style={{ backgroundColor: '#F4EFED ' }}>
                  <TableRow>
                    <TableCell>S.N</TableCell>
                    <TableCell>Name</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {categories.map((key, index) => (
                    <TableRow key={key._id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{key.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Service;
