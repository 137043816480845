import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Typography,
  Container,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const theme = createTheme();

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .post('v1/change-password/', {
        oldPassword,
        newPassword,
        confirmPassword,
      })
      .then((resp) => {
        //  console.log(resp.data.message);
        if (resp.status === 200) {
          toast.success(
            'Password updated sucessfully successfully,Please login with new password',
          );
          window.localStorage.removeItem('galli_token');
          navigate('/login');
        }
      })
      .catch((errors) => {
        console.log(errors.response.data);
        if (errors.response.status === 403) {
          setErrorMessage(errors.response.data.message);
        } else if (errors.response.status === 400) {
          setErrors(errors.response.data.message);
        }
      });
  };
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component='h1' variant='h5'>
              Change Password
            </Typography>
            {errorMessage ? (
              <Alert severity='error'>{errorMessage}</Alert>
            ) : null}
            {errors.map((error) => {
              return <Alert severity='error'>{error.message}</Alert>;
            })}
            <Box
              component='form'
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Old Password'
                type='password'
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                autoComplete='current-password'
              />

              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='New Password'
                type='password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                autoComplete='current-password'
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Confirm Password'
                type='password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                autoComplete='current-password'
              />

              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: '#e37547 ',
                  '&:hover': { bgcolor: '#812c19' },
                }}
              >
                Change Password
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default ChangePassword;
