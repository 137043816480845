import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

const CreateFreeRate = ({ toggleFreeRate, showFreeRate }) => {
  const [credit_points, setCreditPoints] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const handleSubmit = () => {
    axiosInstance
      .post('/v1/rates/free', { credit_points })
      .then((resp) => {
        setIsLoading(true);
        if (resp.status === 201) {
          setIsLoading(false);
          window.location.reload();

          toggleFreeRate();
          toast.success('Free credit points created');
        }
      })
      .catch((errors) => {
        // console.log(errors.response.data.message);
        if (errors) {
          setIsLoading(false);

          setErrors(errors.response.data.message);
        }
      });
  };
  return (
    <div>
      <Dialog
        open={showFreeRate}
        keepMounted
        onClose={toggleFreeRate}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>{'Add free Credit'}</DialogTitle>
        {isLoading ? (
          <Box style={{ position: 'relative' }}>
            <CircularProgress
              size={40}
              left={-20}
              top={10}
              status={'loading'}
              style={{ marginLeft: '50%' }}
            />
          </Box>
        ) : (
          <>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'></DialogContentText>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 2 },
                }}
              >
                {errors.map((error) => {
                  return <Alert severity='error'>{error.message}</Alert>;
                })}
                <FormControl>
                  <TextField
                    label='Free credit points'
                    placeholder='Enter credit points'
                    id='demo-helper-text-aligned'
                    value={credit_points}
                    onChange={(e) => setCreditPoints(e.target.value)}
                  />
                  <br />
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSubmit}>Create</Button>
              <Button onClick={toggleFreeRate}>Cancel</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default CreateFreeRate;
