import React, { useContext } from 'react';
import EventContext from './EventContext';
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Zoom from 'react-medium-image-zoom';
import Badge from '@mui/material/Badge';
import { RxCross2 } from 'react-icons/rx';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { Box } from '@material-ui/core';
import axiosInstance from '../../../../utils/axios';
import CancelIcon from '@mui/icons-material/Cancel';

const Verified = () => {
  const {
    verifiedEvent,
    setVerifiedEvent,
    unVerifiedEvent,
    setUnVerifiedEvent,
  } = useContext(EventContext);
  // console.log('newEvent', newEvent);
  const baseUrl = `${process.env.REACT_APP_PUBLIC_URL}`;
  const handleDelete = (item) => {
    axiosInstance.delete(`/v1/events/${item._id}`).then((resp) => {
      // console.log(resp);
      if (resp.data.data.success === true) {
        const finalEvent = verifiedEvent.filter(
          (items) => items._id !== item._id,
        );
        setVerifiedEvent(finalEvent);
      }
    });
  };
  const handdleCancel = (item) => {
    axiosInstance
      .put(`/v1/events/${item._id}`, { status: 'rejected' })
      .then((resp) => {
        // console.log(resp);
        if (resp.data) {
          item.status = 'rejected';

          const finalEvent = verifiedEvent.filter(
            (items) => items._id !== item._id,
          );
          setVerifiedEvent(finalEvent);
          const rejected = [...unVerifiedEvent, item];
          setUnVerifiedEvent(rejected);
        }
      });
  };

  return (
    <div>
      {' '}
      <Grid container spacing={2} style={{ marginTop: '2rem' }}>
        {verifiedEvent.map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <Card
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {item.status !== 'deleted' ? (
                <IconButton
                  style={{ marginLeft: 'auto', marginTop: '-10px' }}
                  onClick={() => handleDelete(item)} // Replace with your delete function
                >
                  <DeleteIcon style={{ color: 'red' }} />
                </IconButton>
              ) : null}
              <IconButton
                title='Reject'
                style={{ marginLeft: 'auto', marginTop: '-10px' }}
                onClick={() => handdleCancel(item)}
              >
                <CancelIcon style={{ color: 'red' }} />
              </IconButton>
              {item.image !== null && item.image !== undefined ? (
                <>
                  {item.image ? (
                    <>
                      <Zoom>
                        <CardMedia
                          component='img'
                          alt={item.name}
                          height='100'
                          image={
                            baseUrl +
                            'static/' +
                            item.image.replace('public/', '')
                          }
                          style={{ objectFit: 'contain' }}
                        />
                      </Zoom>
                    </>
                  ) : null}
                </>
              ) : null}

              <CardContent style={{ flex: 1 }}>
                <Typography variant='h5' component='div'>
                  Event name: {item.name}
                </Typography>
                <Typography variant='h5' component='div'>
                  Category:{item.category}
                </Typography>
                <Typography variant='h5' component='div'>
                  Created by:{item.createdBy.name}
                </Typography>
                <Typography
                  variant='h5'
                  component='div'
                  style={{ display: 'flex' }}
                >
                  <span style={{ marginRight: '2rem' }}>Status:</span>
                  <Box ml={1}>
                    {item.status === 'deleted' ? (
                      <Badge badgeContent='Deleted' color='error'></Badge>
                    ) : (
                      <>
                        {item.status === 'new' ? (
                          <Badge badgeContent='New' color='primary'></Badge>
                        ) : (
                          <Badge
                            badgeContent='Verified'
                            color='success'
                          ></Badge>
                        )}
                      </>
                    )}
                  </Box>
                </Typography>
                <Typography variant='h5' component='div'>
                  Start Date:
                  {item.startDate !== null
                    ? item.startDate.split('T')[0]
                    : 'N/A'}
                </Typography>
                <Typography variant='h5' component='div'>
                  End Date:
                  {item.endDate !== null ? item.endDate.split('T')[0] : 'N/A'}
                </Typography>
                <Typography
                  variant='h5'
                  component='div'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Is Active:
                  {item.isActive === true ? (
                    <DoneOutlineIcon style={{ color: 'green' }} />
                  ) : (
                    <RxCross2 size='30' color='red' />
                  )}
                </Typography>

                <Typography
                  variant='h5'
                  component='div'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Is Special:
                  {item.isSpecial === true ? (
                    <DoneOutlineIcon style={{ color: 'green' }} />
                  ) : (
                    <RxCross2 size='30' color='red' />
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Verified;
