import React, { useState } from 'react';
import CreatePackages from './CreatePackages';
import Button from '@mui/material/Button';
import { useAdminAuth } from '../../../hooks/useAdminAuth';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { AiFillDelete } from 'react-icons/ai';
import { usePaginate } from '../../../hooks/usePaginate';
import { GrDocumentUpdate } from 'react-icons/gr';
import DeletePackage from './DeletePackage';
import AddPackage from './AddPackage';

function formatDate(dateString) {
  const formattedDate = new Date(dateString).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
  return formattedDate;
}

const Packages = () => {
  const [packages, setPackages] = useState([]);
  const {
    data,
    itemsPerPage,
    handlePageChange,
    totalPages,
    isLoading,
    currentPage,
  } = usePaginate(packages, setPackages, 5, `/v1/packages`);

  const [showCreatePackage, setCreatePackage] = useState(false);
  const toggleCreate = () => setCreatePackage(!showCreatePackage);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDelete = () => setShowDeleteModal(!showDeleteModal);
  const [deleteId, setDeleteId] = useState('');
  const [showAddPackage, setShowAddPackage] = useState(false);
  const toggleAddPackage = () => setShowAddPackage(!showAddPackage);
  const admin = useAdminAuth();
  const showPackages = () => {
    setCreatePackage(true);
  };
  const deletePackages = (key) => {
    setShowDeleteModal(true);
    setDeleteId(key._id);
  };
  const addPackages = () => {
    setShowAddPackage(true);
  };
  return (
    <div>
      {showCreatePackage ? (
        <CreatePackages
          showCreatePackage={showCreatePackage}
          toggleCreate={toggleCreate}
          packages={packages}
          setPackages={setPackages}
        />
      ) : null}
      {showDeleteModal ? (
        <DeletePackage
          deleteId={deleteId}
          showDeleteModal={showDeleteModal}
          toggleDelete={toggleDelete}
          packages={packages}
          setPackages={setPackages}
        />
      ) : null}
      {showAddPackage ? (
        <AddPackage
          toggleAddPackage={toggleAddPackage}
          showAddPackage={showAddPackage}
          packages={packages}
        />
      ) : null}
      {admin ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant='outlined' onClick={showPackages}>
              Create Package
            </Button>
            <Button variant='outlined' onClick={addPackages}>
              Asign Package
            </Button>
          </div>
        </>
      ) : null}

      <TableContainer
        style={{ border: '1px solid #ccc', marginTop: 20, overflowX: 'auto' }}
      >
        <Table style={{ minWidth: 650 }} size='small'>
          <TableHead style={{ backgroundColor: '#F4EFED ' }}>
            <TableRow>
              <TableCell>S.N</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
                {' '}
                Credit Points
              </TableCell>
              <TableCell> Price</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Expire date</TableCell>

              {admin ? (
                <>
                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    Updated At
                  </TableCell>
                  <TableCell>Delete </TableCell>
                  {/* <TableCell>Update </TableCell> */}
                </>
              ) : null}
            </TableRow>
          </TableHead>

          <TableBody>
            {packages.map((key, index) => (
              <TableRow key={key._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
                  {key.name}
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
                  {key.description}
                </TableCell>
                <TableCell>{key.credit_points}</TableCell>
                <TableCell>{key.price}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
                  {formatDate(key.createdAt)}
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
                  {formatDate(key.expiration_date)}
                </TableCell>

                {admin ? (
                  <>
                    {' '}
                    <TableCell
                      style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                    >
                      {formatDate(key.updatedAt)}
                    </TableCell>
                    <TableCell>
                      <Button>
                        <AiFillDelete
                          onClick={(e) => deletePackages(key)}
                          style={{
                            fontSize: '1.4rem',
                            cursor: 'pointer',
                            color: 'red',
                          }}
                          title='Delete'
                        />
                      </Button>
                    </TableCell>
                    {/* <TableCell>
                      <Button>
                        <GrDocumentUpdate
                          style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                          title='Update'
                        />
                      </Button>
                    </TableCell> */}
                  </>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Packages;
