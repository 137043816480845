import React, { useState } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { usePaginate } from '../../../hooks/usePagintation';
import { Pagination } from '@mui/material';
import Details from './Details';
import Button from '@mui/material/Button';

const UserCreditHistory = () => {
  const [history, setHIstory] = useState([]);
  const { data, isLoading } = useFetch(
    history,
    setHIstory,
    '/v1/users/credits-use',
  );
  const { totalPages, currentPage, pageData, handlePageChange } = usePaginate(
    history,
    7,
  );
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  const [allDate, setAllDate] = useState([]);
  const showDetails = (items) => {
    setShow(true);
    setAllDate(items);
  };

  return (
    <div>
      <b>Credit History</b>
      {show ? <Details allDate={allDate} show={show} toggle={toggle} /> : null}
      <TableContainer style={{ border: '1px solid #ccc', marginTop: 20 }}>
        <Table style={{ minWidth: 650 }}>
          <TableHead style={{ backgroundColor: '#F4EFED ' }}>
            <TableRow>
              <TableCell> Total Credit Point Used</TableCell>
              <TableCell> Details</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {pageData.map((key) => (
              <TableRow key={key._id}>
                <TableCell>{key.total_credit_points.toFixed(2)}</TableCell>
                <TableCell>
                  <Button onClick={() => showDetails(key.items)}>Show</Button>
                </TableCell>
                <TableCell>
                  {key.createdAt !== null ? key.createdAt.split('T')[0] : ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color='primary'
      />
    </div>
  );
};

export default UserCreditHistory;
