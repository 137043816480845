import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import employeeBase from '../../../../utils/employee';
import 'react-medium-image-zoom/dist/styles.css';
import CreateDepartment from './CreateDepartment';
import ShowAllDepartments from './ShowAllDepartments';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import ClearIcon from '@material-ui/icons/Clear';
import Checkbox from '@material-ui/core/Checkbox';
import { toast } from 'react-toastify';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
const AddDepartment = () => {
  const [department, setDepartment] = useState([]);
  const token = `${process.env.REACT_APP_ACCESS_TOKEN}`;
  const [showDepartment, setShowDepartment] = useState(false);
  const toggleCreateDepartment = () => setShowDepartment(!showDepartment);
  const [combinedImage, setCombinedImage] = useState([]);
  const [showallDepartment, setShowAllDepartment] = useState(false);
  const [word, setWord] = useState('');
  const [results, setResults] = useState([]);
  const [error, setError] = useState('');
  const baseUrl = `${process.env.REACT_APP_EMPLOYEE_URL}`;
  const [autoCompleteWord, setAutoCompleteWord] = useState('');
  const [autoCompleteResults, setAutoCompleteResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allid, setAllid] = useState('');
  const [removeid, setRemoveId] = useState('');
  const [allAddedList, setAddedList] = useState([]);
  const [uncheckedItems, setUncheckedItems] = useState([]);
  const [showRemoveElements, setRemoveElement] = useState(false);

  const toggleShowAllDepartments = () =>
    setShowAllDepartment(!showallDepartment);
  useEffect(() => {
    const getAllData = async () => {
      try {
        const response = await employeeBase(
          `department/getAllDepartments?accessToken=${token}`,
        );
        if (response.status === 200) {
          // console.log('dataresults', JSON.parse(response.data));
          const newData = response.data.map((item) => {
            const parseData = JSON.parse(item.logo);
            return {
              _id: item._id,
              actualname: item.actualname,
              logo: JSON.parse(parseData),
            };
          });

          setDepartment(newData);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    if (token) {
      getAllData();
    }
  }, []);

  const showCreate = () => {
    setShowDepartment(true);
  };

  const showDepartments = () => {
    setShowAllDepartment(true);
  };
  const handleWordChange = async (event) => {
    setWord(event.target.value);
    try {
      if (event.target.value.length > 0) {
        const response = await employeeBase(
          `department/departmentSearch?accessToken=${token}&word=${event.target.value}`,
        );
        console.log('response', response);
        const newData = response.data.map((item) => {
          const parseData = JSON.parse(item.logo);
          return {
            _id: item._id,
            actualname: item.actualname,
            logo: JSON.parse(parseData),
          };
        });
        setResults(newData);
        console.log('results', newData);
      } else {
        setResults([]);
      }
    } catch (error) {
      if (error) {
        setError('No data found');
      }
    }
  };

  const handleResultClick = (clickedWord) => {
    setWord(clickedWord);
    setResults([]); // Clear results
  };

  const handleAutoCompleteChange = async (event) => {
    setAutoCompleteWord(event.target.value);
    try {
      if (event.target.value.length > 0) {
        const response = await employeeBase(
          `department/departmentBranchesAddSearchList?accessToken=${token}&word=${event.target.value}&departmentName=${word}`,
        );
        console.log('autocomplete', response.data.length);

        setAutoCompleteResults(response.data);
      } else {
        setAutoCompleteResults([]);
      }
    } catch (error) {
      if (error) {
        setError('No data found');
      }
    }
  };
  const selectAll = () => {
    if (selectedItems.length === autoCompleteResults.length) {
      // If all items are selected, clear the selection
      setSelectedItems([]);
    } else {
      // Otherwise, select all items
      setSelectedItems(autoCompleteResults.map((result) => result._id));
    }
  };

  // const removeAll = () => {
  //   if (uncheckedItems.length === allAddedList.length) {
  //     setUncheckedItems([]);
  //   } else {
  //     setUncheckedItems(allAddedList.map((result) => result.id));
  //   }
  // };
  const handleCheckboxChange = (itemId) => {
    // console.log('itemId', itemId);
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        // If the item is already selected, remove it
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        // Otherwise, add it to the selection
        return [...prevSelectedItems, itemId];
      }
    });
  };
  const handleRemoveCheckboxChange = (itemId) => {
    // console.log('itemId', itemId);
    setUncheckedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((id) => id !== itemId);

        // If the item is already selected, remove it
      } else {
        // Otherwise, add it to the selection
        return [...prevSelectedItems, itemId];
      }
    });
  };
  // console.log('uncheckedItems', uncheckedItems);
  useEffect(() => {
    if (selectedItems.length > 0) {
      setAllid(selectedItems.join(','));
    }
  }, [selectedItems]);
  useEffect(() => {
    if (uncheckedItems.length > 0) {
      setRemoveId(uncheckedItems.join(','));
    }
  }, [uncheckedItems]);
  // console.log('removeid', removeid);

  // console.log('selecteditem', selectedItems);
  const saveall = async () => {
    if (selectedItems.length > 0 && word.length > 0) {
      employeeBase
        .post(`department/departmentSeparation?accessToken=${token}`, {
          searchIds: allid,
          department: word,
        })
        .then((resp) => {
          console.log('hello', resp);
          if (resp.status === 200) {
            setSelectedItems([]);
            setAutoCompleteResults([]);
            toast.success('Department added successfully');
          }
        });
    }
  };
  // console.log('selecteditem', allid);

  const removedepartment = () => {
    employeeBase
      .post(`department/departmentRemove?accessToken=${token}`, {
        searchIds: removeid,
        // department: word,
      })
      .then((resp) => {
        console.log('hello', resp);
        if (resp.status === 200) {
          setAddedList([]);
          toast.error('Department Removed successfully');
          setRemoveElement(false);
        }
      });
  };

  const departmentWiseSearch = async () => {
    const response = await employeeBase(
      `department/getDepartmentwiseSearch?accessToken=${token}&department=${word}`,
    );
    // console.log('Departmentwise response', response.data);
    if (response.status === 200) {
      setAddedList(response.data);
      setRemoveElement(true);
    }
  };

  // console.log('autoCompleteResults', autoCompleteResults);
  // console.log('allAddedList', allAddedList);
  return (
    <div>
      {showDepartment ? (
        <CreateDepartment
          department={department}
          setDepartment={setDepartment}
          showDepartment={showDepartment}
          toggleCreateDepartment={toggleCreateDepartment}
          setCombinedImage={setCombinedImage}
          combinedImage={combinedImage}
        />
      ) : null}
      {showallDepartment ? (
        <ShowAllDepartments
          department={department}
          setDepartment={setDepartment}
          showallDepartment={showallDepartment}
          setShowAllDepartment={setShowAllDepartment}
          toggleShowAllDepartments={toggleShowAllDepartments}
        />
      ) : null}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '2rem',
        }}
      >
        <Button variant='outlined' onClick={showDepartments}>
          Show Departments
        </Button>
        <Button variant='outlined' onClick={showCreate}>
          Create Department
        </Button>
      </div>
      <div style={{ display: 'flex', marginBottom: '1rem' }}>
        <div style={{ marginRight: '5rem' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                marginTop: '1rem',
                marginRight: '1.2rem',
                fontSize: '1.3rem',
              }}
            >
              Search Department
            </span>
            <TextField
              style={{ width: '20rem', marginTop: '1rem' }}
              variant='outlined'
              placeholder='Search department'
              value={word}
              onChange={handleWordChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    {word && (
                      <ClearIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setWord('');
                          setResults([]);
                        }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <Button
              onClick={departmentWiseSearch}
              style={{
                backgroundColor: 'green',
                color: 'white',
                height: '2.9rem', // Adjust the height to match TextField
                marginLeft: '1.2rem', // Adjust the margin to match the spacing
                width: '6rem',
              }}
            >
              Search
            </Button>
          </div>
          {results.length > 0 && (
            <div>
              <h2>Search Results:</h2>
              <ul>
                {results.map((result) => (
                  <li
                    key={result._id}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {result.logo && (
                      <Zoom>
                        <img
                          src={baseUrl + 'images/' + result.logo[0].image}
                          alt={`${result.actualname} Logo`}
                          style={{
                            maxWidth: '50px', // Adjust the width as needed
                            maxHeight: '50px', // Adjust the height as needed
                            borderRadius: '50%', // Make it circular
                            marginRight: '10px',
                          }}
                        />
                      </Zoom>
                    )}
                    <div>
                      <a
                        href='#'
                        onClick={() => handleResultClick(result.actualname)}
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        {result.actualname}
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {showRemoveElements ? (
            <>
              {' '}
              <Button
                onClick={removedepartment}
                style={{ backgroundColor: 'red', color: 'white' }}
              >
                Remove
              </Button>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {/* <Checkbox
                      onChange={removeAll}
                      checked={uncheckedItems.length !== allAddedList.length}
                    /> */}
                      </TableCell>
                      <TableCell>Branch Name</TableCell>
                      <TableCell>Geometry</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allAddedList.map((result) => (
                      <TableRow key={result._id}>
                        <TableCell>
                          <Checkbox
                            onChange={() =>
                              handleRemoveCheckboxChange(result._id)
                            }
                            checked={!uncheckedItems.includes(result._id)}
                          />
                        </TableCell>
                        <TableCell>{result.location}</TableCell>
                        <TableCell>{result.geometry}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : null}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column', // Change to column layout
            alignItems: 'flex-end', // Align to the right
            marginBottom: '1rem', // Add some margin if needed
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                marginTop: '1rem',
                marginRight: '1.2rem',
                fontSize: '1.3rem',
              }}
            >
              Search
            </span>
            <TextField
              style={{ width: '20rem', marginTop: '1rem' }}
              variant='outlined'
              placeholder='Search department'
              value={autoCompleteWord}
              onChange={handleAutoCompleteChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    {autoCompleteWord && (
                      <ClearIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setAutoCompleteWord('');
                          setAutoCompleteResults([]);
                        }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>

          {autoCompleteResults && (
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '1rem', // Adjust the margin as needed
                }}
              >
                <h2 style={{ marginRight: '1rem' }}>Search Results:</h2>
                <Button
                  onClick={saveall}
                  style={{ backgroundColor: 'green', color: 'white' }}
                >
                  Save
                </Button>
              </div>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          onChange={selectAll}
                          checked={
                            selectedItems.length === autoCompleteResults.length
                          }
                        />
                      </TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Geometry</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {autoCompleteResults.map((result) => (
                      <TableRow key={result._id}>
                        <TableCell>
                          <Checkbox
                            onChange={() => handleCheckboxChange(result._id)}
                            checked={selectedItems.includes(result._id)}
                          />
                        </TableCell>
                        <TableCell>{result.location}</TableCell>
                        <TableCell>{result.geometry}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddDepartment;
