import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import Button from '@mui/material/Button';
import { TiTickOutline } from 'react-icons/ti';
import { AiOutlineSearch, AiOutlineCloseCircle } from 'react-icons/ai';
import { Pagination } from '@mui/material';
import VerifyPlace from './VerifyPlace';
import RejectPlace from './RejectPlace';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import InspectPlace from './InspectPlace';

const Request = ({
  requests,
  setRequests,
  showdetail,
  setDetail,
  handlePageChange,
  totalPages,
  currentPage,
  rejected,
  setRejected,
  totalResults,
  setTotalResults,
  verified,
  setVerified,
  totalVerifiedResults,
  setTotalVerifiedResults,
  totalRejectedResults,
  setTotalRejectedResults,
  inspect,
  setInspect,
  setTotalInspectResults,
  totalInspectResults,
}) => {
  const [detailData, setDetailData] = useState([]);
  const [showVerfyPlace, setShowVerifyPlace] = useState(false);
  const toggleVerifyPlace = () => setShowVerifyPlace(!showVerfyPlace);
  const [showRejectPlace, setShowRejectPlace] = useState(false);
  const toggleReject = () => setShowRejectPlace(!showRejectPlace);
  const [showInspectPlace, setShowInspectPlace] = useState(false);
  const toggleInspect = () => setShowInspectPlace(!showInspectPlace);
  const showverify = (row) => {
    // console.log('row', row);
    const newRow = {
      ...row,
      fromrequest: true,
    };
    setShowVerifyPlace(true);
    setDetailData(newRow);
  };

  const showreject = (row) => {
    setShowRejectPlace(true);
    const newRow = { ...row, fromrequest: true };
    setDetailData(newRow);
  };

  const showinspect = (row) => {
    setShowInspectPlace(true);
    const newRow = { ...row, fromrequest: true };
    setDetailData(newRow);
  };

  const tableContainerStyle = {
    maxHeight: 600, // Set the maximum height as needed
    // overflowY: 'auto',
  };

  // console.log('rowandrowsrequests', requests);
  const baseUrl = `${process.env.REACT_APP_EMPLOYEE_URL}`;
  return (
    <div>
      {showVerfyPlace ? (
        <VerifyPlace
          showVerfyPlace={showVerfyPlace}
          detailData={detailData}
          toggleVerifyPlace={toggleVerifyPlace}
          requests={requests}
          setRequests={setRequests}
          verified={verified}
          setVerified={setVerified}
          totalVerifiedResults={totalVerifiedResults}
          setTotalVerifiedResults={setTotalVerifiedResults}
          totalRejectedResults={totalRejectedResults}
          setTotalRejectedResults={setTotalRejectedResults}
          totalResults={totalResults}
          setTotalResults={setTotalResults}
        />
      ) : null}
      {showRejectPlace ? (
        <RejectPlace
          toggleReject={toggleReject}
          showRejectPlace={showRejectPlace}
          detailData={detailData}
          requests={requests}
          setRequests={setRequests}
          rejected={rejected}
          setRejected={setRejected}
          totalResults={totalResults}
          setTotalResults={setTotalResults}
          totalRejectedResults={totalRejectedResults}
          setTotalRejectedResults={setTotalRejectedResults}
        />
      ) : null}
      {showInspectPlace ? (
        <InspectPlace
          requests={requests}
          setRequests={setRequests}
          toggleInspect={toggleInspect}
          showInspectPlace={showInspectPlace}
          detailData={detailData}
          totalResults={totalResults}
          setTotalResults={setTotalResults}
          inspect={inspect}
          setInspect={setInspect}
          setTotalInspectResults={setTotalInspectResults}
          totalInspectResults={totalInspectResults}
        />
      ) : null}
      <TableContainer component={Paper} style={tableContainerStyle}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.N</TableCell>
              <TableCell>Place name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Office/Store</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell>Verification</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1 + (currentPage - 1) * 10}</TableCell>
                <TableCell>{row.placename}</TableCell>
                <TableCell>{row.category}</TableCell>
                <TableCell>{row.location}</TableCell>
                <TableCell>{row.contactInfo}</TableCell>
                <TableCell>{row.place_office_store ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  {row.photos !== null && row.photos !== undefined ? (
                    <>
                      {JSON.parse(row.photos).length > 0 ? (
                        <Zoom>
                          <img
                            style={{ width: '10rem' }}
                            src={
                              baseUrl +
                              'images/' +
                              JSON.parse(row.photos)[0].image
                            }
                          />
                        </Zoom>
                      ) : (
                        'N/A'
                      )}
                    </>
                  ) : (
                    'N/A'
                  )}
                </TableCell>
                <TableCell>
                  <Button>
                    <TiTickOutline
                      onClick={(e) => showverify(row)}
                      title='Verify'
                      style={{ color: '#32C949', fontSize: '1.5rem' }}
                    />
                  </Button>
                  <Button>
                    <AiOutlineSearch
                      onClick={(e) => showinspect(row)}
                      title='Inspect'
                      style={{ color: '#FFAA00', fontSize: '1.5rem' }}
                    />
                  </Button>
                  <Button>
                    <AiOutlineCloseCircle
                      onClick={(e) => showreject(row)}
                      title='Reject'
                      style={{ color: '#FF3F2D', fontSize: '1.5rem' }}
                    />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color='primary'
      />
    </div>
  );
};

export default Request;
