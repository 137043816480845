import React, { useState } from 'react';
import CreateEvent from './CreateEvent';
import Button from '@mui/material/Button';
import { usePaginate } from '../../../hooks/usePaginate';
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { RxCross2 } from 'react-icons/rx';
import { Pagination } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update'; // Import the UpdateIcon
import IconButton from '@mui/material/IconButton';
import DeleteEvent from './DeleteEvent';
import Badge from '@mui/material/Badge';
import { Box } from '@material-ui/core';
import UpdateEvent from './UpdateEvent';

const Event = () => {
  const [events, setEvents] = useState([]);
  const {
    data,
    itemsPerPage,
    handlePageChange,
    totalPages,
    isLoading,
    currentPage,
  } = usePaginate(events, setEvents, 4, '/v1/events');
  const [showCreate, setShowCreate] = useState(false);
  const toggleCreate = () => setShowCreate(!showCreate);
  const [showDelete, setShowDelete] = useState(false);
  const toggleDelete = () => setShowDelete(!showDelete);
  const [deleteId, setDeleteId] = useState('');
  const [update_id, setUpdateID] = useState([]);
  const [showUpdateEvent, setShowUpdateEvent] = useState(false);
  const toggleUpdateEvent = () => setShowUpdateEvent(!showUpdateEvent);
  // console.log('events', events);

  const baseUrl = `${process.env.REACT_APP_PUBLIC_URL}`;

  const createShow = () => {
    setShowCreate(true);
  };

  const handleDelete = (_id) => {
    setDeleteId(_id);
    setShowDelete(true);
  };
  const handdleUpdate = (item) => {
    setUpdateID(item);
    setShowUpdateEvent(true);
  };
  // console.log('update_id', update_id.name);
  return (
    <div>
      {showCreate ? (
        <CreateEvent showCreate={showCreate} toggleCreate={toggleCreate} />
      ) : null}
      {showDelete ? (
        <DeleteEvent
          events={events}
          setEvents={setEvents}
          showDelete={showDelete}
          toggleDelete={toggleDelete}
          deleteId={deleteId}
        />
      ) : null}
      {showUpdateEvent ? (
        <UpdateEvent
          events={events}
          setEvents={setEvents}
          showUpdateEvent={showUpdateEvent}
          toggleUpdateEvent={toggleUpdateEvent}
          update_id={update_id}
        />
      ) : null}
      <Button onClick={createShow} variant='outlined'>
        Create Event
      </Button>
      <Grid container spacing={2} style={{ marginTop: '2rem' }}>
        {events.map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <Card
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {item.status !== 'deleted' ? (
                <IconButton
                  style={{ marginLeft: 'auto', marginTop: '-10px' }}
                  onClick={() => handleDelete(item._id)} // Replace with your delete function
                >
                  <DeleteIcon style={{ color: 'red' }} />
                </IconButton>
              ) : null}
              <IconButton
                style={{ marginLeft: 'auto', marginTop: '-10px' }}
                onClick={() => handdleUpdate(item)} // Replace with your delete function
              >
                <UpdateIcon style={{ color: 'blue' }} />
              </IconButton>

              {item.image !== null && item.image !== undefined ? (
                <>
                  {item.image ? (
                    <>
                      <Zoom>
                        <CardMedia
                          component='img'
                          alt={item.name}
                          height='100'
                          image={
                            baseUrl +
                            'static/' +
                            item.image.replace('public/', '')
                          }
                          style={{ objectFit: 'contain' }}
                        />
                      </Zoom>
                    </>
                  ) : null}
                </>
              ) : null}

              <CardContent style={{ flex: 1 }}>
                <Typography variant='h5' component='div'>
                  Event name: {item.name}
                </Typography>
                <Typography variant='h5' component='div'>
                  Category:{item.category}
                </Typography>
                <Typography variant='h5' component='div'>
                  Created by:{item.createdBy.name}
                </Typography>
                <Typography
                  variant='h5'
                  component='div'
                  style={{ display: 'flex' }}
                >
                  <span style={{ marginRight: '2rem' }}>Status:</span>
                  <Box ml={1}>
                    {item.status === 'deleted' ? (
                      <Badge badgeContent='Deleted' color='error'></Badge>
                    ) : (
                      <>
                        {item.status === 'new' ? (
                          <Badge badgeContent='New' color='primary'></Badge>
                        ) : null}
                      </>
                    )}
                  </Box>
                </Typography>
                <Typography variant='h5' component='div'>
                  Start Date:
                  {item.startDate !== null
                    ? item.startDate.split('T')[0]
                    : 'N/A'}
                </Typography>
                <Typography variant='h5' component='div'>
                  End Date:
                  {item.endDate !== null ? item.endDate.split('T')[0] : 'N/A'}
                </Typography>
                <Typography
                  variant='h5'
                  component='div'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Is Active:
                  {item.isActive === true ? (
                    <DoneOutlineIcon style={{ color: 'green' }} />
                  ) : (
                    <RxCross2 size='30' color='red' />
                  )}
                </Typography>

                <Typography
                  variant='h5'
                  component='div'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Is Special:
                  {item.isSpecial === true ? (
                    <DoneOutlineIcon style={{ color: 'green' }} />
                  ) : (
                    <RxCross2 size='30' color='red' />
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color='primary'
      />
    </div>
  );
};

export default Event;
