import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { useFetch } from '../../../hooks/useFetch';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { AiFillCheckCircle } from 'react-icons/ai';
import { debounce } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Step 1';
    case 1:
      return 'Step 2';
    case 2:
      return 'Step 3';
    default:
      return 'Unknown step';
  }
}

const CreateApiKey = ({ open, handleClose, keys, setKeys }) => {
  const [alias, setAlias] = useState('');
  const [domain_name, setDomainName] = useState('');
  // const [max_hit, setMaxHit] = useState(0);
  const [errors, setErrors] = useState([]);
  const [checked, setChecked] = useState(false);
  const [expiration_date, setExpirationDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState([]);
  const { data } = useFetch(services, setServices, '/v1/services');
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = [
    'Create an access token for customer',
    'Select the services for customer',
    'Complete',
  ];
  const [value, setValue] = useState('');
  const [domainCheck, setDomainCheck] = useState(false);
  const [domainNameErrorMessage, setErrorMessage] = useState('');
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedWords, setSelectedWords] = useState([]);
  const [newError, setNewError] = useState(false);

  // const handleInputChange = (event) => {
  //   setInputValue(event.target.value);
  // };

  // console.log('inputvalue', inputValue);
  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      const word = inputValue.trim();
      if (word !== '') {
        setSelectedWords([...selectedWords, word]);
        setInputValue('');
      }
    }
  };
  // console.log('length', inputValue.length);
  const handleWordClick = (index) => {
    const updatedWords = [...selectedWords];
    setSelectedWords(updatedWords);
    const final = updatedWords.join(';');
    setDomainName(final);
  };
  const handleRemoveWord = (index) => {
    const updatedWords = [...selectedWords];
    updatedWords.splice(index, 1);
    setSelectedWords(updatedWords);
    const final = updatedWords.join(';');
    setDomainName(final);
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    const defaultDate = new Date();
    defaultDate.setFullYear(defaultDate.getFullYear() + 1);
    var parsedDate = moment(defaultDate, 'M/D/YYYY');
    var formattedDate = parsedDate.format('YYYY-MM-DDTHH:mm');
    setExpirationDate(formattedDate);
  }, [1]);

  useEffect(() => {
    const token = window.localStorage.getItem('galli_token');
    const decoded = jwt_decode(token);
    setValue(decoded.aud);
  }, []);

  // console.log("values",value);

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleItemClick = (datas) => {
    // console.log('datani', datas);
    const index = selectedItems.indexOf(datas);
    if (index > -1) {
      // Item already selected, remove it from the array
      setSelectedItems(selectedItems.filter((i) => i !== datas));
      setTotalSum(totalSum - datas.rate);
    } else {
      // Item not selected, add it to the array
      setSelectedItems([...selectedItems, datas]);
      setTotalSum(totalSum + datas.rate);
    }
  };
  // console.log('selectedItems',selectedItems)
  // console.log('totalsum',totalSum)

  ///dns-lookup

  // useEffect(() => {}, [domain_name]);
  // console.log('domain_name', domain_name);

  const checkDomainAvailability = async () => {
    try {
      const response = await axiosInstance.post('/v1/dns-lookup', {
        domain_name: inputValue,
      });
      // console.log(response.data.data);
      if (response.data.data === true) {
        setDomainCheck(true);
        setShow(false);
        setInputValue('');
        if (domain_name.length > 0 || domain_name === '*') {
          const finaldomain = domain_name + ';' + inputValue;
          setDomainName(finaldomain);
        } else {
          setDomainName(inputValue);
        }
      }
    } catch (error) {
      // console.log(error.response.data.message);

      if (error.response.data) {
        setErrorMessage(error.response.data.message[0].message);
        setDomainCheck(false);
        setShow(true);
      }
      // setErrorMessage(error);
      setNewError(true);
      setDomainCheck(false);
      setShow(true);
    }
  };

  // console.log(domainNameErrorMessage);
  useEffect(() => {
    if (domain_name.length >= 1) {
      const domainNamesArray = domain_name.split(';');
      setSelectedWords(domainNamesArray);
    }
  }, [domain_name]);

  const removedomain = (domain) => {
    // console.log(domain);
    const newselectedWord = selectedWords.filter((i) => i !== domain);
    setSelectedWords(newselectedWord);
    const domainNamesArray = domain_name.split(';');
    const filteredDomain = domainNamesArray.filter((i) => i !== domain);
    const joinedFilter = filteredDomain.join(';');
    setDomainName(joinedFilter);
  };

  // useEffect(() => {}, [domain_name]);

  // const debouncedCheckDomainAvailability = debounce(
  //   checkDomainAvailability,
  //   1000,
  // );

  // const handleDomainChange = (event) => {
  //   const domain_name = event.target.value;
  //   setDomainName(domain_name);
  //   checkDomainAvailability(domain_name);
  //   if (domain_name) {
  //     debouncedCheckDomainAvailability(domain_name);
  //   }
  // };

  // console.log('selectedWords', selectedWords);

  const handleSubmit = () => {
    axiosInstance
      .post('/v1/api-keys/', {
        alias,
        domain_name,
        expiration_date,
        user: value,
        services: services,
      })
      .then((resp) => {
        setIsLoading(true);
        if (resp.status === 201) {
          setIsLoading(false);

          const newKey = [
            ...keys,
            {
              alias: resp.data.data.alias,
              domain_name: resp.data.data.domain_name,
              counter: resp.data.data.counter,
              expire: resp.data.data.expire,
              _id: resp.data.data._id,
              createdAt: resp.data.data.createdAt,
              updatedAt: resp.data.data.updatedAt,
              access_key: resp.data.data.access_key,
              expiration_date: resp.data.data.expiration_date,
            },
          ];
          setKeys(newKey);
          handleClose();
          toast.success('Api Keys created successfully');
          // window.location.reload();
        }
      })
      .catch((errors) => {
        // console.log(errors.response.data.message);
        if (errors) {
          setIsLoading(false);

          setErrors(errors.response.data.message);
        }
      });
  };
  const isStep0Complete = () => {
    // Add conditions to check if all required fields are filled
    return (
      alias.trim() !== '' &&
      domain_name.trim() !== '' &&
      expiration_date.trim() !== '' &&
      domainCheck
    );
  };
  return (
    <div>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        fullWidth // Expand to full width
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>{'Create Access Token'}</DialogTitle>
        {isLoading ? (
          <Box style={{ position: 'relative' }}>
            <CircularProgress
              size={40}
              left={-20}
              top={10}
              status={'loading'}
              style={{ marginLeft: '50%' }}
            />
          </Box>
        ) : (
          <>
            <DialogContent>
              <div className={classes.root}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    // if (isStepOptional(index)) {
                    //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
                    // }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& > :not(style)': { m: 2 },
                  }}
                >
                  <FormControl>
                    {errors.map((error) => {
                      return <Alert severity='error'>{error.message}</Alert>;
                    })}

                    {activeStep === 0 && (
                      <>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              placeholder='Enter the Alias'
                              id='demo-helper-text-aligned'
                              label='Alias'
                              value={alias}
                              onChange={(e) => setAlias(e.target.value)}
                              helperText='Enter a unique name for the access token key.'
                            />
                            <br />
                            <br />
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <TextField
                                label='Enter Domain or sub-domain'
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                helperText={
                                  domainNameErrorMessage ? (
                                    domainNameErrorMessage
                                  ) : (
                                    <>
                                      {newError ? (
                                        <p>{inputValue} is not allowed</p>
                                      ) : (
                                        <p>
                                          Enter the domain(s) to which this API
                                          should be restricted. Requests from
                                          other domains will be denied. For
                                          testing purposes, you can use '*' to
                                          allow access from all domains. To
                                          allow multiple domains, list them
                                          separated by a semicolon (';').
                                        </p>
                                      )}
                                    </>
                                  )
                                }
                                fullWidth
                                error={show}
                              />
                            </div>
                            {domainCheck && (
                              <AiFillCheckCircle style={{ color: 'green' }} />
                            )}

                            <br />
                            <List>
                              {selectedWords.map((domain, index) => (
                                <>
                                  <ListItem key={index}>
                                    {domain}
                                    <Button
                                      variant='contained'
                                      color='error'
                                      style={{ marginLeft: '1rem' }}
                                      onClick={(e) => removedomain(domain)}
                                    >
                                      Remove
                                    </Button>
                                  </ListItem>
                                </>
                              ))}
                            </List>
                            <br />
                            {/* <TextField
                              placeholder='Enter the Domain Name '
                              id='demo-helper-text-aligned-no-helper'
                              label='Domain Name'
                              // disabled
                              InputProps={{ readOnly: true }}
                              value={domain_name}
                              onChange={handleDomainChange}
                              helperText={
                                domainNameErrorMessage
                                  ? domainNameErrorMessage
                                  : "Please Provide your website's domain. If not available, indicate that by inserting an asterisk (*)."
                              }
                              error={show}
                            /> */}
                            {/* {domainCheck && (
                              <AiFillCheckCircle style={{ color: 'green' }} />
                            )} */}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id='datetime-local'
                              helperText='Add expiry date '
                              type='datetime-local'
                              defaultValue='2017-05-24T10:30'
                              value={expiration_date}
                              onChange={(e) =>
                                setExpirationDate(e.target.value)
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled
                            />
                            <Button
                              style={{ marginTop: '3rem', marginLeft: '2rem' }}
                              variant='contained'
                              color='success'
                              onClick={checkDomainAvailability}
                            >
                              Verify and Add
                            </Button>
                          </Grid>
                        </Grid>

                        {/* <label>Select User</label> */}
                        {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label="User type"
                 onChange={(event: SelectChangeEvent) => {
                    console.log(event.target.value)
                    setValue(event.target.value);
                  }}
                >
                    {users.map(user=>{
                        return <MenuItem value={user._id}>{user.username}</MenuItem>
                        
                    })}
           
                </Select> */}
                        {/* <FormControlLabel 
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label="Select date"
                    /> */}
                      </>
                    )}

                    {activeStep === 1 && (
                      <>
                        <label>Select the services</label>
                        {data.map((datas) => {
                          return (
                            <>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked
                                    // onChange={()=>handleItemClick(datas)}
                                    inputProps={{
                                      'aria-label': 'primary checkbox',
                                    }}
                                  />
                                }
                                label={datas.name}
                              />
                            </>
                          );
                        })}
                        <br />
                        {/* <Grid container  alignItems="center">
                  Total :
                  <Grid item>
                  <label>
                     <h1>{totalSum}</h1>
                    </label>
                  </Grid>
                 </Grid> */}
                      </>
                    )}
                  </FormControl>
                </Box>

                <div>
                  {activeStep === steps.length ? (
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                      <Button onClick={handleSubmit}>
                        Create Access Token{' '}
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Typography className={classes.instructions}>
                        {getStepContent(activeStep)}
                      </Typography>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={handleNext}
                          className={classes.button}
                          disabled={!isStep0Complete()}
                        >
                          {activeStep === steps.length - 1
                            ? 'Complete'
                            : 'Next'}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default CreateApiKey;
