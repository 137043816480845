import React from 'react';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axiosInstance from '../../../utils/axios';

const Pay = ({ toggleConfirm, showConfirmPayment, invoiceId }) => {
  const confirmPayment = () => {
    axiosInstance.put(`/v1/users/${invoiceId}/invoicePaid`).then((resp) => {
      if (resp) {
        window.location.reload();
      }
    });
  };
  return (
    <div>
      <Dialog
        open={showConfirmPayment}
        onClose={toggleConfirm}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Pay the invoice'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure want to complete the payment
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmPayment}>Confirm</Button>
          <Button onClick={toggleConfirm} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Pay;
