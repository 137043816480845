import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import axiosInstance from '../../../utils/axios';
import { toast } from 'react-toastify';

const categories = ['Sports', 'Jatra', 'Music'];

const CreateEvent = ({ showCreate, toggleCreate }) => {
  const [name, setName] = useState('');
  const [category, setCategory] = useState();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isActive, setIsactive] = useState(false);
  const [isSpecial, setIsSpecial] = useState(true);
  const [status, setStatus] = useState();
  const [hasPamplet, setHasPamplet] = useState(false);
  const [points, setPoints] = useState('');
  const [line, setLine] = useState('');
  const [type, setType] = useState('');
  const [pampletImageFile, setPamplateImageFile] = useState();
  const [createdById, setCreatedById] = useState('643e39caee3547cf9d69f67d');
  const [createdByName, setCreateByName] = useState('abhises');
  const [createdByRole, setCreatedByRole] = useState('admin');
  const [clickLink, setClickLink] = useState('');
  const [publishDate, setPublishDate] = useState('');
  const [imageFile, setImageFile] = useState();
  const [errors, setErrors] = useState([]);
  const [callToAction, setCallToAction] = useState('');
  const [bannerImageFile, setBannerImageFile] = useState();
  const [location, setLocation] = useState('');
  const [price, setPrice] = useState('');
  const handdleSubmit = () => {
    const formData = new FormData();

    formData.append('name', name);
    // formData.append('category', category);
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    formData.append('createdById', createdById);
    formData.append('createdByName', createdByName);
    formData.append('createdByRole', createdByRole);
    // formData.append('clickLink', clickLink);
    formData.append('publishDate', publishDate);
    formData.append('line', line);
    formData.append('type', type);
    formData.append('points', points);
    formData.append('hasPamplet', String(hasPamplet));
    // formData.append('status', status);
    formData.append('isActive', String(isActive));
    formData.append('isSpecial', String(isSpecial));
    formData.append('callToAction', callToAction);
    formData.append('location', location);
    formData.append('price', price);

    if (imageFile) {
      // Append image file as a Blob
      formData.append('imageFile', imageFile, imageFile.name);
    }

    if (pampletImageFile) {
      // Append pamplet image file as a Blob
      formData.append(
        'pampletImageFile',
        pampletImageFile,
        pampletImageFile.name,
      );
    }
    if (bannerImageFile) {
      formData.append('bannerImageFile', bannerImageFile, bannerImageFile.name);
    }
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    axiosInstance
      .post('/v1/events', formData, config)
      .then((resp) => {
        if (resp.status === 201) {
          toggleCreate();
          toast.success(resp.data.message);
        }
      })
      .catch((error) => {
        console.log('error', error.response.data.message);
        setErrors(error.response.data.message);
      });
  };
  return (
    <div>
      <Dialog
        open={showCreate}
        keepMounted
        onClose={toggleCreate}
        maxWidth='md'
        fullWidth
        style={{ minHeight: '600px' }}
      >
        <DialogTitle>{'Create an Event'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {/* First Column */}
            <Grid item xs={9}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl>
                    <TextField
                      label='Name'
                      fullWidth
                      placeholder='Enter name'
                      id='demo-helper-text-aligned'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      error={errors.some((error) => error.path[0] === 'name')}
                      helperText={
                        errors
                          .find((error) => error.path[0] === 'name')
                          ?.message.replace(/"/g, '') || ''
                      }
                    />
                    {/* <br />
                    <label>Select Category</label>
                    <Select
                      id='demo-simple-select'
                      value={category}
                      onChange={(event) => {
                        setCategory(event.target.value);
                      }}
                    >
                      {categories.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <br />
                    <TextField
                      id='datetime-local'
                      label='Start date'
                      type='datetime-local'
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      error={errors.some(
                        (error) => error.path[0] === 'startDate',
                      )}
                      helperText={
                        errors
                          .find((error) => error.path[0] === 'startDate')
                          ?.message.replace(/"/g, '') || ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <br />
                    <TextField
                      id='datetime-local'
                      label='End date'
                      type='datetime-local'
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      error={errors.some(
                        (error) => error.path[0] === 'endDate',
                      )}
                      helperText={
                        errors
                          .find((error) => error.path[0] === 'endDate')
                          ?.message.replace(/"/g, '') || ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <br />

                    <TextField
                      id='datetime-local'
                      label='Publish date'
                      type='datetime-local'
                      value={publishDate}
                      onChange={(e) => setPublishDate(e.target.value)}
                      error={errors.some(
                        (error) => error.path[0] === 'publishDate',
                      )}
                      helperText={
                        errors
                          .find((error) => error.path[0] === 'publishDate')
                          ?.message.replace(/"/g, '') || ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <br />
                    <TextField
                      label='Location'
                      fullWidth
                      placeholder='Enter Location'
                      id='demo-helper-text-aligned'
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      error={errors.some(
                        (error) => error.path[0] === 'location',
                      )}
                      helperText={
                        errors
                          .find((error) => error.path[0] === 'location')
                          ?.message.replace(/"/g, '') || ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <br />
                    <TextField
                      label='Call to Action'
                      fullWidth
                      placeholder='Enter call to action'
                      id='demo-helper-text-aligned'
                      value={callToAction}
                      onChange={(e) => setCallToAction(e.target.value)}
                      error={errors.some(
                        (error) => error.path[0] === 'callToAction',
                      )}
                      helperText={
                        errors
                          .find((error) => error.path[0] === 'callToAction')
                          ?.message.replace(/"/g, '') || ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <TextField
                      label='Price'
                      fullWidth
                      placeholder='Enter Price'
                      id='demo-helper-text-aligned'
                      value={price}
                      error={errors.some((error) => error.path[0] === 'price')}
                      helperText={
                        errors
                          .find((error) => error.path[0] === 'price')
                          ?.message.replace(/"/g, '') || ''
                      }
                      onChange={(e) => setPrice(e.target.value)}
                    />
                    <br />
                    <TextField
                      label='Point'
                      fullWidth
                      placeholder='Enter Point'
                      id='demo-helper-text-aligned'
                      value={points}
                      error={errors.some((error) => error.path[0] === 'points')}
                      helperText={
                        errors
                          .find((error) => error.path[0] === 'points')
                          ?.message.replace(/"/g, '') || ''
                      }
                      onChange={(e) => setPoints(e.target.value)}
                    />
                    <br />
                    <TextField
                      label='Line'
                      fullWidth
                      placeholder='Enter line'
                      id='demo-helper-text-aligned'
                      value={line}
                      error={errors.some((error) => error.path[0] === 'line')}
                      helperText={
                        errors
                          .find((error) => error.path[0] === 'line')
                          ?.message.replace(/"/g, '') || ''
                      }
                      onChange={(e) => setLine(e.target.value)}
                    />
                    <br />

                    <TextField
                      label='Type'
                      fullWidth
                      placeholder='Enter type'
                      id='demo-helper-text-aligned'
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      error={errors.some((error) => error.path[0] === 'type')}
                      helperText={
                        errors
                          .find((error) => error.path[0] === 'type')
                          ?.message.replace(/"/g, '') || ''
                      }
                    />
                    <br />
                    <TextField
                      label='Click link'
                      fullWidth
                      placeholder='Enter Click Link'
                      id='demo-helper-text-aligned'
                      value={clickLink}
                      error={errors.some(
                        (error) => error.path[0] === 'clickLink',
                      )}
                      helperText={
                        errors
                          .find((error) => error.path[0] === 'clickLink')
                          ?.message.replace(/"/g, '') || ''
                      }
                      onChange={(e) => setClickLink(e.target.value)}
                    />
                    <br />
                    <label>Select image file</label>
                    <br />
                    <input
                      type='file'
                      accept='image/*'
                      onChange={(e) => setImageFile(e.target.files[0])}
                    />
                    <br />
                    <p style={{ color: 'red' }}>
                      {errors
                        .find((error) => error.path[0] === 'image')
                        ?.message.replace(/`/g, '') || ''}
                    </p>
                    {imageFile && (
                      <img
                        src={URL.createObjectURL(imageFile)}
                        alt='Selected Image'
                        style={{ maxWidth: '100%' }}
                      />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {/* Second Column */}
            <Grid item xs={3}>
              <FormControl>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '10px', marginBottom: '0' }}>
                    Is Active
                  </label>
                  <Checkbox
                    onChange={() => setIsactive(!isActive)}
                    checked={isActive}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '10px', marginBottom: '0' }}>
                    Is Special
                  </label>
                  <Checkbox
                    onChange={() => setIsSpecial(!isSpecial)}
                    checked={isSpecial}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '10px', marginBottom: '0' }}>
                    Has Pamplet
                  </label>
                  <Checkbox
                    onChange={() => setHasPamplet(!hasPamplet)}
                    checked={hasPamplet}
                  />
                </div>
                <br />
                {hasPamplet ? (
                  <>
                    <label>Select Pamplet Image File </label>
                    <br />
                    <input
                      type='file'
                      accept='image/*'
                      onChange={(e) => setPamplateImageFile(e.target.files[0])}
                    />
                    <br />

                    {pampletImageFile && (
                      <img
                        src={URL.createObjectURL(pampletImageFile)}
                        alt='Selected Image'
                        style={{ maxWidth: '100%' }}
                      />
                    )}
                  </>
                ) : null}
                <label>Select Banner Image File </label>
                <br />
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => setBannerImageFile(e.target.files[0])}
                />
                <br />

                {bannerImageFile && (
                  <img
                    src={URL.createObjectURL(bannerImageFile)}
                    alt='Selected Image'
                    style={{ maxWidth: '100%' }}
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handdleSubmit}>Create</Button>
          <Button onClick={toggleCreate}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateEvent;
