import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const VerifyEmail = () => {
    const location = useLocation();
    const url = (location.pathname);
    const parts = url.split("/");
    const value = parts[parts.length - 1];
    const navigate  =useNavigate();

    const verifyEmail = async ()=>{
        axiosInstance.put('v1/verify-Email',{token:value}).then(resp=>{
            if(resp.status===200){
                toast.success('Email verified')
                navigate('/');

            }
        })

    }

    useEffect(()=>{
        verifyEmail();
    },[])


  return (
    <div>VerifyEmail</div>
  )
}

export default VerifyEmail