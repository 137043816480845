import { useState, useEffect } from 'react';
import axiosInstance from '../utils/axios';

export const usePaginate = (data, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const pageData = data.slice(startIndex, endIndex);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return { currentPage, totalPages, pageData, handlePageChange };
};
