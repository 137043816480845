import React, { useState, useEffect } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import useCapitalize from '../../../hooks/useCapitalize';
import {
  Box,
  Grid,
  Card,
  Container,
  Typography,
  CardHeader,
  MenuItem,
  FormControl,
  CardContent,
  Select,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Buy from './Buy';
import Upgrade from './Upgrade';
import axiosInstance from '../../../utils/axios';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import 'nepali-datepicker-reactjs/dist/index.css';
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';
import NepaliDate from 'nepali-date-converter';

import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import EditProfile from './EditProfile';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import './Dashboard.css';

const CDashboard = () => {
  const [keys, setKeys] = useState([]);
  const [services, setServices] = useState([]);
  const { data } = useFetch(services, setServices, '/v1/services');
  const [rate, setRate] = useState([]);

  // const {data,isLoading}=useFetch(keys,setKeys,'/v1/getLoginUserApikey')
  const [user, setUser] = useState([]);
  const {} = useFetch(user, setUser, '/v1/getLoginUser');
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  const [totalHits, setHits] = useState([]);
  const [realHits, setRealHits] = useState([]);
  const [newDetail, setDetail] = useState([]);

  const [access, setAccess] = useState('');
  const [showEdit, setShowEdit] = useState(false);
  const toggleEdit = () => setShowEdit(!showEdit);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const toggleUpgrade = () => setShowUpgrade(!showUpgrade);
  const [selectedOption, setSelectedOption] = useState('option1'); // 'option1' is the default value for "This Week"
  // const handleSelectChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };
  const [newNepalidate, setNepaliDate] = useState([]);
  const [activeFilter, setActiveFilter] = useState('button');
  const [mapData, setMapData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [routeData, setRouteData] = useState([]);
  const [totals, setTotals] = useState('');
  const [newOption, setNewOption] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);
  const tableContainerStyle = {
    maxHeight: 600, // Set the maximum height as needed
    // overflowY: 'auto',
  };
  const [serviceData, setServiceData] = useState([]);
  const [selectService, setSelectService] = useState('Distance');
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  // console.log(selectedFromDate, selectedFromDate);
  const handleServiceChange = (event) => {
    setSelectService(event.target.value);
  };
  const handleFromDateChange = (value) => {
    // Convert the Nepali date to an English date
    const nepaliDate = new NepaliDate(value);
    const englishDate = nepaliDate.toJsDate(); // This converts to a JavaScript Date object
    setSelectedFromDate(englishDate);
  };
  // console.log('selectedFromDate', selectedFromDate);

  const handleToDateChange = (value) => {
    // Convert the Nepali date to an English date
    const nepaliDate = new NepaliDate(value);
    const englishDate = nepaliDate.toJsDate(); // This converts to a JavaScript Date object
    setSelectedToDate(englishDate);
  };

  const maxDate = new Date();
  const today = new Date();
  const dayBeforeYesterday = new Date();
  dayBeforeYesterday.setDate(today.getDate() - 2);
  const containerStyle = {
    display: 'flex',
    flex: 3,
    gap: '20px',
    border: '1px solid #ccc',
    marginTop: '2px',
    // height: '100px',
  };
  useEffect(() => {
    const getAllData = async () => {
      const response = await axiosInstance('/v1/rates?limit=1');
      if (response.status === 200) {
        setRate(response.data.data.results);
      }
    };
    getAllData();
  }, []);
  useEffect(() => {
    const finalDetail = rate.map((rates) => {
      return rates.details;
    });
    setDetail(finalDetail);
  }, [rate]);

  useEffect(() => {
    axiosInstance('/v1/getLoginUserApikey').then((resp) => {
      // console.log('nepalko', resp);
      if ((resp.status = 200)) {
        if (resp.data.data.length > 0) {
          setAccess(resp.data.data[0].access_key);
        }
      }
    });
  }, []);
  const customDatePickerStyle = {
    padding: '1rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '100px',
    height: '50px',
    color: 'red',
  };
  const customStyles = {
    datePicker: {
      // Style the DatePicker container
      width: '200px', // Adjust the width to your desired value
      height: '100px',
    },
    input: {
      // Style the input field
      color: 'blue', // Change text color to blue
      height: '100px', // Change the height of the input field
    },
  };
  // console.log("selectedOption",selectedOption)
  // console.log('key', access);
  const handleOption4Click = async () => {
    setNewOption('option4');
    setButtonClicked(true);
    if (access) {
      try {
        let fromDate, toDate;

        fromDate = selectedFromDate;
        toDate = selectedToDate;

        const response = await axiosInstance.get(
          `v1/api-keys/${access}/services/hits`,
          {
            params: {
              fromDate,
              toDate,
            },
          },
        );

        if (response.status === 200) {
          // console.log('hiii111', response.data.data);
          const updatedData = response.data.data.map((item) => {
            return {
              totalByDay: item.totalByDay,
              totalServiceHits: item.totalServiceHits,
              successfulServiceHits: item.successfulServiceHits,
              service:
                item.service.charAt(0).toUpperCase() + item.service.slice(1),
              category:
                item.category.charAt(0).toUpperCase() +
                item.category.slice(1).toLowerCase(),
              totalCreditPoints: item.totalCreditPoints,
              total: item.totalCreditPoints * 50,
            };
          });
          const sortedData = updatedData.sort((a, b) =>
            a.service.localeCompare(b.service),
          );

          const grandTotal = updatedData.reduce(
            (totalSum, item) => totalSum + item.total,
            0,
          );
          setTotals(grandTotal);
          setHits(sortedData);
          setRealHits(updatedData);
        }
      } catch (error) {
        // Handle error if necessary
      }
    }
  };
  useEffect(() => {
    handleOption4Click();
  }, [buttonClicked]);
  const getAllData = async () => {
    if (access) {
      try {
        let fromDate, toDate;

        // Determine the date range based on the selected option
        if (selectedOption === 'option1') {
          // For "This Week"
          const today = new Date();
          const startDate = new Date(today);
          startDate.setDate(startDate.getDate() - 7); // Subtract 6 days to get the start date for the last seven days
          const endDate = new Date(today);

          fromDate = startDate.toISOString().split('T')[0];
          toDate = endDate.toISOString().split('T')[0];
          // const reversedFromDate = new Date(fromDate.split('T')[0]);
          // const reversedToDate = new Date(toDate.split('T')[0]);
          // // console.log('reversedFromDate', reversedFromDate);
          // setSelectedFromDate(reversedFromDate);
          // setSelectedToDate(reversedToDate);
        } else if (selectedOption === 'option2') {
          const neapliToday = new NepaliDate(Date.now());
          const nepaliStartofMonth = neapliToday.getMonth();
          const nepaliyear = neapliToday.getYear();
          const finaldate = new NepaliDate(
            `${nepaliyear}`,
            `${nepaliStartofMonth}`,
            1,
          );
          const results = finaldate.toJsDate();
          // console.log('nepaliStartofMonth', results);

          // // For "This Month"
          // const today = new Date();
          // const startOfMonth = new Date(
          //   today.getFullYear(),
          //   today.getMonth(),
          //   2,
          // );
          // startOfMonth + 1;
          // Set the day to the 1st day of the month

          const endDate = new Date(today); // Use the current date
          endDate.setDate(today.getDate()); // Set the day to the current day of the month

          // fromDate = startOfMonth.toISOString().split('T')[0];
          fromDate = results;
          toDate = endDate.toISOString().split('T')[0];
          // const reversedFromDate = new Date(fromDate.split('T')[0]);
          // const reversedToDate = new Date(toDate.split('T')[0]);
          // setSelectedFromDate(reversedFromDate);
          // setSelectedToDate(reversedToDate);
        } else if (selectedOption === 'option3') {
          const today = new Date();
          const startDate = new Date(today);
          startDate.setDate(startDate.getDate() - today.getDay() - 90); // Get the start of the week (Sunday) one week ago
          const endDate = new Date(today);

          fromDate = startDate.toISOString().split('T')[0];
          toDate = endDate.toISOString().split('T')[0];
          // const reversedFromDate = new Date(fromDate.split('T')[0]);
          // const reversedToDate = new Date(toDate.split('T')[0]);

          // setSelectedFromDate(reversedFromDate);
          // setSelectedToDate(reversedToDate);
        }
        //  else if (buttonClicked) {
        // fromDate = selectedFromDate;
        // toDate = selectedToDate;
        // fromDate = selectedFromDate.toISOString().split('T')[0];
        // toDate = selectedToDate.toISOString().split('T')[0];
        // const reversedFromDate = new Date(fromDate.split('T')[0]);
        // const reversedToDate = new Date(toDate.split('T')[0]);
        // setSelectedFromDate(reversedFromDate);
        // setSelectedToDate(reversedToDate);
        // }

        const response = await axiosInstance.get(
          `v1/api-keys/${access}/services/hits`,
          {
            params: {
              fromDate,
              toDate,
            },
          },
        );

        if (response.status === 200) {
          // console.log('hiii111', response.data.data);
          const updatedData = response.data.data.map((item) => {
            return {
              totalByDay: item.totalByDay,
              totalServiceHits: item.totalServiceHits,
              successfulServiceHits: item.successfulServiceHits,
              service:
                item.service.charAt(0).toUpperCase() + item.service.slice(1),
              category:
                item.category.charAt(0).toUpperCase() +
                item.category.slice(1).toLowerCase(),
              totalCreditPoints: item.totalCreditPoints,
              total: item.totalCreditPoints * 50,
            };
          });
          const sortedData = updatedData.sort((a, b) =>
            a.service.localeCompare(b.service),
          );

          const grandTotal = updatedData.reduce(
            (totalSum, item) => totalSum + item.total,
            0,
          );
          setTotals(grandTotal);
          setHits(sortedData);
          setRealHits(updatedData);
        }
      } catch (error) {
        // Handle error if necessary
      }
    }
  };
  useEffect(() => {
    getAllData();
  }, [access, selectedOption]);

  // console.log('totalHitsnew', totalHits);
  // console.log('selectService', selectService);

  useEffect(() => {
    const data = totalHits.find((item) => item.service === selectService);
    // setServiceData(data);
    if (data) {
      const lastMonth = data.totalByDay.map((entry) => {
        const dateObject = new Date(entry.date);
        const monthName = dateObject.toLocaleDateString(undefined, {
          month: 'short',
        });
        const dayOfMonth = dateObject.toLocaleDateString(undefined, {
          day: '2-digit',
        });
        return { ...entry, dayOfWeek: `${monthName} ${dayOfMonth}` };
      });
      setServiceData(lastMonth);
    } else {
      setServiceData([]);
    }
  }, [selectService, selectedOption, totalHits]);
  // console.log('serviceDataserviceData', newNepalidate);

  useEffect(() => {
    const finaldata = serviceData.map((item) => {
      const formattedDate = new NepaliDate(new Date(item.date));
      const newFormat = formattedDate.format(' DD MMMM', 'en');

      return {
        date: item.date,
        successfulHits: item.successfulHits,
        totalHits: item.totalHits,
        creditPoints: item.creditPoints,
        dayOfWeek: item.dayOfWeek,
        nepaliDate: newFormat,
      };
    });
    setNepaliDate(finaldata);
  }, [serviceData]);
  // console.log('serviceDataserviceData', newNepalidate);

  // const [date, setDate] = useState();
  // console.log('datedate', date);
  // const handleDate = (date) => {
  //   setDate(date);
  //   console.log('Date', date);
  // };

  // console.log('totalHits', totalHits);

  // useEffect(() => {
  //   // Find the object with service name 'Map'
  //   const mapService = totalHits.find((item) => item.category === 'Map');
  //   const searchService = totalHits.find((item) => item.category === 'Search');
  //   const imageService = totalHits.find((item) => item.category === 'Image');
  //   const routeService = totalHits.find((item) => item.category === 'Route');

  //   // If the object is found, update the state with the 'totalByDay' array
  //   if (mapService) {
  //     if (selectedOption === 'option1') {
  //       const lastSeven = mapService.totalByDay.slice(-7).map((entry) => {
  //         const dateObject = new Date(entry.date);
  //         const dayOfWeek = dateObject.toLocaleDateString(undefined, {
  //           weekday: 'short',
  //         }); // Get the full day name
  //         return { ...entry, dayOfWeek };
  //       });
  //       setMapData(lastSeven);
  //     } else if (selectedOption === 'option2') {
  //       const lastMonth = mapService.totalByDay.map((entry) => {
  //         const dateObject = new Date(entry.date);
  //         const monthName = dateObject.toLocaleDateString(undefined, {
  //           month: 'short',
  //         });
  //         const dayOfMonth = dateObject.toLocaleDateString(undefined, {
  //           day: '2-digit',
  //         });
  //         return { ...entry, dayOfWeek: `${monthName} ${dayOfMonth}` };
  //       });
  //       setMapData(lastMonth);
  //     } else {
  //       const lastMonth = mapService.totalByDay.slice(-100).map((entry) => {
  //         const dateObject = new Date(entry.date);
  //         const monthName = dateObject.toLocaleDateString(undefined, {
  //           month: 'short',
  //         });
  //         const dayOfMonth = dateObject.toLocaleDateString(undefined, {
  //           day: '2-digit',
  //         });
  //         return { ...entry, dayOfWeek: `${monthName} ${dayOfMonth}` };
  //       });
  //       setMapData(lastMonth);
  //     }
  //   }
  //   if (searchService) {
  //     if (selectedOption === 'option1') {
  //       const lastSeven = searchService.totalByDay.slice(-7).map((entry) => {
  //         const dateObject = new Date(entry.date);
  //         const dayOfWeek = dateObject.toLocaleDateString(undefined, {
  //           weekday: 'short',
  //         }); // Get the full day name
  //         return { ...entry, dayOfWeek };
  //       });
  //       setSearchData(lastSeven);
  //     } else if (selectedOption === 'option2') {
  //       const lastMonth = searchService.totalByDay.slice(-30).map((entry) => {
  //         const dateObject = new Date(entry.date);
  //         const monthName = dateObject.toLocaleDateString(undefined, {
  //           month: 'short',
  //         });
  //         const dayOfMonth = dateObject.toLocaleDateString(undefined, {
  //           day: '2-digit',
  //         });
  //         return { ...entry, dayOfWeek: `${monthName} ${dayOfMonth}` };
  //       });
  //       setSearchData(lastMonth);
  //     } else {
  //       const lastMonth = searchService.totalByDay.slice(-100).map((entry) => {
  //         const dateObject = new Date(entry.date);
  //         const monthName = dateObject.toLocaleDateString(undefined, {
  //           month: 'short',
  //         });
  //         const dayOfMonth = dateObject.toLocaleDateString(undefined, {
  //           day: '2-digit',
  //         });
  //         return { ...entry, dayOfWeek: `${monthName} ${dayOfMonth}` };
  //       });
  //       setSearchData(lastMonth);
  //     }
  //   }
  //   if (imageService) {
  //     if (selectedOption === 'option1') {
  //       const lastSeven = imageService.totalByDay.slice(-7).map((entry) => {
  //         const dateObject = new Date(entry.date);
  //         const dayOfWeek = dateObject.toLocaleDateString(undefined, {
  //           weekday: 'short',
  //         }); // Get the full day name
  //         return { ...entry, dayOfWeek };
  //       });
  //       setImageData(lastSeven);
  //     } else if (selectedOption === 'option2') {
  //       const lastMonth = imageService.totalByDay.slice(-30).map((entry) => {
  //         const dateObject = new Date(entry.date);
  //         const monthName = dateObject.toLocaleDateString(undefined, {
  //           month: 'short',
  //         });
  //         const dayOfMonth = dateObject.toLocaleDateString(undefined, {
  //           day: '2-digit',
  //         });
  //         return { ...entry, dayOfWeek: `${monthName} ${dayOfMonth}` };
  //       });
  //       setImageData(lastMonth);
  //     } else {
  //       const lastMonth = imageService.totalByDay.slice(-100).map((entry) => {
  //         const dateObject = new Date(entry.date);
  //         const monthName = dateObject.toLocaleDateString(undefined, {
  //           month: 'short',
  //         });
  //         const dayOfMonth = dateObject.toLocaleDateString(undefined, {
  //           day: '2-digit',
  //         });
  //         return { ...entry, dayOfWeek: `${monthName} ${dayOfMonth}` };
  //       });
  //       setImageData(lastMonth);
  //     }
  //   }
  //   if (routeService) {
  //     if (selectedOption === 'option1') {
  //       const lastSeven = routeService.totalByDay.slice(-7).map((entry) => {
  //         const dateObject = new Date(entry.date);
  //         const dayOfWeek = dateObject.toLocaleDateString(undefined, {
  //           month: 'short',
  //         }); // Get the full day name
  //         return { ...entry, dayOfWeek };
  //       });
  //       setRouteData(lastSeven);
  //     } else if (selectedOption === 'option2') {
  //       const lastMonth = routeService.totalByDay.slice(-30).map((entry) => {
  //         const dateObject = new Date(entry.date);
  //         const monthName = dateObject.toLocaleDateString(undefined, {
  //           month: 'short',
  //         });
  //         const dayOfMonth = dateObject.toLocaleDateString(undefined, {
  //           day: '2-digit',
  //         });
  //         return { ...entry, dayOfWeek: `${monthName} ${dayOfMonth}` };
  //       });
  //       setRouteData(lastMonth);
  //     } else {
  //       const lastMonth = routeService.totalByDay.slice(-100).map((entry) => {
  //         const dateObject = new Date(entry.date);
  //         const monthName = dateObject.toLocaleDateString(undefined, {
  //           month: 'short',
  //         });
  //         const dayOfMonth = dateObject.toLocaleDateString(undefined, {
  //           day: '2-digit',
  //         });
  //         return { ...entry, dayOfWeek: `${monthName} ${dayOfMonth}` };
  //       });
  //       setRouteData(lastMonth);
  //     }
  //   }
  // }, [totalHits, selectedOption]);

  // useEffect(() => {
  //   setMapData([]);
  //   setSearchData([]);
  //   setImageData([]);
  //   setRouteData([]);
  // }, [selectedOption]);

  // console.log('mapdataaaa', searchData);
  // console.log('new', totalHits);
  const cardStyle = {
    maxWidth: user.user_type === 'organization' ? 600 : 500, // Adjust the width as needed
    height: user.user_type === 'organization' ? 490 : 280, // Adjust the height as needed
  };

  const smallCard = {
    maxWidth: user.payment_type === 'free' ? 310 : 310,
    height: user.payment_type === 'free' ? 170 : 260,
  };

  const showBuy = () => {
    setShow(true);
  };

  const showProfileModal = () => {
    setShowEdit(true);
  };

  // console.log('user', user);

  const showUp = () => {
    setShowUpgrade(true);
  };

  const handleFilterByChange = (value) => {
    // if (value === 'filter') {
    //   handleOption4Click();
    // }
    setActiveFilter(value);
  };
  return (
    <>
      {show ? <Buy toggle={toggle} show={show} /> : null}
      {showEdit ? (
        <EditProfile
          showEdit={showEdit}
          toggleEdit={toggleEdit}
          user={user}
          setUser={setUser}
        />
      ) : null}
      {showUpgrade ? (
        <Upgrade showUpgrade={showUpgrade} toggleUpgrade={toggleUpgrade} />
      ) : null}
      <h2 style={{ marginLeft: '15px' }}>{user.username}</h2>

      <Box style={{ position: 'relative', marginTop: '1rem' }}>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Card style={cardStyle}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>Contact Number:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>
                      {user.contact_number}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>Email:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>{user.email}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>Address:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>
                      {user.billing_address}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>User Type:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>
                      {user.user_type}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>PAN/VAT:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>
                      {user.pan_number}
                    </Typography>
                    {user.user_type === 'organization' ? null : (
                      <Button
                        onClick={showProfileModal}
                        variant='contained'
                        style={{
                          fontSize: '.8rem',
                          minWidth: '100px',
                          margin: '0 0 10px 0',
                          backgroundColor: '#e37547',
                          marginTop: '1rem',
                          marginLeft: '8rem',
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Grid>
                  {user.user_type === 'organization' ? (
                    <>
                      {' '}
                      <Grid item xs={6}>
                        <Typography variant='subtitle1'>
                          Contact Person Name:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle1'>
                          {user.contact_person_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle1'>
                          Contact Person Email:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle1'>
                          {user.contact_person_email}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle1'>
                          Contact Person Mobile:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle1'>
                          {user.contact_person_mobile_number}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle1'>
                          Contact Person Designation:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle1'>
                          {user.contact_person_designation}
                        </Typography>
                        <Button
                          onClick={showProfileModal}
                          variant='contained'
                          style={{
                            fontSize: '.8rem',
                            minWidth: '100px',
                            margin: '0 0 10px 0',
                            backgroundColor: '#e37547',
                            marginTop: '1rem',
                            marginLeft: '8rem',
                          }}
                        >
                          Edit
                        </Button>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                {/* Rest of your content */}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card style={smallCard}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>Free points:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>
                      {Math.round(user.free_credit_points)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>Credit points:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>
                      {Math.round(user.paid_credit_points)}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Typography variant='subtitle1'>Balance Due:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>
                      {user.balance_due}
                    </Typography>
                  </Grid> */}
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>Plan:</Typography>
                  </Grid>
                  <Grid item xs={6} container alignItems='center'>
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>
                        {user.payment_type
                          ? user.payment_type.charAt(0).toUpperCase() +
                            user.payment_type.slice(1)
                          : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {user.payment_type === 'free' ? (
                        <Button
                          onClick={showUp}
                          variant='contained'
                          style={{
                            fontSize: '.65rem',
                            minWidth: '50px',
                            margin: '0 0 5px 0',
                            backgroundColor: '#e37547',
                          }}
                        >
                          Upgrade
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
                {user.payment_type === 'pre-paid' ? (
                  <Button
                    onClick={showBuy}
                    variant='contained'
                    style={{
                      fontSize: '1rem',
                      minWidth: '100px',
                      margin: '0 0 10px 0',
                      backgroundColor: '#e37547',
                      marginTop: '1rem',
                    }}
                  >
                    Add Credit Points
                  </Button>
                ) : null}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box
          border={0.5}
          borderColor='#F1B4B4'
          padding={2}
          style={{ marginTop: '2rem' }}
        >
          <FormControl>
            <RadioGroup
              // style={{ marginTop: '.9rem' }}
              row
              name='filter by'
              value={activeFilter}
              onChange={(e) => handleFilterByChange(e.target.value)}
            >
              <FormControlLabel
                value='filter'
                control={<Radio size='small' />}
                label='Filter by date'
              />
              <FormControlLabel
                value='button'
                control={<Radio size='small' />}
                label='Filter by button'
              />
            </RadioGroup>
          </FormControl>
          <Box style={containerStyle}>
            {activeFilter === 'filter' && (
              <>
                {' '}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    // marginBottom: '1rem',
                  }}
                >
                  <Typography style={{ fontSize: 22, marginRight: '1rem' }}>
                    From:
                  </Typography>
                  {/* <NepaliDatePicker
                    value={date}
                    format='YYYY-MM-DD'
                    onChange={handleDate}
                  /> */}
                  <NepaliDatePicker
                    inputClassName='form-control'
                    className=''
                    onChange={handleFromDateChange}
                    options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    // marginBottom: '1rem',
                  }}
                >
                  <Typography style={{ fontSize: 22, marginRight: '1rem' }}>
                    To:
                  </Typography>
                  <NepaliDatePicker
                    inputClassName='form-control'
                    className=''
                    onChange={handleToDateChange}
                    options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                  />
                </div>
                <Button
                  onClick={handleOption4Click}
                  variant='contained'
                  style={{
                    fontSize: '1rem',
                    minWidth: '100px',
                    margin: '0 0 10px 0',
                    backgroundColor: '#e37547',
                    marginTop: 10,
                  }}
                >
                  Filter
                </Button>
              </>
            )}
            {activeFilter === 'button' && (
              <>
                {' '}
                <Button
                  onClick={() => setSelectedOption('option1')}
                  variant='contained'
                  style={{
                    fontSize: '1rem',
                    minWidth: '100px',
                    margin: '0 0 10px 0',
                    backgroundColor: '#e37547',
                    marginTop: 10,
                  }}
                >
                  This Week
                </Button>
                <Button
                  onClick={() => setSelectedOption('option2')}
                  variant='contained'
                  style={{
                    fontSize: '1rem',
                    minWidth: '100px',
                    margin: '0 0 10px 0',
                    backgroundColor: '#e37547',
                    marginTop: 10,
                  }}
                >
                  This Month
                </Button>
                <Button
                  onClick={() => setSelectedOption('option3')}
                  variant='contained'
                  style={{
                    fontSize: '1rem',
                    minWidth: '100px',
                    margin: '0 0 10px 0',
                    backgroundColor: '#e37547',
                    marginTop: 10,
                  }}
                >
                  Last 90 Days
                </Button>
              </>
            )}
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid style={{ marginTop: '2rem' }}>
                <p style={{ fontSize: 20 }}>Total successful hit</p>
                <BarChart
                  width={500}
                  height={300}
                  data={totalHits}
                  style={{ marginTop: 5 }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='service' />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey='successfulServiceHits'
                    fill='#812c19'
                    name='Hit'
                  />
                </BarChart>
              </Grid>
              <p style={{ fontSize: 20, marginTop: '1rem' }}>
                Total credit points used on total request
              </p>

              <TableContainer component={Paper} style={tableContainerStyle}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.N</TableCell>
                      <TableCell>Service</TableCell>
                      {/* <TableCell>Category</TableCell> */}
                      <TableCell>Total hits</TableCell>
                      <TableCell>Credit point</TableCell>
                      {selectedOption === 'option1' ||
                      selectedOption === 'option2' ||
                      selectedOption === 'option3' ||
                      newOption === 'option4' ? (
                        <TableCell>Cost before discount</TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {totalHits.map((row, index) => (
                      // console.log("totalHits",totalHits)

                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.service}</TableCell>
                        {/* <TableCell>{row.category}</TableCell> */}
                        <TableCell>{row.successfulServiceHits}</TableCell>
                        <TableCell>
                          {row.totalCreditPoints.toFixed(2)}
                        </TableCell>
                        {selectedOption === 'option1' ||
                        selectedOption === 'option2' ||
                        selectedOption === 'option3' ||
                        newOption === 'option4' ? (
                          <TableCell>{row.total.toFixed(2)}</TableCell>
                        ) : null}
                      </TableRow>
                    ))}
                    {selectedOption === 'option1' ||
                    selectedOption === 'option2' ||
                    selectedOption === 'option3' ||
                    newOption === 'option4' ? (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        {/* <TableCell></TableCell> */}
                        <TableCell></TableCell>
                        <TableCell>Total:</TableCell>
                        <TableCell>
                          {typeof totals === 'number'
                            ? totals.toFixed(2)
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={6}>
              <Grid style={{ marginTop: '2rem' }}>
                <p style={{ fontSize: 20 }}> Total hit per service</p>
                <BarChart
                  width={500}
                  height={300}
                  data={totalHits}
                  style={{ marginTop: 5 }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='service' />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey='totalServiceHits' fill='#812c19' name='Hit' />
                </BarChart>
              </Grid>
              <p style={{ fontSize: 20, marginTop: '1rem' }}>
                Credit points per services request
              </p>
              <TableContainer component={Paper} style={tableContainerStyle}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.N</TableCell>
                      <TableCell>Name</TableCell>
                      {/* <TableCell>Description</TableCell> */}
                      {/* <TableCell>Category</TableCell> */}
                      <TableCell>Credit point per 1000 request</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {services.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {row.name.charAt(0).toUpperCase() + row.name.slice(1)}
                        </TableCell>
                        {/* <TableCell>{row.description}</TableCell> */}
                        {/* <TableCell>
                          {row.category && row.category.name
                            ? row.category.name
                            : 'N/A'}
                        </TableCell> */}
                        <TableCell style={{ verticalAlign: 'middle' }}>
                          {row.credit_points * 1000}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <p style={{ fontSize: 20, marginTop: '1rem' }}>Rate</p>
              <TableContainer component={Paper} style={tableContainerStyle}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Cp Minimum</TableCell>
                      <TableCell>Cp maximum</TableCell>
                      <TableCell>Rate per cp</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {newDetail &&
                      newDetail.map((item, index) => (
                        <>
                          <TableRow key={index}>
                            <TableCell>{item[index].minimum_cp}</TableCell>
                            <TableCell>{item[index].maximum_cp}</TableCell>
                            <TableCell>{item[index].rate_per_cp}</TableCell>
                            <TableCell>{item[index].amount}</TableCell>
                          </TableRow>
                        </>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer> */}
            </Grid>
          </Grid>
        </Box>
        <Box
          border={0.5}
          borderColor='#F1B4B4'
          padding={2}
          style={{ marginTop: '2rem' }}
        >
          <p style={{ fontSize: 25 }}>Services</p>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <p style={{ fontSize: 15 }}>Select services</p>
            </Grid>
            <Grid item>
              <FormControl>
                <Select
                  labelId='service-label'
                  id='service-select'
                  value={selectService}
                  onChange={handleServiceChange}
                >
                  {services.map((row, index) => (
                    <MenuItem
                      value={
                        row.name.charAt(0).toUpperCase() + row.name.slice(1)
                      }
                      key={index}
                    >
                      {row.name.charAt(0).toUpperCase() + row.name.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={9}>
              <Grid style={{ marginTop: '2rem' }}>
                <p style={{ fontSize: 20 }}>{selectService}</p>
                <BarChart
                  width={900}
                  height={400}
                  data={newNepalidate}
                  style={{ marginTop: 5 }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='nepaliDate' />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey='successfulHits' fill='#812c19' name='Hits' />
                </BarChart>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default CDashboard;
