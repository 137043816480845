import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import axiosInstance from '../../../utils/axios';
import { toast } from 'react-toastify';

const UpdateEvent = ({
  events,
  setEvents,
  showUpdateEvent,
  toggleUpdateEvent,
  update_id,
}) => {
  const [name, setName] = useState(update_id.name);
  const [isActive, setIsactive] = useState(update_id.isActive);
  const [isSpecial, setIsSpecial] = useState(update_id.isSpecial);
  const [points, setPoints] = useState(update_id.points);
  const [line, setLine] = useState(update_id.line);
  const [type, setType] = useState(update_id.type);
  const [startDate, setStartDate] = useState(update_id.startDate);
  const [endDate, setEndDate] = useState(update_id.endDate);
  const [location, setLocation] = useState(update_id.location);
  const [price, setPrice] = useState(update_id.price);
  const [clickLink, setClickLink] = useState(update_id.clickLink);
  const [publishDate, setPublishDate] = useState(update_id.publishDate);
  const [callToAction, setCallToAction] = useState(update_id.callToAction);
  const [imageFile, setImageFile] = useState(update_id.imageFile);
  const [errors, setErrors] = useState([]);
  // console.log('imageFIle0', update_id.image);
  const baseUrl = `${process.env.REACT_APP_PUBLIC_URL}`;
  // console.log();

  const handdleSubmit = () => {
    axiosInstance
      .patch(`/v1/events/${update_id._id}/`, {
        name,
        isActive,
        isSpecial,
        // imageFile,
        points,
        line,
        type,
        startDate,
        endDate,
        location,
        price,
        clickLink,
        publishDate,
        callToAction,
      })
      .then((resp) => {
        if (resp.status === 200) {
          const updatedEvents = events.map((event) =>
            event._id === update_id._id
              ? {
                  ...event,
                  name,
                  isActive,
                  isSpecial,
                  // imageFile,
                  points,
                  line,
                  type,
                  startDate,
                  endDate,
                  location,
                  price,
                  clickLink,
                  publishDate,
                  callToAction,
                }
              : event,
          );
          setEvents(updatedEvents);
          toggleUpdateEvent();
          toast.success('Updated Successfully');
        }
      });
  };
  return (
    <div>
      {' '}
      <Dialog
        open={showUpdateEvent}
        keepMounted
        onClose={toggleUpdateEvent}
        maxWidth='md'
        fullWidth
        style={{ minHeight: '600px' }}
      >
        <DialogTitle>{'Edit an Event'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* First Column */}
            <Grid item xs={4}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl>
                    <br />
                    <TextField
                      label='Name'
                      fullWidth
                      placeholder='Enter name'
                      id='demo-helper-text-aligned'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <br />
                    <TextField
                      id='datetime-local'
                      label='Start date'
                      type='datetime-local'
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      error={errors.some(
                        (error) => error.path[0] === 'startDate',
                      )}
                      helperText={
                        errors
                          .find((error) => error.path[0] === 'startDate')
                          ?.message.replace(/"/g, '') || ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <br />
                    <TextField
                      id='datetime-local'
                      label='End date'
                      type='datetime-local'
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      error={errors.some(
                        (error) => error.path[0] === 'endDate',
                      )}
                      helperText={
                        errors
                          .find((error) => error.path[0] === 'endDate')
                          ?.message.replace(/"/g, '') || ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <br />

                    <TextField
                      id='datetime-local'
                      label='Publish date'
                      type='datetime-local'
                      value={publishDate}
                      onChange={(e) => setPublishDate(e.target.value)}
                      error={errors.some(
                        (error) => error.path[0] === 'publishDate',
                      )}
                      helperText={
                        errors
                          .find((error) => error.path[0] === 'publishDate')
                          ?.message.replace(/"/g, '') || ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <br />
                    <TextField
                      label='Location'
                      fullWidth
                      placeholder='Enter Location'
                      id='demo-helper-text-aligned'
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      error={errors.some(
                        (error) => error.path[0] === 'location',
                      )}
                      helperText={
                        errors
                          .find((error) => error.path[0] === 'location')
                          ?.message.replace(/"/g, '') || ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <br />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <br />
                <TextField
                  label='Call to Action'
                  fullWidth
                  placeholder='Enter call to action'
                  id='demo-helper-text-aligned'
                  value={callToAction}
                  onChange={(e) => setCallToAction(e.target.value)}
                  error={errors.some(
                    (error) => error.path[0] === 'callToAction',
                  )}
                  helperText={
                    errors
                      .find((error) => error.path[0] === 'callToAction')
                      ?.message.replace(/"/g, '') || ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <br />
                <TextField
                  label='Price'
                  fullWidth
                  placeholder='Enter Price'
                  id='demo-helper-text-aligned'
                  value={price}
                  error={errors.some((error) => error.path[0] === 'price')}
                  helperText={
                    errors
                      .find((error) => error.path[0] === 'price')
                      ?.message.replace(/"/g, '') || ''
                  }
                  onChange={(e) => setPrice(e.target.value)}
                />
                <br />
                <TextField
                  label='Point'
                  fullWidth
                  placeholder='Enter Point'
                  id='demo-helper-text-aligned'
                  value={points}
                  error={errors.some((error) => error.path[0] === 'points')}
                  helperText={
                    errors
                      .find((error) => error.path[0] === 'points')
                      ?.message.replace(/"/g, '') || ''
                  }
                  onChange={(e) => setPoints(e.target.value)}
                />
                <br />
                <TextField
                  label='Line'
                  fullWidth
                  placeholder='Enter line'
                  id='demo-helper-text-aligned'
                  value={line}
                  error={errors.some((error) => error.path[0] === 'line')}
                  helperText={
                    errors
                      .find((error) => error.path[0] === 'line')
                      ?.message.replace(/"/g, '') || ''
                  }
                  onChange={(e) => setLine(e.target.value)}
                />
                <br />

                <TextField
                  label='Type'
                  fullWidth
                  placeholder='Enter type'
                  id='demo-helper-text-aligned'
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  error={errors.some((error) => error.path[0] === 'type')}
                  helperText={
                    errors
                      .find((error) => error.path[0] === 'type')
                      ?.message.replace(/"/g, '') || ''
                  }
                />
                <br />
                <TextField
                  label='Click link'
                  fullWidth
                  placeholder='Enter Click Link'
                  id='demo-helper-text-aligned'
                  value={clickLink}
                  error={errors.some((error) => error.path[0] === 'clickLink')}
                  helperText={
                    errors
                      .find((error) => error.path[0] === 'clickLink')
                      ?.message.replace(/"/g, '') || ''
                  }
                  onChange={(e) => setClickLink(e.target.value)}
                />
                <br />
              </FormControl>
            </Grid>
            {/* <Grid item xs={6}></Grid> */}
            {/* Second Column */}
            <Grid item xs={3}>
              <FormControl>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '10px', marginBottom: '0' }}>
                    Is Active
                  </label>
                  <Checkbox
                    onChange={() => setIsactive(!isActive)}
                    checked={isActive}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '10px', marginBottom: '0' }}>
                    Is Special
                  </label>
                  <Checkbox
                    onChange={() => setIsSpecial(!isSpecial)}
                    checked={isSpecial}
                  />
                </div>
                {/* <label>Select image file</label> */}
                {/* <br /> */}
                {/* <p>{update_id.imageFile}</p> */}
                {/* <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => setImageFile(e.target.files[0])}
                /> */}
                {/* <br /> */}
                {/* <p style={{ color: 'red' }}>
                  {errors
                    .find((error) => error.path[0] === 'image')
                    ?.message.replace(/`/g, '') || ''}
                </p> */}
                {/* {imageFile ? (
                  <img
                    src={URL.createObjectURL(imageFile)}
                    alt='Selected Image'
                    style={{ maxWidth: '100%' }}
                  />
                ) : (
                  <img
                    src={
                      baseUrl +
                      'static/' +
                      update_id.image.replace('public/', '')
                    }
                    alt='Selected'
                  />
                )} */}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handdleSubmit}>Update</Button>
          <Button onClick={toggleUpdateEvent}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpdateEvent;
