import { useState, useEffect } from 'react';

export const useUserSearch = (data, searchTerm, itemsPerPage) => {
  const [searchResult, setSearchResult] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const result = data.filter((val) => {
      if (searchTerm === '') {
        return val;
      } else if (
        val.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.username.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return val;
      }
      return null;
    });
    setSearchResult(result);
    setCurrentPage(1); // Reset current page when search term changes
  }, [searchTerm, data]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedSearchResult = searchResult.slice(startIndex, endIndex);

  const totalPages = Math.ceil(searchResult.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return {
    totalPages,
    currentPage,
    searchResult: paginatedSearchResult,
    handlePageChange,
  };
};
