import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import employeeBase from '../../../../utils/employee';

const VerifyPlace = ({
  showVerfyPlace,
  toggleVerifyPlace,
  detailData,
  requests,
  setRequests,
  setRejected,
  rejected,
  verified,
  setVerified,
  totalVerifiedResults,
  setTotalVerifiedResults,
  totalRejectedResults,
  setTotalRejectedResults,
  totalResults,
  setTotalResults,
  inspect,
  setInspect,
  setTotalInspectResults,
  totalInspectResults,
}) => {
  // console.log('totalInspectResults', totalInspectResults);
  const token = `${process.env.REACT_APP_ACCESS_TOKEN}`;
  console.log('detailData', detailData);
  const confirm = () => {
    employeeBase
      .post(`place/activatePlace/${detailData._id}?accessToken=${token}`)
      .then((resp) => {
        console.log(resp);
        if (resp.status === 200) {
          // window.location.reload();
          if (detailData.fromrejected === true) {
            const filtered = rejected.filter((i) => i._id !== detailData._id);
            setRejected(filtered);
            const addverify = [...verified, detailData];
            setVerified(addverify);
            setTotalRejectedResults(totalRejectedResults - 1);
            setTotalVerifiedResults(totalVerifiedResults + 1);
          } else if (detailData.fromrequest === true) {
            const filtered = requests.filter((i) => i._id !== detailData._id);
            setRequests(filtered);
            const addverify = [...verified, detailData];
            setVerified(addverify);
            setTotalResults(totalResults - 1);
            setTotalVerifiedResults(totalVerifiedResults + 1);
          } else if (detailData.frominspect === true) {
            const filtered = inspect.filter((i) => i._id !== detailData._id);
            setInspect(filtered);
            const addverify = [...verified, detailData];
            setVerified(addverify);
            setTotalInspectResults(totalInspectResults - 1);
            setTotalVerifiedResults(totalVerifiedResults + 1);
          }

          toggleVerifyPlace();
        }
      });
  };
  return (
    <div>
      {' '}
      <Dialog
        open={showVerfyPlace}
        onClose={toggleVerifyPlace}
        // maxWidth='md'
        fullWidth
      >
        <DialogTitle>Verify the place</DialogTitle>
        <DialogContent>
          Are you sure want to verify the place for the addition of places in
          the map?
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleVerifyPlace}>Cancel</Button>
          <Button onClick={confirm} color='primary'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VerifyPlace;
