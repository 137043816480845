import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import employeeBase from '../../../../utils/employee';
const RejectPlace = ({
  showRejectPlace,
  toggleReject,
  detailData,
  rejected,
  setRejected,
  requests,
  setRequests,
  verified,
  setVerified,
  setTotalVerifiedResults,
  totalVerifiedResults,
  setTotalRejectedResults,
  totalRejectedResults,
  totalResults,
  setTotalResults,
  inspect,
  setInspect,
  setTotalInspectResults,
  totalInspectResults,
}) => {
  const token = `${process.env.REACT_APP_ACCESS_TOKEN}`;
  const confirm = () => {
    employeeBase
      .post(`place/removePlace/${detailData._id}?accessToken=${token}`)
      .then((resp) => {
        if (resp.status === 200) {
          if (detailData.fromrequest === true) {
            const filtered = requests.filter((i) => i._id !== detailData._id);
            setRequests(filtered);
            const addedRejected = [...rejected, detailData];
            setRejected(addedRejected);
            setTotalResults(totalResults - 1);
            setTotalRejectedResults(totalRejectedResults + 1);
          } else if (detailData.fromverify === true) {
            const filtered = verified.filter((i) => i._id !== detailData._id);
            setVerified(filtered);
            const addedRejected = [...rejected, detailData];
            setRejected(addedRejected);
            setTotalVerifiedResults(totalVerifiedResults - 1);
            setTotalRejectedResults(totalRejectedResults + 1);
          } else if (detailData.frominspect === true) {
            const filtered = inspect.filter((i) => i._id !== detailData._id);
            setInspect(filtered);
            const addedRejected = [...rejected, detailData];
            setRejected(addedRejected);
            setTotalInspectResults(totalInspectResults - 1);
            setTotalRejectedResults(totalRejectedResults + 1);
          }
          toggleReject();
        }
      });
  };
  return (
    <div>
      {' '}
      <Dialog
        open={showRejectPlace}
        onClose={toggleReject}
        // maxWidth='md'
        fullWidth
      >
        <DialogTitle>Reject the place</DialogTitle>
        <DialogContent>
          Are you sure want to reject the place for the addition of places in
          the map?
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleReject}>Cancel</Button>
          <Button onClick={confirm} color='primary'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RejectPlace;
