import { useState, useEffect } from 'react';
import employeeBase from '../utils/employee';

export const useEmployeeFetch = (data, setData, url, limit) => {
  const [isLoading, setIsLoading] = useState(false);
  const token = window.localStorage.getItem('galli_token');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState();
  const handlePageChange = (event, value) => {
    // console.log('value', value);
    setCurrentPage(value);
    // alert('hi', event.value);
  };
  useEffect(() => {
    setIsLoading(true);
    const getAllData = async () => {
      try {
        const response = await employeeBase(
          url + `&limit=${limit}&page=${currentPage}`,
        );
        if (response.status === 200) {
          // console.log('dataresults', response);
          setData(response.data.results);
          setTotalPages(response.data.totalPages);
          setTotalResults(response.data.totalResults);
          setIsLoading(false);
        }
      } catch (error) {
        // console.error('An error occurred:', error);
        setIsLoading(false);
      }
    };
    if (token) {
      getAllData();
    }
  }, [setData, url, currentPage, limit, token]);

  return {
    data,
    handlePageChange,
    totalPages,
    currentPage,
    limit,
    isLoading,
    totalResults,
    setTotalResults,
  };
};
