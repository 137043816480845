import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useFetch } from '../../../hooks/useFetch';
import Autocomplete from '@mui/material/Autocomplete';
import axiosInstance from '../../../utils/axios';
import { toast } from 'react-toastify';

const AddPackage = ({ toggleAddPackage, showAddPackage, packages }) => {
  const [email, setEmail] = useState('');
  const [selectedPackage, setSelectedPackage] = useState([0]);
  const [users, setUsers] = useState([]);
  const [prePaidUser, setPrePaidUser] = useState([]);
  const { data, isLoading } = useFetch(users, setUsers, '/v1/users/');
  useEffect(() => {
    if (users.length > 0) {
      const newlist = users.filter((user) => user.payment_type === 'pre-paid');
      if (newlist !== null) {
        const list = newlist.filter((i) =>
          i.email.toLowerCase().includes(email.toLowerCase()),
        );
        setPrePaidUser(list);
      }
    }
  }, [email]);
  // console.log('prePaidUser', prePaidUser);
  const handlePackageChange = (event) => {
    setSelectedPackage(event.target.value);
  };
  console.log('selectedPackage', selectedPackage);
  const handleSelectChange = (event) => {
    setEmail(event.target.value);
  };
  const addPackage = () => {
    axiosInstance
      .post('v1/payments/addPackage', {
        email,
        amount: selectedPackage.price,
        credit_points: selectedPackage.credit_points,
      })
      .then((resp) => {
        if (resp.status === 200) {
          toast.success('Credit added to the user');
          toggleAddPackage();
        }
      })
      .catch((error) => {
        if (error) {
          toast.error('Please complete field before submitting');
        }
      });
  };
  return (
    <div>
      <Dialog
        open={showAddPackage}
        onClose={toggleAddPackage}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          style: {
            minWidth: '50%', // Adjust as needed for desired width
            maxWidth: '90%', // Adjust as needed for desired width
            maxHeight: '95vh', // Adjust as needed for desired height
            overflowY: 'auto', // Enable vertical scrolling if needed
          },
        }}
      >
        <DialogTitle id='alert-dialog-title'>
          {'Add package to user'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'></DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '.5rem',
          }}
        >
          <div style={{ display: 'flex', gap: '2rem' }}>
            <FormControl>
              <Autocomplete
                options={prePaidUser}
                getOptionLabel={(user) => user.email}
                onChange={(event, newValue) => {
                  // Handle the selected user
                  console.log(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setEmail(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Search or Select User by Email'
                    variant='outlined'
                    style={{ width: '300px' }}
                  />
                )}
              />
            </FormControl>
            {/* <FormControl>
              <TextField
                label='Search pre-paid user by email'
                placeholder='Search pre-paid user by email'
                id='demo-helper-text-aligned'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Select
                label='Select Option'
                value={email}
                onChange={handleSelectChange}
              >
                {prePaidUser.map((user) => (
                  <MenuItem value={user.email}>{user.email}</MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <FormControl>
              <Select
                labelId='Select package'
                id='package-select'
                placeholder='Select the package'
                value={selectedPackage ? selectedPackage : ''}
                onChange={handlePackageChange}
                displayEmpty // This allows you to display the placeholder as the initially selected value
              >
                <MenuItem value='' disabled>
                  <em>Select the package</em> {/* Placeholder text */}
                </MenuItem>
                {packages.map((row, index) => (
                  <MenuItem value={row} key={index}>
                    {row.name.charAt(0).toUpperCase() + row.name.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant='contained'
              style={{
                fontSize: '12px',
                padding: '13px 20px',
                height: '3rem',
              }}
              onClick={addPackage}
            >
              Add package
            </Button>
            <Button
              onClick={toggleAddPackage}
              variant='contained'
              style={{
                fontSize: '12px',
                padding: '13px 20px',
                height: '3rem',
              }}
            >
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddPackage;
