import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { FormControl } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

const AddLimit = ({showAddLimit,setShowAddLimit}) => {
    const [errors, setErrors] = useState([]);
    const [isLoading,setIsLoading]=useState(false);

    const handleSubmit =()=>{
     

    }
  return (
    <div> <Dialog
    open={showAddLimit}
    keepMounted
    onClose={setShowAddLimit}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle>{}</DialogTitle>
    {isLoading?<Box style={{position: 'relative'}}>
       <CircularProgress
       size={40}
       left={-20}
       top={10}
       status={'loading'}
       style={{marginLeft: '50%'}}
       />
     </Box>:<>
      <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
      </DialogContentText>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > :not(style)': { m: 2 },
          }}
        >
          <FormControl>
            {errors.map(error => {
              return <Alert severity="error">{error.message}</Alert>

            })}

            {/* <TextField
               placeholder='Enter amount'
               type="number"
               label="Amount"
              />                                      */}
          
           
          </FormControl>


        </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleSubmit}>Add Limit</Button>
      <Button onClick={setShowAddLimit}>Cancel</Button>
    </DialogActions>
    </>
    }
   
  </Dialog></div>
  )
}

export default AddLimit