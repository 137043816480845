import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axiosInstance from '../../../utils/axios';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core';


const SubServices = ({show,toggle,subService}) => {
  return (
    <div><Dialog
    open={show}
    onClose={toggle}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
  
    <DialogTitle id="alert-dialog-title">
      {"Sub services"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
      <TableContainer >
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Creditpoints</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {subService.map((service) => (
        <TableRow key={service.name}>
          <TableCell>{service.name}</TableCell>
          <TableCell>{service.credit_points}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={toggle} autoFocus>
        Close
      </Button>
    </DialogActions>
  </Dialog></div>
  )
}

export default SubServices