import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tabs,
} from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { useFetch } from '../../../hooks/useFetch';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'step 1';
    case 1:
      return 'Step 2';
    case 2:
      return 'Step 3';
    default:
      return 'Unknown step';
  }
}

const UpdateModal = ({
  keys,
  setKeys,
  showUpdateModal,
  toggleShowModal,
  update_id,
}) => {
  const [alias, setAlias] = useState(update_id.alias);
  const [domain_name, setDomainName] = useState();
  const [max_hit, setMaxHit] = useState(20000);
  const [errors, setErrors] = useState([]);
  const [checked, setChecked] = useState(false);
  const [expiration_date, setExpirationDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState([]);
  const { data } = useFetch(services, setServices, '/v1/services');
  const [selectedItems, setSelectedItems] = useState(update_id.services || []);
  const [totalSum, setTotalSum] = useState(0);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = ['Update an api key', 'Select the services', 'Complete'];
  const [users, setUser] = useState([]);
  const [value, setValue] = useState('');
  const [tabId, setTabId] = useState('1');
  const [access_key, setAccessKey] = useState();

  // useEffect(()=>{
  //   setSelectedItems(update_id.services);
  // },[1])

  const handleChanges = (event: React.SyntheticEvent, newValue: string) => {
    setTabId(newValue);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  console.log('update_id', update_id);

  const handleSubmit = () => {
    axiosInstance
      .put(`/v1/api-keys/${update_id._id}`, {
        alias,
        domain_name,
        max_hit,
        expiration_date,
        user: update_id.user._id,
        services: services,
      })
      .then((resp) => {
        setIsLoading(true);
        if (resp.status === 200) {
          setIsLoading(false);
          const updatedKey = [...keys];
          const index = updatedKey.findIndex(
            (i) => i._id === resp.data.data._id,
          );

          let {
            alias,
            domain_name,
            max_hit,
            counter,
            expire,
            _id,
            createdAt,
            updatedAt,
            access_key,
            expiration_date,
            services,
          } = resp.data.data;
          const newKey = {
            _id: _id,
            alias: alias,
            domain_name: domain_name,
            max_hit: max_hit,
            counter: counter,
            expire: expire,
            createdAt: createdAt,
            updatedAt: updatedAt,
            services: services,
            expiration_date: expiration_date,
            access_key: access_key,
          };
          updatedKey.splice(index, 1, newKey);

          setKeys(updatedKey);
          // window.location.reload();

          toggleShowModal();
          toast.success('Api Keys updated successfully');
        }
      })
      .catch((errors) => {
        // console.log(errors.response.data.message);
        if (errors) {
          setIsLoading(false);

          setErrors(errors.response.data.message);
        }
      });
  };
  const date = new Date(update_id.expiration_date);
  const formattedDateString = date.toISOString().substring(0, 16);
  console.log('helloo', formattedDateString);

  useEffect(() => {
    setExpirationDate(formattedDateString);
  }, []);
  console.log('updateID', update_id);
  const handleItemClick = (datas) => {
    console.log('datani', datas);
    const index = selectedItems.findIndex((item) => item._id === datas._id);
    if (index > -1) {
      // Item already selected, remove it from the array
      setSelectedItems(selectedItems.filter((i) => i._id !== datas._id));
      setTotalSum(totalSum - datas.rate);
    } else {
      // Item not selected, add it to the array
      setSelectedItems([...selectedItems, datas]);
      setTotalSum(totalSum + datas.rate);
    }
  };

  console.log('test', selectedItems);

  return (
    <div>
      <Dialog
        open={showUpdateModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={toggleShowModal}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>{'Update Api Key'}</DialogTitle>
        {isLoading ? (
          <Box style={{ position: 'relative' }}>
            <CircularProgress
              size={40}
              left={-20}
              top={10}
              status={'loading'}
              style={{ marginLeft: '50%' }}
            />
          </Box>
        ) : (
          <>
            <DialogContent>
              <div className={classes.root}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    // if (isStepOptional(index)) {
                    //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
                    // }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& > :not(style)': { m: 2 },
                  }}
                >
                  <FormControl>
                    {errors.map((error) => {
                      return <Alert severity='error'>{error.message}</Alert>;
                    })}

                    {activeStep === 0 && (
                      <>
                        <Grid container spacing={4}>
                          <Grid item xs={6} md={6}>
                            <TextField
                              placeholder='Enter  Alias'
                              id='demo-helper-text-aligned'
                              label='Alias'
                              value={alias}
                              onChange={(e) => setAlias(e.target.value)}
                            />
                            <br />
                            <br />

                            <TextField
                              placeholder='Enter Domain Name '
                              id='demo-helper-text-aligned-no-helper'
                              label='Domain Name'
                              value={
                                domain_name
                                  ? domain_name
                                  : update_id.domain_name
                              }
                              onChange={(e) => setDomainName(e.target.value)}
                            />
                            <br />
                            <br />
                            {/* <TextField
                        placeholder="Access key "
                        id="demo-helper-text-aligned-no-helper"
                        label="Access key"
                        value={access_key?access_key:update_id.access_key}
                        onChange={(e) => setAccessKey(e.target.value)}
                        /> */}
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id='demo-helper-text-aligned-no-helper'
                              label='Maximum hit'
                              value={max_hit ? max_hit : max_hit}
                              onChange={(e) => setMaxHit(e.target.value)}
                            />
                            <br />
                            <br />

                            <TextField
                              id='datetime-local'
                              label='Expiry date'
                              type='datetime-local'
                              // defaultValue={expiration_date?expiration_date:update_id.expiration_date}
                              value={expiration_date}
                              onChange={(e) =>
                                setExpirationDate(e.target.value)
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>

                        {/* <FormControlLabel 
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label="Select date"
                    /> */}
                        <br />

                        {/* {checked ? <>
                  <TextField
                    helperText="Please enter Maximum Hit "
                    id="demo-helper-text-aligned-no-helper"
                    label="Maximum hit"
                    value={max_hit}
                    onChange={(e) => setMaxHit(e.target.value)}

                  />
                  <TextField
                    id="datetime-local"
                    helperText="Add expiry date "
                    type="datetime-local"
                    defaultValue="2017-05-24T10:30"
                    value={expiration_date}
                    onChange={(e) => setExpirationDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                </> : null
                } */}
                      </>
                    )}

                    {activeStep === 1 && (
                      <>
                        {/* <label>Select the services</label> */}
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                          <TabContext value={tabId}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >
                              <TabList
                                onChange={handleChanges}
                                aria-label='lab API tabs example'
                              >
                                <Tab label='Select services' value='1' />
                                {/* <Tab label="Select the plans" value="2" /> */}
                                {/* <Tab label="Item Three" value="3" /> */}
                              </TabList>
                            </Box>
                            <TabPanel value='1'>
                              {data.map((datas) => {
                                const isServiceSelected = selectedItems.some(
                                  (selectedItem) =>
                                    selectedItem._id === datas._id,
                                );

                                return (
                                  <>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked
                                          onChange={() =>
                                            handleItemClick(datas)
                                          }
                                          inputProps={{
                                            'aria-label': 'primary checkbox',
                                          }}
                                        />
                                        //   <Checkbox
                                        //   checked
                                        //   inputProps={{ "aria-label": "primary checkbox" }}
                                        // />
                                      }
                                      label={datas.name}
                                      key={datas._id}
                                    />
                                    <br />
                                  </>
                                );
                              })}
                              <br />
                              {/* <Grid container  alignItems="center">
                  Total :
                  <Grid item>
                  <label>
                     <h1>{totalSum}</h1>
                    </label>
                  </Grid>
                 </Grid> */}
                            </TabPanel>
                            {/* <TabPanel value="2">Item Two</TabPanel>
                  <TabPanel value="3">Item Three</TabPanel> */}
                          </TabContext>
                        </Box>
                      </>
                    )}
                  </FormControl>
                </Box>

                <div>
                  {activeStep === steps.length ? (
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                      <Button onClick={handleSubmit}>Update an Api key</Button>
                    </div>
                  ) : (
                    <div>
                      <Typography className={classes.instructions}>
                        {getStepContent(activeStep)}
                      </Typography>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={handleNext}
                          className={classes.button}
                        >
                          {activeStep === steps.length - 1
                            ? 'Complete'
                            : 'Next'}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleShowModal}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default UpdateModal;
