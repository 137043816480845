import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Box, Grid } from '@mui/material';
import { FormControl } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField } from '@material-ui/core';
const EditProfile = ({ showEdit, toggleEdit, user, setUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [pan_number, setPanNumber] = useState(user.pan_number);
  const [billing_address, setBillingAddress] = useState(user.billing_address);
  const [contact_number, setContactNumber] = useState(user.contact_number);
  const [errorMessage, setErrorMessage] = useState([]);
  const [contact_person_email, setContactPersonEmail] = useState(
    user.contact_person_email,
  );
  const [contact_person_mobile_number, setContactPersonMobileNumber] = useState(
    user.contact_person_mobile_number,
  );
  const [contact_person_designation, setContactPersonDegination] = useState(
    user.contact_person_designation,
  );
  const [contact_person_name, setContactPersonName] = useState(
    user.contact_person_name,
  );

  // console.log('users', user);

  const handleSubmit = (e) => {
    axiosInstance
      .put(`v1/users/${user._id}`, {
        pan_number,
        billing_address,
        contact_number,
        contact_person_email,
        contact_person_mobile_number,
        contact_person_designation,
        contact_person_name,
      })
      .then((resp) => {
        console.log(resp);
        if (resp.status === 200) {
          setUser({
            ...user,
            pan_number,
            billing_address,
            contact_number,
            contact_person_email,
            contact_person_mobile_number,
            contact_person_designation,
            contact_person_name,
          });
          toggleEdit();
          toast.success('User updated updated');

          // window.location.reload();
        }
      })
      .catch((errors) => {
        console.log(errors.response.data.message);
        if (errors) {
          // setIsLoading(false);
          setErrors(errors.response.data.message);
        }
      });
  };
  return (
    <div>
      <Dialog
        open={showEdit}
        keepMounted
        onClose={toggleEdit}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>{}</DialogTitle>
        {isLoading ? (
          <Box style={{ position: 'relative' }}>
            <CircularProgress
              size={40}
              left={-20}
              top={10}
              status={'loading'}
              style={{ marginLeft: '50%' }}
            />
          </Box>
        ) : (
          <>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'></DialogContentText>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 2 },
                }}
              >
                <FormControl>
                  {errors.map((error) => {
                    return <Alert severity='error'>{error.message}</Alert>;
                  })}
                  <Grid container spacing={6}>
                    <Grid item xs={6}>
                      <TextField
                        margin='normal'
                        required
                        fullWidth
                        id='Contact Number'
                        label='Contact Number'
                        name='Contact Number'
                        autoComplete='Contact Number'
                        type='number'
                        value={contact_number}
                        onChange={(e) => setContactNumber(e.target.value)}
                        autoFocus
                        error={errorMessage.some(
                          (error) => error.path[0] === 'contact_number',
                        )}
                        helperText={
                          errorMessage
                            .find((error) => error.path[0] === 'contact_number')
                            ?.message.replace(/"/g, '') || ''
                        }
                      />
                      <TextField
                        margin='normal'
                        required
                        fullWidth
                        id='Billing Address'
                        label='Billing Address'
                        name='Billing Address'
                        autoComplete='Billing Address'
                        value={
                          billing_address
                            ? billing_address
                            : user.billing_address
                        }
                        onChange={(e) => setBillingAddress(e.target.value)}
                        autoFocus
                        error={errorMessage.some(
                          (error) => error.path[0] === 'billing_address',
                        )}
                        helperText={
                          errorMessage
                            .find(
                              (error) => error.path[0] === 'billing_address',
                            )
                            ?.message.replace(/"/g, '') || ''
                        }
                      />

                      <TextField
                        margin='normal'
                        required
                        fullWidth
                        id='Pan Number'
                        label='PAN/VAT Number'
                        name='Pan Number'
                        autoComplete='Pan Number'
                        type='number'
                        value={pan_number}
                        onChange={(e) => {
                          setPanNumber(e.target.value);
                        }}
                        autoFocus
                        error={errorMessage.some(
                          (error) => error.path[0] === 'pan_number',
                        )}
                        helperText={
                          errorMessage
                            .find((error) => error.path[0] === 'pan_number')
                            ?.message.replace(/"/g, '') || ''
                        }
                      />
                      <br />
                    </Grid>
                    <Grid item xs={6}>
                      {user.user_type === 'organization' ? (
                        <>
                          <TextField
                            margin='normal'
                            fullWidth
                            id='Contact person name'
                            label="Contact Person's Name"
                            name='Contact person name'
                            autoComplete='Contact person name'
                            value={contact_person_name}
                            onChange={(e) =>
                              setContactPersonName(e.target.value)
                            }
                            autoFocus
                            error={errorMessage.some(
                              (error) =>
                                error.path[0] === 'contact_person_name',
                            )}
                            helperText={
                              errorMessage
                                .find(
                                  (error) =>
                                    error.path[0] === 'contact_person_name',
                                )
                                ?.message.replace(/"/g, '') || ''
                            }
                          />
                          <TextField
                            margin='normal'
                            fullWidth
                            id='Contact person mobile number'
                            label="Contact Person's Mobile Number"
                            name='Contact person mobile number'
                            autoComplete='Contact person mobile number'
                            value={contact_person_mobile_number}
                            onChange={(e) =>
                              setContactPersonMobileNumber(e.target.value)
                            }
                            autoFocus
                            error={errorMessage.some(
                              (error) =>
                                error.path[0] ===
                                'contact_person_mobile_number',
                            )}
                            helperText={
                              errorMessage
                                .find(
                                  (error) =>
                                    error.path[0] ===
                                    'contact_person_mobile_number',
                                )
                                ?.message.replace(/"/g, '') || ''
                            }
                          />
                          <TextField
                            margin='normal'
                            fullWidth
                            id='Contact person Email'
                            label="Contact Person's Email"
                            name='Contact person Email'
                            autoComplete='Contact person Email'
                            value={contact_person_email}
                            onChange={(e) =>
                              setContactPersonEmail(e.target.value)
                            }
                            autoFocus
                            error={errorMessage.some(
                              (error) =>
                                error.path[0] === 'contact_person_email',
                            )}
                            helperText={
                              errorMessage
                                .find(
                                  (error) =>
                                    error.path[0] === 'contact_person_email',
                                )
                                ?.message.replace(/"/g, '') || ''
                            }
                          />
                          <TextField
                            margin='normal'
                            fullWidth
                            id="Contact person's Designation"
                            label="Contact Person's Designation"
                            name='Contact person Designation'
                            autoComplete='Contact person Designation'
                            value={contact_person_designation}
                            onChange={(e) =>
                              setContactPersonDegination(e.target.value)
                            }
                            autoFocus
                            error={errorMessage.some(
                              (error) =>
                                error.path[0] === 'contact_person_designation',
                            )}
                            helperText={
                              errorMessage
                                .find(
                                  (error) =>
                                    error.path[0] ===
                                    'contact_person_designation',
                                )
                                ?.message.replace(/"/g, '') || ''
                            }
                          />
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSubmit}>Edit</Button>
              <Button onClick={toggleEdit}>Cancel</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default EditProfile;
