import React from 'react';
import axiosInstance from '../../../utils/axios';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DeleteEvent = ({
  events,
  setEvents,
  deleteId,
  showDelete,
  toggleDelete,
}) => {
  const confirmDelete = () => {
    axiosInstance.delete(`/v1/events/${deleteId}/`).then((resp) => {
      if (resp.status === 200) {
        const newList = events.map((key) => {
          if (key._id === deleteId) {
            return { ...key, status: 'deleted' };
          }
          return key;
        });
        // console.log('newList', newList);
        setEvents(newList);
        toggleDelete();
        toast.error(resp.data.message);
      }
    });
  };
  return (
    <div>
      <Dialog
        open={showDelete}
        onClose={toggleDelete}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Deleting event alert'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure want to delete an event?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmDelete}>Confirm</Button>
          <Button onClick={toggleDelete} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteEvent;
