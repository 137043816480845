import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import { InputLabel, MenuItem, Select } from '@mui/material';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});
const CreateRate = ({ open, handleClose, rate, setRate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [details, setDetail] = useState([
    {
      minimum_cp: '',
      maximum_cp: '',
      rate_per_cp: '',
    },
  ]);
  // const [free_credits,setFree]=useState('');

  const addDetail = () => {
    setDetail([
      ...details,
      {
        minimum_cp: '',
        maximum_cp: '',
        rate_per_cp: '',
      },
    ]);
  };

  const removeDetail = (index) => {
    const newDetails = [...details];
    newDetails.splice(index, 1);
    setDetail(newDetails);
  };

  const handleDetailsChange = (index, key, value) => {
    const newDetails = details.map((detail, i) => {
      if (i === index) {
        return { ...detail, [key]: value };
      }
      return detail;
    });
    setDetail(newDetails);
  };

  const handleSubmit = () => {
    axiosInstance
      .post('/v1/rates/', { details })
      .then((resp) => {
        setIsLoading(true);
        console.log(resp);
        if (resp.status === 201) {
          setIsLoading(false);
          const newRate = [...rate];
          newRate.unshift(resp.data.data);
          setRate(newRate);

          handleClose();
          toast.success('New rate created successfully');
        }
      })
      .catch((errors) => {
        // console.log(errors.response.data.message);
        if (errors) {
          setIsLoading(false);

          setErrors(errors.response.data.message);
        }
      });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby='alert-dialog-slide-cp_range'
      maxWidth='xl'
    >
      <DialogTitle>{'Create Rate'}</DialogTitle>
      {isLoading ? (
        <Box style={{ position: 'relative' }}>
          <CircularProgress
            size={40}
            left={-20}
            top={10}
            status={'loading'}
            style={{ marginLeft: '50%' }}
          />
        </Box>
      ) : (
        <>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-cp_range'></DialogContentText>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                '& > :not(style)': { m: 2 },
              }}
            >
              <FormControl>
                {errors.map((error) => {
                  return <Alert severity='error'>{error.message}</Alert>;
                })}
                {/* <FormControl>
                  <TextField
                    label='Enter free credit points'
                    value={free_credits}
                    onChange={(e) => setFree(e.target.value)}
                    style={{ marginRight: '20px' }}
                  />
                </FormControl> */}

                {details.map((detail, index) => (
                  <div key={index} style={{ marginBottom: '20px' }}>
                    {/* <FormControl style={{ width: '10rem', marginRight: '20px' }}>
      <InputLabel>Type</InputLabel>
      <Select
        value={detail.type}
        onChange={(e) => handleDetailsChange(index, "type", e.target.value)}
      >
        <MenuItem value="free">Free</MenuItem>
        <MenuItem value="paid">Paid</MenuItem>
      </Select>
    </FormControl> */}

                    <TextField
                      label='CP Range Minimum'
                      value={detail.minimum_cp}
                      onChange={(e) =>
                        handleDetailsChange(index, 'minimum_cp', e.target.value)
                      }
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label='CP Range Maximum'
                      value={detail.maximum_cp}
                      onChange={(e) =>
                        handleDetailsChange(index, 'maximum_cp', e.target.value)
                      }
                      style={{ marginRight: '20px' }}
                    />

                    <TextField
                      label='Rate per CP'
                      type='number'
                      value={detail.rate_per_cp}
                      onChange={(e) =>
                        handleDetailsChange(
                          index,
                          'rate_per_cp',
                          e.target.value,
                        )
                      }
                      style={{ marginRight: '20px' }}
                    />
                    <br />
                    {index > 0 && (
                      <Button
                        style={{ marginTop: '1rem' }}
                        variant='outlined'
                        color='secondary'
                        onClick={() => removeDetail(index)}
                      >
                        Remove
                      </Button>
                    )}
                    <br />
                  </div>
                ))}
                <Button variant='outlined' color='primary' onClick={addDetail}>
                  Add Detail
                </Button>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit}>Create</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default CreateRate;
