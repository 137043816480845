import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { FormControl } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const options = [
    "free",
    'pre-paid',
    'post-paid',
  ];

const ShowPaymentType = ({toggleShowPaymentType,showPaymentType,users,setUsers,typeId}) => {

    const [errors, setErrors] = useState([]);
    const [isLoading,setIsLoading]=useState(false);
    const [payment_type,setType]=useState('');

    // console.log("typeId",typeId)
    console.log("user",users)

    const handleSubmit =()=>{
        axiosInstance.put(`/v1/user/${typeId}/payment`,{payment_type}).then(resp=>{
            if(resp.status===200){
                toggleShowPaymentType();
                // window.location.reload();
                const updatedUser = users.find(item=>item._id ===typeId)
                const newuser ={...updatedUser,payment_type}
                // console.log("updatedUser",newuser)
                const removeold =users.filter(item=>item._id !==typeId)
                // console.log("removeold",removeold)
                const final=[...removeold,newuser]
                // console.log("final",final)
                setUsers(final)

            }

        })

    }

  return (
    <div> <Dialog
    open={showPaymentType}
    keepMounted
    onClose={toggleShowPaymentType}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle>{}</DialogTitle>
    {isLoading?<Box style={{position: 'relative'}}>
       <CircularProgress
       size={40}
       left={-20}
       top={10}
       status={'loading'}
       style={{marginLeft: '50%'}}
       />
     </Box>:<>
      <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
      </DialogContentText>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > :not(style)': { m: 2 },
          }}
        >
          <FormControl>
            {errors.map(error => {
              return <Alert severity="error">{error.message}</Alert>

            })}

            <label>Select Payment type</label>
            <Select
                id="demo-simple-select"
                value={payment_type}
                // label="Select an option"
                onChange={(event: SelectChangeEvent) => {
                    console.log(event.target.value)
                    setType(event.target.value);
                  }}
                >
                    {options.map(option=>{
                        return  <MenuItem value={option}>{option}</MenuItem>
                        
                    })}
           
                </Select>                   
          
           
          </FormControl>


        </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleSubmit}>Complete</Button>
      <Button onClick={toggleShowPaymentType}>Cancel</Button>
    </DialogActions>
    </>
    }
   
  </Dialog></div>
  )
}

export default ShowPaymentType