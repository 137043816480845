import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { Grid } from '@material-ui/core';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

const Details = ({ allDate, show, toggle }) => {
  // console.log('allDate', allDate);
  return (
    <div>
      <Dialog
        open={show}
        onClose={toggle}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='xl'
        fullWidth={true}
      >
        <Grid container>
          <Grid item xs={12}>
            <DialogTitle id='alert-dialog-title'>{' Details'}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'></DialogContentText>
              <TableContainer
                style={{ border: '1px solid #ccc', marginTop: 20 }}
              >
                <Table style={{ minWidth: 650 }}>
                  <TableHead style={{ backgroundColor: '#F4EFED ' }}>
                    <TableRow>
                      <TableCell>Service</TableCell>
                      <TableCell>Credit Per 1000 Request</TableCell>
                      <TableCell> Number of request</TableCell>
                      <TableCell> Credit Points used</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {allDate.map((key) => (
                      <TableRow key={key._id}>
                        <TableCell>
                          {key.service} [{key.sub_service}]
                        </TableCell>
                        <TableCell>{key.credit_per_request * 1000}</TableCell>
                        <TableCell>{key.successful_usage}</TableCell>
                        <TableCell>
                          {key.credit_points_used.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
          </Grid>
        </Grid>

        <DialogActions>
          <Button onClick={toggle} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Details;
