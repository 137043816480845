import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import Button from '@mui/material/Button';
import { TiTickOutline } from 'react-icons/ti';
import { AiOutlineSearch, AiOutlineCloseCircle } from 'react-icons/ai';
import { Pagination } from '@mui/material';
import VerifyPlace from './VerifyPlace';
import RejectPlace from './RejectPlace';

const Inspect = ({
  inspect,
  setInspect,
  showdetail,
  setDetail,
  toggle,
  handleInspectPageChange,
  totalInspectPages,
  currentInspectPage,
  rejected,
  setRejected,
  verified,
  setVerified,
  totalVerifiedResults,
  setTotalVerifiedResults,
  totalRejectedResults,
  setTotalRejectedResults,
  setTotalInspectResults,
  totalInspectResults,
}) => {
  const [detailData, setDetailData] = useState([]);
  const [showVerfyPlace, setShowVerifyPlace] = useState(false);
  const toggleVerifyPlace = () => setShowVerifyPlace(!showVerfyPlace);
  const [showRejectPlace, setShowRejectPlace] = useState(false);
  const toggleReject = () => setShowRejectPlace(!showRejectPlace);

  const showall = (row) => {
    // console.log('row', row);
    setDetail(true);
    setDetailData(row);
  };
  // console.log('totalInspectPages', totalInspectPages);

  const showverify = (row) => {
    // console.log('row', row);
    const newRow = {
      ...row,
      frominspect: true,
    };
    setShowVerifyPlace(true);
    setDetailData(newRow);
  };

  const showreject = (row) => {
    setShowRejectPlace(true);
    const newRow = { ...row, frominspect: true };
    setDetailData(newRow);
  };

  const tableContainerStyle = {
    maxHeight: 600, // Set the maximum height as needed
    // overflowY: 'auto',
  };
  const baseUrl = `${process.env.REACT_APP_EMPLOYEE_URL}`;

  return (
    <div>
      {showVerfyPlace ? (
        <VerifyPlace
          inspect={inspect}
          setInspect={setInspect}
          setTotalInspectResults={setTotalInspectResults}
          totalInspectResults={totalInspectResults}
          showVerfyPlace={showVerfyPlace}
          detailData={detailData}
          toggleVerifyPlace={toggleVerifyPlace}
          verified={verified}
          setVerified={setVerified}
          totalVerifiedResults={totalVerifiedResults}
          setTotalVerifiedResults={setTotalVerifiedResults}
        />
      ) : null}
      {showRejectPlace ? (
        <RejectPlace
          inspect={inspect}
          setInspect={setInspect}
          setTotalInspectResults={setTotalInspectResults}
          totalInspectResults={totalInspectResults}
          toggleReject={toggleReject}
          showRejectPlace={showRejectPlace}
          detailData={detailData}
          rejected={rejected}
          setRejected={setRejected}
          totalRejectedResults={totalRejectedResults}
          setTotalRejectedResults={setTotalRejectedResults}
        />
      ) : null}
      <TableContainer component={Paper} style={tableContainerStyle}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.N</TableCell>
              <TableCell>Place name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Office/Store</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell>Verification</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inspect.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.placename}</TableCell>
                <TableCell>{row.category}</TableCell>
                <TableCell>{row.location}</TableCell>
                <TableCell>{row.contactInfo}</TableCell>
                <TableCell>{row.place_office_store ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  {row.photos !== null && row.photos !== undefined ? (
                    <>
                      {JSON.parse(row.photos).length > 0 ? (
                        <img
                          style={{ width: '10rem' }}
                          src={
                            baseUrl +
                            'images/' +
                            JSON.parse(row.photos)[0].image
                          }
                        />
                      ) : (
                        'N/A'
                      )}
                    </>
                  ) : (
                    'N/A'
                  )}
                </TableCell>
                <TableCell>
                  <Button>
                    <TiTickOutline
                      onClick={(e) => showverify(row)}
                      title='Verify'
                      style={{ color: '#32C949', fontSize: '1.5rem' }}
                    />
                  </Button>

                  <Button>
                    <AiOutlineCloseCircle
                      onClick={(e) => showreject(row)}
                      title='Reject'
                      style={{ color: '#FF3F2D', fontSize: '1.5rem' }}
                    />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalInspectPages}
        page={currentInspectPage}
        onChange={handleInspectPageChange}
        color='primary'
      />
    </div>
  );
};

export default Inspect;
