import React, { useEffect, useState } from "react";
import axiosInstance from "../../../utils/axios";
import { useLocation, useNavigate } from "react-router-dom";

const Esewa = () => {
  const search = useLocation().search;
  const amount = new URLSearchParams(search).get("amt");
  const refid = new URLSearchParams(search).get("refId");
  const points = new URLSearchParams(search).get("points");
  const oid = new URLSearchParams(search).get("oid");
  const navigate = useNavigate();

  const [apiCalled, setApiCalled] = useState(false);

  const verifyEsewa = async () => {
    console.log("before")
    if(apiCalled===false){
      const resp = await axiosInstance.post("/v1/verifyEsewa", {
        refid,
        amt: amount,
        oid,
        points,
      });
      // console.log("after")
      // debugger;
      if (resp.status === 200 ) {
        setApiCalled(true);
        console.log("after debugger")

        navigate("/capiKey");
        // window.location.reload();
      }

    }
  };

  useEffect(() => {
    verifyEsewa();
  }, []);

  return <div>Esewa</div>;
};

export default Esewa;
