import React, { useState, useEffect } from 'react';
import EventContext from './EventContext';
import Components from './Components';
import axiosInstance from '../../../../utils/axios';

const Event = () => {
  const [data, setData] = useState([]);
  const [newEvent, setNewEvent] = useState([]);
  const [verifiedEvent, setVerifiedEvent] = useState([]);
  const [unVerifiedEvent, setUnVerifiedEvent] = useState([]);
  const [deletedEvent, setDeletedEvent] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await axiosInstance.get('/v1/events');
      const datas = response.data.data.results.filter(
        (item) => item.isSpecial === false,
      );
      setData(datas);
    };
    getData();
  }, []);
  useEffect(() => {
    const filteredNew = data.filter((item) => item.status === 'new');
    const filteredVerified = data.filter((item) => item.status === 'verified');
    const filteredRejected = data.filter((item) => item.status === 'rejected');
    // const filterDeleted = data.filter((item) => item.status === 'deleted');
    setNewEvent(filteredNew);
    setVerifiedEvent(filteredVerified);
    setUnVerifiedEvent(filteredRejected);
    // setDeletedEvent(filterDeleted);
  }, [data]);
  // console.log('events', deletedEvent, newEvent, verifiedEvent, unVerifiedEvent);
  return (
    <EventContext.Provider
      value={{
        newEvent,
        verifiedEvent,
        unVerifiedEvent,
        deletedEvent,
        setNewEvent,
        setUnVerifiedEvent,
        setVerifiedEvent,
        setDeletedEvent,
      }}
    >
      <Components />
    </EventContext.Provider>
  );
};

export default Event;
