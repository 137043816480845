import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { toast } from 'react-toastify';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

const options = ['admin', 'client', 'employee'];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const CreateUser = ({ open, handleClose, users, setUsers }) => {
  const [username, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    axiosInstance
      .post('v1/register', { username, email, role, password })
      .then((resp) => {
        // console.log("hello",resp.data);
        setIsLoading(true);
        if (resp.status === 201) {
          setIsLoading(false);

          handleClose();
          toast.success('User created Successfully');
          window.location.reload();
          let {
            username,
            role,
            email,
            createdAt,
            updatedAt,
            verified,
            user_type,
            payment_type,
            credit_points,
          } = resp.data.data;
          const newUser = [
            ...users,
            {
              username: username,
              role: role,
              email: email,
              createdAt: createdAt,
              updatedAt: updatedAt,
              verified: verified,
            },
          ];
          setUsers(newUser);
        }
      })
      .catch((errors) => {
        // console.log(errors.response.data.message);
        if (errors) {
          setIsLoading(false);
          setErrors(errors.response.data.message);
        }
      });
  };

  return (
    <div>
      {' '}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>{'Create User'}</DialogTitle>
        {isLoading ? (
          <Box style={{ position: 'relative' }}>
            <CircularProgress
              size={40}
              left={-20}
              top={10}
              status={'loading'}
              style={{ marginLeft: '50%' }}
            />
          </Box>
        ) : (
          <>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'></DialogContentText>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 2 },
                }}
              >
                <FormControl>
                  {errors.map((error) => {
                    return <Alert severity='error'>{error.message}</Alert>;
                  })}

                  <TextField
                    placeholder='Enter Username'
                    label='Username'
                    type='text'
                    value={username}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                  <br />

                  <TextField
                    placeholder='Enter Email address '
                    label='Email'
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <br />

                  <TextField
                    placeholder='Enter password'
                    id='demo-helper-text-aligned-no-helper'
                    label='Password'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <br />
                  <label>Select role</label>
                  <Select
                    id='demo-simple-select'
                    value={role}
                    // label="Select an option"
                    onChange={(event: SelectChangeEvent) => {
                      console.log(event.target.value);
                      setRole(event.target.value);
                    }}
                  >
                    {options.map((option) => {
                      return <MenuItem value={option}>{option}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSubmit}>Create</Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default CreateUser;
