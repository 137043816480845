import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import Button from '@mui/material/Button';
import { Pagination } from '@mui/material';
import { TiTickOutline } from 'react-icons/ti';
import { AiOutlineSearch, AiOutlineCloseCircle } from 'react-icons/ai';
import VerifyPlace from './VerifyPlace';
import RejectPlace from './RejectPlace';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const Verified = ({
  verified,
  setVerified,
  rejected,
  setRejected,
  showdetail,
  setDetail,
  toggle,
  handleVerifiedPageChange,
  totalVerifiedPages,
  currentVerifiedPage,
  setTotalVerifiedResults,
  totalVerifiedResults,
  setTotalRejectedResults,
  totalRejectedResults,
}) => {
  const [detailData, setDetailData] = useState([]);

  const [showVerfyPlace, setShowVerifyPlace] = useState(false);
  const toggleVerifyPlace = () => setShowVerifyPlace(!showVerfyPlace);
  const [showRejectPlace, setShowRejectPlace] = useState(false);
  const toggleReject = () => setShowRejectPlace(!showRejectPlace);

  const showreject = (row) => {
    const newRow = { ...row, fromverify: true };
    setShowRejectPlace(true);
    setDetailData(newRow);
  };
  const tableContainerStyle = {
    maxHeight: 600, // Set the maximum height as needed
    // overflowY: 'auto',
  };
  const baseUrl = `${process.env.REACT_APP_EMPLOYEE_URL}`;

  return (
    <div>
      {showVerfyPlace ? (
        <VerifyPlace
          showVerfyPlace={showVerfyPlace}
          detailData={detailData}
          toggleVerifyPlace={toggleVerifyPlace}
        />
      ) : null}
      {showRejectPlace ? (
        <RejectPlace
          toggleReject={toggleReject}
          showRejectPlace={showRejectPlace}
          detailData={detailData}
          verified={verified}
          setVerified={setVerified}
          rejected={rejected}
          setRejected={setRejected}
          totalVerifiedResults={totalVerifiedResults}
          setTotalVerifiedResults={setTotalVerifiedResults}
          totalRejectedResults={totalRejectedResults}
          setTotalRejectedResults={setTotalRejectedResults}
        />
      ) : null}
      <TableContainer component={Paper} style={tableContainerStyle}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.N</TableCell>
              <TableCell>Place name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Office/Store</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell>Verification</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {verified.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  {index + 1 + (currentVerifiedPage - 1) * 10}
                </TableCell>
                <TableCell>{row.placename}</TableCell>
                <TableCell>{row.category}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>{row.contactInfo}</TableCell>
                <TableCell>{row.place_office_store ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  {row.photos !== null && row.photos !== undefined ? (
                    <>
                      {JSON.parse(row.photos).length > 0 ? (
                        <Zoom>
                          <img
                            style={{ width: '10rem' }}
                            src={
                              baseUrl +
                              'images/' +
                              JSON.parse(row.photos)[0].image
                            }
                          />
                        </Zoom>
                      ) : (
                        'N/A'
                      )}
                    </>
                  ) : (
                    'N/A'
                  )}
                </TableCell>
                <TableCell>
                  {/* <Button>
                    <AiOutlineSearch
                      title='Inspect'
                      style={{ color: '#FFAA00', fontSize: '1.5rem' }}
                    />
                  </Button> */}
                  <Button>
                    <AiOutlineCloseCircle
                      onClick={(e) => showreject(row)}
                      title='Reject'
                      style={{ color: '#FF3F2D', fontSize: '1.5rem' }}
                    />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalVerifiedPages}
        page={currentVerifiedPage}
        onChange={handleVerifiedPageChange}
        color='primary'
      />
    </div>
  );
};

export default Verified;
