import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

const CreatePackages = ({
  setPackages,
  packages,
  toggleCreate,
  showCreatePackage,
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [credit_points, setCreditPoints] = useState('');
  const [price, setPrice] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [expiration_date, setExpirationDate] = useState();

  useEffect(() => {
    setPrice(credit_points * 50);
  }, [credit_points]);
  useEffect(() => {
    const defaultDate = new Date();
    defaultDate.setFullYear(defaultDate.getFullYear() + 1);
    var parsedDate = moment(defaultDate, 'M/D/YYYY');
    var formattedDate = parsedDate.format('YYYY-MM-DDTHH:mm');
    setExpirationDate(formattedDate);
  }, [1]);
  const handleSubmit = () => {
    axiosInstance
      .post('/v1/packages', {
        name,
        description,
        credit_points,
        price,
        expiration_date,
      })
      .then((resp) => {
        if (resp.status == 201) {
          const newList = [
            ...packages,
            {
              name: name,
              description: description,
              credit_points: credit_points,
              price: price,
              expiration_date: expiration_date,
            },
          ];
          setPackages(newList);
          toggleCreate();
        }
      })
      .catch((error) => {
        // console.log('error', error.response.data.message);
        setErrors(error.response.data.message);
      });
  };
  return (
    <Dialog
      open={showCreatePackage}
      keepMounted
      onClose={toggleCreate}
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle>{'Add packages'}</DialogTitle>
      {isLoading ? (
        <Box style={{ position: 'relative' }}>
          <CircularProgress
            size={40}
            left={-20}
            top={10}
            status={'loading'}
            style={{ marginLeft: '50%' }}
          />
        </Box>
      ) : (
        <>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'></DialogContentText>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                '& > :not(style)': { m: 2 },
              }}
            >
              <FormControl>
                <TextField
                  label='Name'
                  placeholder='Enter name of packages'
                  id='demo-helper-text-aligned'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={errors.some((error) => error.path[0] === 'name')}
                  helperText={
                    errors
                      .find((error) => error.path[0] === 'name')
                      ?.message.replace(/"/g, '') || ''
                  }
                />
                <br />
                <TextField
                  label='Descriptions'
                  placeholder='Enter descriptions'
                  id='demo-helper-text-aligned'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  error={errors.some(
                    (error) => error.path[0] === 'description',
                  )}
                  helperText={
                    errors
                      .find((error) => error.path[0] === 'description')
                      ?.message.replace(/"/g, '') || ''
                  }
                />
                <br />
                <TextField
                  label='Free credit points'
                  placeholder='Enter credit points'
                  id='demo-helper-text-aligned'
                  value={credit_points}
                  onChange={(e) => setCreditPoints(e.target.value)}
                  error={errors.some(
                    (error) => error.path[0] === 'credit_points',
                  )}
                  helperText={
                    errors
                      .find((error) => error.path[0] === 'credit_points')
                      ?.message.replace(/"/g, '') || ''
                  }
                />
                <br />
                <TextField
                  label='Price'
                  placeholder='Price'
                  id='demo-helper-text-aligned'
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                <br />
                <TextField
                  id='datetime-local'
                  label='Expiry date'
                  type='datetime-local'
                  // disabled
                  // defaultValue={formattedDate}
                  value={expiration_date}
                  onChange={(e) => setExpirationDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit}>Create</Button>
            <Button onClick={toggleCreate}>Cancel</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default CreatePackages;
