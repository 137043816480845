import React from 'react';
import axiosInstance from '../../../utils/axios';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DeleteModal = ({
  contact,
  showDeleteModal,
  toggleDeleteModal,
  setContact,
  delete_id,
}) => {
  const confirmDelete = () => {
    axiosInstance.delete(`/v1/contact-us/${delete_id}/`).then((resp) => {
      console.log(resp.data.message);
      if (resp.status === 200) {
        const newList = contact.filter((key) => key._id !== delete_id);
        setContact(newList);
        toggleDeleteModal();
        toast.error(resp.data.message);
      }
    });
  };
  return (
    <div>
      {' '}
      <Dialog
        open={showDeleteModal}
        onClose={toggleDeleteModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Delete contact us'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure want to delete contact us?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmDelete}>Confirm</Button>
          <Button onClick={toggleDeleteModal} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteModal;
