import React, { useState, useEffect } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import './sidebar.css';
import img from '.././admin/Galli_color.png';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import VpnKeyTwoToneIcon from '@material-ui/icons/VpnKeyTwoTone';
import LogoutIcon from '@mui/icons-material/Logout';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ResetTvIcon from '@mui/icons-material/ResetTv';
import Approval from '@mui/icons-material/Approval';
import { useFetch } from '../hooks/useFetch';
import MiscellaneousServicesTwoToneIcon from '@mui/icons-material/MiscellaneousServicesTwoTone';
import PaidIcon from '@mui/icons-material/Paid';
import RateReview from '@mui/icons-material/RateReview';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useAdminClient } from '../hooks/useAdminClient';
import jwt_decode from 'jwt-decode';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { toast } from 'react-toastify';
import WifiCallingIcon from '@mui/icons-material/WifiCalling';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { useEmployeeAuth } from '../hooks/useEmployeeAuth';
import { useClientAuth } from '../hooks/useClientAuth';
import PlaceIcon from '@mui/icons-material/Place';
import BusinessIcon from '@mui/icons-material/Business';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const useAdminAuth = () => {
  const token = window.localStorage.getItem('galli_token');
  const decoded = jwt_decode(token);
  // console.log(decoded.role);
  if (decoded.role === 'admin') {
    return true;
  } else {
    return false;
  }
};

const Sidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const auth = useAdminAuth();
  const employee = useEmployeeAuth();
  const client = useClientAuth();
  const adminClient = useAdminClient();

  const [user, setUser] = useState([]);
  const {} = useFetch(user, setUser, '/v1/getLoginUser');
  // console.log('sidebar user', user);
  const logout = () => {
    navigate('/login');

    window.localStorage.removeItem('galli_token');
    window.localStorage.removeItem('galli_Refreshtoken');
  };
  const token = localStorage.getItem('galli_token');

  useEffect(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      // console.log('exp', decodedToken.exp);

      const currentTime = Date.now() / 1000; // convert to seconds
      // console.log('decodedToken', currentTime);

      if (decodedToken.exp < currentTime) {
        logout();
        toast.error('Session Expired');
      }
    }
  }, [token, navigate]);

  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      toast.success('online');
    };
    const handleOffline = () => {
      setIsOnline(false);
      toast.error('No internet connection', {
        autoClose: false,
      });
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position='fixed' open={open} style={{ background: '#e37547' }}>
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' noWrap component='div'>
              Gallimaps
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant='permanent'
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: '#682110',
            },
          }}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon className='show-arrow' />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {' '}
            <Link className='link-image' to='/'>
              {' '}
              <img src={img} alt='no' />
            </Link>
          </List>

          <Divider />
          <br />

          {auth || client ? (
            <List>
              <Link className='link' to='/'>
                <HomeRoundedIcon />
                Dashboard
              </Link>
            </List>
          ) : null}
          <Divider />

          {auth ? (
            <>
              <Link className='link' to='/apiKey'>
                <VpnKeyTwoToneIcon />
                Api key
              </Link>
              <Link className='link' to='/user'>
                <PeopleOutlineIcon />
                User
              </Link>

              {/* <Link className='link' to="/plans"><PaymentsIcon/> Plans</Link> */}
              <Link className='link' to='/allInvoice'>
                <Approval /> All Invoice
              </Link>
              <Link className='link' to='/transactions'>
                <PaidIcon /> Transaction
              </Link>
              <Link className='link' to='/events'>
                <Approval /> Events
              </Link>
              <Link className='link' to='/earlyaccess'>
                <AccessibilityIcon /> EarlyAccess
              </Link>
              <Link className='link' to='/contact'>
                <WifiCallingIcon /> Contact us
              </Link>
              <Link className='link' to='/municipality'>
                <MapsHomeWorkIcon /> Municipality
              </Link>
            </>
          ) : (
            <>
              {client ? (
                <>
                  <Link className='link' to='/capiKey'>
                    <VpnKeyTwoToneIcon />
                    Access Token
                  </Link>
                  <Link className='link' to='/ctransaction'>
                    <PaidIcon /> Transaction
                  </Link>
                  <Link className='link' to='/userCreditHistory'>
                    <CreditScoreIcon /> Credit History
                  </Link>
                  <Link className='link' to='/rate_service'>
                    <RateReview /> Rate/Service
                  </Link>
                  {user.payment_type === 'post-paid' ? (
                    <Link className='link' to='/invoice'>
                      <Approval /> Invoice
                    </Link>
                  ) : null}
                </>
              ) : null}

              {employee ? (
                <>
                  <Link className='link' to='/addPlaceRequest'>
                    <PlaceIcon />
                    Add Place Requests
                  </Link>
                  <Link className='link' to='/addBusinessRequest'>
                    <BusinessIcon />
                    Add Business Requests
                  </Link>
                  <Link className='link' to='/addDepartment'>
                    <DepartureBoardIcon />
                    Add Department
                  </Link>
                  <Link className='link' to='/employeeEvent'>
                    <Approval />
                    Event
                  </Link>
                </>
              ) : null}
            </>
          )}
          {auth || user.payment_type === 'pre-paid' ? (
            <Link className='link' to='/packages'>
              <LocalShippingIcon /> Packages
            </Link>
          ) : null}
          {auth ? (
            <>
              <Link className='link' to='/rate'>
                <CorporateFareIcon /> Rate
              </Link>
              <Link className='link' to='/service'>
                <MiscellaneousServicesTwoToneIcon /> Services
              </Link>
            </>
          ) : null}

          <Link className='link' to='/changePassword'>
            <ResetTvIcon /> Change Password
          </Link>
          {/* <Link className='link' to="/test"><ResetTvIcon /> Test</Link> */}

          <Link className='link' to='/login' onClick={logout}>
            <LogoutIcon />
            Logout
          </Link>
        </Drawer>
        <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Typography component={'span'} variant={'body2'}>
            <Outlet />
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
