import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { Pagination } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { usePaginate } from '../../../hooks/usePaginate';
import DeleteModal from './DeleteModal';
import { AiFillDelete } from 'react-icons/ai';
import { GrMail } from 'react-icons/gr';
import Reply from './Reply';

function formatDate(dateString) {
  const formattedDate = new Date(dateString).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
  return formattedDate;
}

const Contact = () => {
  const [contact, setContact] = useState([]);
  const {
    data,
    itemsPerPage,
    handlePageChange,
    totalPages,
    isLoading,
    currentPage,
  } = usePaginate(contact, setContact, 7, `/v1/contact-us`);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const [delete_id, setDeleteId] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [email, setMail] = useState('');
  const [name, setName] = useState('');
  const deleteKey = (_id) => {
    setShowDeleteModal(true);
    setDeleteId(_id);
  };
  const handleOpenDialog = (key) => {
    setShowDialog(true);
    setMail(key.email);
    setName(key.name);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  console.log('contact', contact);

  return (
    <div>
      {showDeleteModal ? (
        <DeleteModal
          contact={contact}
          showDeleteModal={showDeleteModal}
          toggleDeleteModal={toggleDeleteModal}
          delete_id={delete_id}
          setContact={setContact}
        />
      ) : null}
      <Reply
        open={showDialog}
        onClose={handleCloseDialog}
        email={email}
        name={name}
        setShowDialog={setShowDialog}
      />
      {isLoading ? (
        <Box style={{ position: 'relative' }}>
          <CircularProgress
            size={40}
            left={-20}
            top={10}
            status={'loading'}
            style={{ marginLeft: '50%' }}
          />
        </Box>
      ) : (
        <>
          <TableContainer style={{ border: '1px solid #ccc', marginTop: 20 }}>
            <Table style={{ minWidth: 650 }} size='small'>
              <TableHead style={{ backgroundColor: '#F4EFED ' }}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell> Email</TableCell>
                  <TableCell> Phone</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell> Delete</TableCell>
                  <TableCell> Email</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {contact.map((key) => (
                  <TableRow key={key._id}>
                    <TableCell
                      style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                    >
                      {key.name}
                    </TableCell>
                    <TableCell>{key.email}</TableCell>
                    <TableCell>{key.phone}</TableCell>
                    <TableCell>{key.message}</TableCell>
                    <TableCell
                      style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                    >
                      {formatDate(key.createdAt)}
                    </TableCell>

                    <TableCell>
                      <Button>
                        <AiFillDelete
                          onClick={(e) => deleteKey(key._id)}
                          style={{
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                            color: 'red',
                          }}
                          title='Delete'
                        />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button>
                        <GrMail
                          onClick={(e) => handleOpenDialog(key)}
                          style={{ fontSize: '1.5rem', cursor: 'pointer' }}
                          title='Reply'
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color='primary'
          />
        </>
      )}
    </div>
  );
};

export default Contact;
