import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const durations =[
  '1 month','3 month','6month'
]
const CreatePlan = ({open, handleClose,plans,setPlans}) => {

    const [name,setName]=useState('');
  const [description,setDescription]=useState("");
  const [credit_points,setCreditPoints]=useState();
  const [freeCreditPoints,setFreeCreditPoints]=useState();
  const [isLoading,setIsLoading]=useState(false);
  const [errors, setErrors] = useState([]);
  const [price,setPrices]=useState();
  const [duration,setDuration]=useState('');

  const handleSubmit = () => {
    axiosInstance.post('/v1/plans/', { name, description, price,credit_points }).then(resp => {
      setIsLoading(true);
      if (resp.status===201) {
        setIsLoading(false);

        let {name,description,price,createdAt,updatedAt,credit_points,free_credit_points}=resp.data.data;
        const newPlans = [...plans, {
         
          name: name,
          description:description,
          price: price,
          credit_points:credit_points,
          free_credit_points:free_credit_points,
          createdAt:createdAt,
          updatedAt:updatedAt,
         

        }]
        setPlans(newPlans);
        handleClose();
        toast.success('Api Keys created successfully')
      }
    }).catch(errors => {
      // console.log(errors.response.data.message);
      if (errors) {
        setIsLoading(false);

        setErrors(errors.response.data.message)
      }

    })
  }

  return (
    <div>
    <Dialog
     open={open}
     TransitionComponent={Transition}
     keepMounted
     onClose={handleClose}
     aria-describedby="alert-dialog-slide-description"
   >
     <DialogTitle>{"Create Plan"}</DialogTitle>
     {isLoading?<Box style={{position: 'relative'}}>
        <CircularProgress
        size={40}
        left={-20}
        top={10}
        status={'loading'}
        style={{marginLeft: '50%'}}
        />
      </Box>:<>
       <DialogContent>
       <DialogContentText id="alert-dialog-slide-description">
       </DialogContentText>

         <Box
           sx={{
             display: 'flex',
             alignItems: 'center',
             '& > :not(style)': { m: 2 },
           }}
         >
           <FormControl>
             {errors.map(error => {
               return <Alert severity="error">{error.message}</Alert>

             })}

             <TextField
               label="Name"               
               placeholder='Enter name'
               id="demo-helper-text-aligned"
               value={name}
               onChange={(e) => setName(e.target.value)}
             />
             <br/>
             <TextField
               label="Description"               
               placeholder='Enter description'
               id="demo-helper-text-aligned-no-helper"
               value={description}
               onChange={(e) => setDescription(e.target.value)}

             />
             <br/>
             <TextField
               placeholder='Enter Price'
               label="Price"
               id="demo-helper-text-aligned-no-helper"
               value={price}
               onChange={(e) => setPrices(e.target.value)}

             />
              <br/>
             <TextField
               placeholder='Enter Credit points'
               label="Credit points"
               id="demo-helper-text-aligned-no-helper"
               value={credit_points}
               onChange={(e) => setCreditPoints(e.target.value)}

             />
            
            
           </FormControl>


         </Box>
     </DialogContent>
     <DialogActions>
       <Button onClick={handleSubmit}>Create</Button>
       <Button onClick={handleClose}>Cancel</Button>
     </DialogActions>
     </>
     }
    
   </Dialog>
 </div>
  )
}

export default CreatePlan