import React,{useState} from 'react';
import { useFetch } from '../../../hooks/useFetch';
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import {AiFillDelete} from "react-icons/ai"
import Button from '@mui/material/Button';
import DeleteModal from './DeleteModal';
import UpdateModal from './UpdateModal.tsx';
import {GrDocumentUpdate} from "react-icons/gr"
import CreatePlan from './CreatePlan.tsx';

const Plan = () => {
  const [open, setOpen] = React.useState(false);
  const [plans,setPlans]=useState([]);
  const {data,isLoading}=useFetch(plans,setPlans,'/v1/plans')
  const [showDeleteModal,setShowDeleteModal]=useState(false);
  const [delete_id,setDeleteId]=useState();
  const toggleDeleteModal =()=>setShowDeleteModal(!showDeleteModal);
  const [showUpdateModal,setShowUpdateModal]=useState(false);
  const toggleShowModal =()=>setShowUpdateModal(!showUpdateModal);
  const [update_id,setUpdateId]=useState([]);
  // console.log(plans);
  const deleteService =(_id)=>{
    setShowDeleteModal(true);
    setDeleteId(_id)

  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const updateService=(key)=>{
    setUpdateId(key);
    setShowUpdateModal(true);

  }


  return (
    <div>
    {open?<CreatePlan open={open} 
    setOpen={setOpen} handleClose={handleClose}
    plans={plans}
    setPlans={setPlans}
     handleClickOpen={handleClickOpen}/>:null}
      {showDeleteModal?<DeleteModal toggleDeleteModal={toggleDeleteModal}
     delete_id={delete_id}
     plans={plans}
     setPlans={setPlans}
      showDeleteModal={showDeleteModal} />:null}
       { showUpdateModal?<UpdateModal plans={plans} setPlans={setPlans} 
     showUpdateModal={showUpdateModal}
      toggleShowModal={toggleShowModal}
      update_id={update_id}
      />:null}
       <Button variant="outlined"
     onClick={handleClickOpen}

     >
      Create Plan
    </Button>
    <TableContainer style={{ border: '1px solid #ccc',marginTop:20}}>
    <Table style={{ minWidth: 650,}}>
    <TableHead style={{backgroundColor: '#F4EFED '}}>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Description</TableCell>
        <TableCell> Credit Points</TableCell>
        <TableCell> Free Credit Points</TableCell>
        <TableCell> Price</TableCell>
        <TableCell>Created At</TableCell>
        <TableCell>Updated At</TableCell>
        <TableCell>Action </TableCell>


      </TableRow>
    </TableHead>
    
   <TableBody>
      {plans.map((key) => (
        <TableRow key={key._id}>
          <TableCell>{key.name}</TableCell>
          <TableCell>{key.description}</TableCell>
          <TableCell>{key.credit_points}</TableCell>
          <TableCell>{key.free_credit_points}</TableCell>
          <TableCell>{key.price}</TableCell>
          <TableCell>{key.createdAt !== null ? key.createdAt.split('T')[0] : ''}</TableCell>
          <TableCell>{key.updatedAt !== null ? key.updatedAt.split('T')[0] : ''}</TableCell>
          <TableCell>
             <Button><AiFillDelete onClick={(e)=>deleteService(key._id)} style={{fontSize:'1.4rem',cursor:'pointer'}} title="Delete"/></Button>
             <Button><GrDocumentUpdate onClick={(e)=>updateService(key)} style={{fontSize:'1.4rem',cursor:'pointer'}} title="Update"/></Button>

           </TableCell>

        </TableRow>
      ))}
    </TableBody>
   

  </Table>

    </TableContainer>

  </div>
  )
}

export default Plan