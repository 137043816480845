import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

const Upgrade = ({ showUpgrade, toggleUpgrade }) => {
  return (
    <div>
      {' '}
      <Dialog
        open={showUpgrade}
        onClose={toggleUpgrade}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Upgrade an account'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Typography variant='body1'>
              To upgrade an account, kindly reach out to our dedicated sales
              team. You can contact our friendly sales representative using the
              following contact details,
            </Typography>
            {/* <Typography variant="body1">
    </Typography> */}
            <div style={{ textAlign: 'center' }}>
              <Typography variant='body1' style={{ marginTop: '1.2rem' }}>
                📞 Mobile: +977 9841702323
              </Typography>

              <div style={{ marginBottom: '8px', textAlign: 'center' }}>
                <Typography variant='body1' style={{ fontSize: '1.2rem' }}>
                  <span style={{ marginRight: '8px', fontSize: '1.5rem' }}>
                    ✉️
                  </span>
                  sales@gallimaps.com
                </Typography>
              </div>
            </div>
            <Typography variant='body1' style={{ marginTop: '1rem' }}>
              Our team will be delighted to assist you. Feel free to get in
              touch with us at your convenience. We look forward to hearing from
              you!
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleUpgrade} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Upgrade;
