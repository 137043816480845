import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axiosInstance from '../../../utils/axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { AiFillCheckCircle } from 'react-icons/ai';
import { debounce } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
const UpdateDomainName = ({
  showUpdateDomainName,
  toggleUpdateDomainName,
  update_domain,
  keys,
  setKeys,
}) => {
  const [domain_name, setDomainName] = useState(update_domain.domain_name);
  const [domainCheck, setDomainCheck] = useState(false);
  const [domainNameErrorMessage, setErrorMessage] = useState('');
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedWords, setSelectedWords] = useState([]);
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  useEffect(() => {
    const domainNames = update_domain.domain_name
      .split(';')
      .map((domain) => domain.trim())
      .filter(Boolean);
    setSelectedWords(domainNames);
  }, []);

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      const word = inputValue.trim();
      if (word !== '') {
        setSelectedWords([...selectedWords, word]);
        setInputValue('');
      }
    }
  };

  const handleWordClick = (index) => {
    const updatedWords = [...selectedWords];
    setSelectedWords(updatedWords);
    const final = updatedWords.join(';');
    setDomainName(final);
  };
  const handleRemoveWord = (index) => {
    const updatedWords = [...selectedWords];
    updatedWords.splice(index, 1);
    setSelectedWords(updatedWords);
    const final = updatedWords.join(';');
    setDomainName(final);
  };

  const checkDomainAvailability = async (domain_name) => {
    try {
      const response = await axiosInstance.post('/v1/dns-lookup', {
        domain_name,
      });
      console.log(response.data.data);
      if (response.data.data === true) {
        setDomainCheck(true);
        setShow(false);
      }
    } catch (error) {
      console.log(error.response.data.message);
      setErrorMessage(error.response.data.message[0].message);
      setDomainCheck(false);
      setShow(true);
    }
  };

  console.log(domainNameErrorMessage);

  useEffect(() => {
    if (domain_name) {
      checkDomainAvailability(domain_name);
    }
  }, [domain_name, domainCheck]);

  const debouncedCheckDomainAvailability = debounce(
    checkDomainAvailability,
    1000,
  );

  const handleDomainChange = (event) => {
    const domain_name = event.target.value;
    setDomainName(domain_name);
    checkDomainAvailability(domain_name);
    if (domain_name) {
      debouncedCheckDomainAvailability(domain_name);
    }
  };
  //
  const crossedStyle = {
    textDecoration: 'line-through',
    color: 'gray',
  };
  const update = () => {
    axiosInstance
      .put(`/v1/api-keys/${update_domain._id}`, {
        domain_name,
      })
      .then((resp) => {
        // setIsLoading(true);
        if (resp.status === 200) {
          //   setIsLoading(false);
          const updatedKey = [...keys];
          const index = updatedKey.findIndex(
            (i) => i._id === resp.data.data._id,
          );

          let {
            alias,
            domain_name,
            max_hit,
            counter,
            expire,
            _id,
            createdAt,
            updatedAt,
            access_key,
            expiration_date,
            services,
          } = resp.data.data;
          const newKey = {
            _id: _id,
            alias: alias,
            domain_name: domain_name,
            max_hit: max_hit,
            counter: counter,
            expire: expire,
            createdAt: createdAt,
            updatedAt: updatedAt,
            services: services,
            expiration_date: expiration_date,
            access_key: access_key,
          };
          updatedKey.splice(index, 1, newKey);

          setKeys(updatedKey);
          // window.location.reload();

          toggleUpdateDomainName();
          toast.success('Api Keys updated successfully');
        }
      })
      .catch((errors) => {
        // console.log(errors.response.data.message);
      });
  };
  return (
    <div>
      {' '}
      <Dialog
        open={showUpdateDomainName}
        onClose={toggleUpdateDomainName}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Are you sure want to update domain'}
        </DialogTitle>
        <DialogContent>
          <TextField
            placeholder='Enter the Domain Name '
            id='demo-helper-text-aligned-no-helper'
            label='Domain Name'
            // disabled
            InputProps={{ readOnly: true }}
            value={domain_name}
            onChange={handleDomainChange}
            helperText={
              domainNameErrorMessage
                ? domainNameErrorMessage
                : "Please Provide your website's domain. If not available, indicate that by inserting an asterisk (*)."
            }
            error={show}
          />
          {domainCheck && <AiFillCheckCircle style={{ color: 'green' }} />}

          <br />
          <TextField
            label='Enter Domain or sub-domain'
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleInputKeyPress}
            button
            fullWidth
          ></TextField>
          <List>
            {selectedWords.map((word, index) => (
              <ListItem
                key={index}
                button
                onClick={() => handleWordClick(index)}
                // button
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <ListItemText
                  primary={word.startsWith('~') ? word.substring(1) : word}
                  className={word.startsWith('~') ? crossedStyle : ''}
                />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => handleRemoveWord(index)}>
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={update}>Update</Button>
          <Button onClick={toggleUpdateDomainName} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpdateDomainName;
