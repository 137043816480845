import React from 'react';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axiosInstance from '../../../utils/axios';
const DeletePackage = ({
  deleteId,
  packages,
  setPackages,
  showDeleteModal,
  toggleDelete,
}) => {
  const confirmDelete = () => {
    axiosInstance.delete(`/v1/packages/${deleteId}`).then((resp) => {
      if (resp.status == 200) {
        const filteredPackage = packages.filter((i) => i._id !== deleteId);
        setPackages(filteredPackage);
        toggleDelete();
      }
    });
  };
  return (
    <div>
      {' '}
      <Dialog
        open={showDeleteModal}
        onClose={toggleDelete}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Delete packages alert'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure want to delete the package
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmDelete}>Confirm</Button>
          <Button onClick={toggleDelete} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeletePackage;
