import axios from 'axios';

const employeeBaseURL = `${process.env.REACT_APP_EMPLOYEE_URL}`;
// const token = `${process.env.REACT_APP_ACCESS_TOKEN}`;

const employeeBase = axios.create({
  baseURL: employeeBaseURL,
});

export default employeeBase;
