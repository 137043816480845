import React, { useState } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { usePaginate } from '../../../hooks/usePagintation';
import { Pagination } from '@mui/material';
import { useSearch } from '../../../hooks/useSearch';
import Input from '@mui/material/Input';

const CTransaction = () => {
  const [transactions, setTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { data, isLoading } = useFetch(
    transactions,
    setTransactions,
    '/v1/getLoginUserPayment/',
  );
  // const { searchResult } = useSearch(transactions, searchTerm);
  const { totalPages, currentPage, pageData, handlePageChange } = usePaginate(
    transactions,
    10,
  );

  return (
    <div>
      <b>Transaction</b>

      <TableContainer style={{ border: '1px solid #ccc', marginTop: 20 }}>
        <Table style={{ minWidth: 650 }}>
          <TableHead style={{ backgroundColor: '#F4EFED ' }}>
            <TableRow>
              <TableCell>Amount</TableCell>
              {/* <TableCell>Credit Points</TableCell> */}
              <TableCell> Type</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {pageData.map((key) => (
              <TableRow key={key._id}>
                <TableCell>
                  {key.amount !== 'null' ? key.amount.toFixed(2) : 'N/A'}
                </TableCell>
                {/* <TableCell>{key.credit_points}</TableCell> */}
                <TableCell>{key.type}</TableCell>
                <TableCell>
                  {key.createdAt !== null ? key.createdAt.split('T')[0] : ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color='primary'
      />
    </div>
  );
};

export default CTransaction;
