import React,{useState,useRef,useEffect} from 'react'
import{Avatar, Box,Typography,Container,Button,CssBaseline,TextField,FormControlLabel,Checkbox,Link,Grid} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import image from "./Galli_color.png"
import axiosBase from "../utils/axios"
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { toast } from 'react-toastify';
import { IconButton, InputAdornment} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormControl,InputLabel} from '@material-ui/core';

// const options = [
//   { name: 'User type', value: 'user type' },
//   { name: 'Individual', value: 'individual' },
//   { name: 'Organization', value: 'organization' }
// ];
const options = [
  'User type',
  'individual',
  'organization',
];
const types=[
  "Product Type",
  "E-commerce",
  "Food delivery",
  "Parcel delivery",
  "Ride sharing",
  "Home services",
  "ISP",
  "Bank",
  "IT Developer Company",
  "Others"
]

function Copyright(props) {
    return (
     
      <Typography variant="body2" color="text.secondary" align="center" marginTop="3rem">
      {'Copyright © Galli Express Private Limited 2023 '}
      <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem',marginTop:".25rem"  }}>
        <Link href="https://gallimaps.com/">Visit Website</Link>
        <Link href="https://gallimap.com/terms.html">Terms and Conditions</Link>
        <Link href="https://gallimap.com/privacy.html">Privacy Policy</Link>
      </div>
    </Typography>
      
     
    );
  }
  
  const theme = createTheme();
  
  

const Signup = () => {
    const [username , setUserName]=useState('');
  const [password,setPassword]=useState('');
  const [confirmPassword,setConfirmPassword]=useState('');
  const [errors, setErrors] = useState('');
  const [email,setEmail]=useState('')
  const [pan_number,setPanNumber]=useState('');
  const [billing_address,setBillingAddress]=useState('');
  const [contact_number,setContactNumber]=useState('');
  const [errorMessage,setErrorMessage]=useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [role,setRole]=useState('client');
  const [user_type,setUserType]=useState('User type');
  const [business_type,setBusinessType]=useState('Producet Type');
  const [contact_person_email,setContactPersonEmail]=useState('');
  const [contact_person_mobile_number,setContactPersonMobileNumber]=useState('');
  const [contact_person_designation,setContactPersonDegination]=useState('');
  const [contact_person_name,setContactPersonName]=useState('');
  const navigate  =useNavigate();
  const emailRef = useRef(null); // Reference to the email TextField
  useEffect(() => {
    // Focus on the email TextField when component mounts
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  const handleSubmit =(evt)=>{
    evt.preventDefault();
    const userData = {
      username,
      password,
      confirmPassword,
      email,
      role,
     
      contact_number,
      billing_address,
      user_type,
    };
  
    if (user_type === 'organization') {
      userData.business_type = business_type;
      userData.contact_person_name = contact_person_name;
      userData.contact_person_email = contact_person_email;
      userData.contact_person_mobile_number = contact_person_mobile_number;
      userData.contact_person_designation = contact_person_designation;
      userData.pan_number= pan_number;
    }
    axiosBase.post("v1/signUp", userData).then(resp=>{
      if(resp.status===201){
        navigate ('/login')
        toast.success('User Created sucessfull')


      }
     
    }).catch(errors => {
      // console.log(errors)
      if (errors.response.status===400) {
        setErrorMessage(errors.response.data.message)
      }else if(errors.response.status===401){
        setErrors(errors.response.data.message)

      }

    })
  }
  return (
    <div>  <ThemeProvider theme={theme}>
    <Container component="main"   maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onSubmit={handleSubmit}
      >
        <Avatar sx={{ m: 1,width: 100, height: 100  }} src={image}>
        </Avatar>
        <Typography component="h1" variant="h5">
          Galli Maps Sign up
        </Typography>
        {/* {errorMessage.map(error=>{
          return <Alert severity="error">{error.message}
                
          </Alert>
         

        })} */}
          {errors?<Alert severity="error">{errors}</Alert>:null}
           <Box component="form"  noValidate sx={{ mt: 1 }} item  xs={12} style={{marginTop:20}}>
           <FormControl fullWidth>
            <TextField
                     margin="normal"
                     required
                     fullWidth
                     id="username"
                     label="Applicant/Organization Name"
                     name="username"
                     autoComplete="username"
                     inputRef={emailRef}
                     value={username}
                     onChange={(e) => setUserName(e.target.value)}
                     autoFocus
                     error={errorMessage.some((error) => error.path[0] === "username")}
                     helperText={errorMessage.find((error) => error.path[0] === "username")?.message.replace(/"/g,'') || ''}

                   />
                   <TextField
                     margin="normal"
                     required
                     fullWidth
                     id="email"
                     label="Email"
                     name="email"
                     autoComplete="email"
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                     autoFocus
                     error={errorMessage.some((error) => error.path[0] === "email")}
                     helperText={errorMessage.find((error) => error.path[0] === "email")?.message.replace(/"/g,'') || ''}

                   />
                  
               <TextField
                     margin="normal"
                     required
                     fullWidth
                     name="password"
                     label="Password"
                     type={showPassword ? 'text' : 'password'}
                     id="password"
                     value={password}
                     onChange={(e) => setPassword(e.target.value)}
                     autoComplete="current-password"
                     InputProps={{
                         endAdornment: (
                           <InputAdornment position="end">
                             <IconButton onClick={() => setShowPassword(!showPassword)}>
                               {showPassword ? <Visibility /> : <VisibilityOff />}
                             </IconButton>
                           </InputAdornment>
                         ),
                       }}
                       error={errorMessage.some((error) => error.path[0] === "password")}
                       helperText={errorMessage.find((error) => error.path[0] === "password")?.message.replace(/"/g,'') || ''}
  
                   />
                        <TextField
                     margin="normal"
                     required
                     fullWidth
                     name="password"
                     label="Confirm Password"
                     type={showPassword ? 'text' : 'password'}
                     id="password"
                     value={confirmPassword}
                     onChange={(e) => setConfirmPassword(e.target.value)}
                     autoComplete="current-password"
                     InputProps={{
                         endAdornment: (
                           <InputAdornment position="end">
                             <IconButton onClick={() => setShowPassword(!showPassword)}>
                               {showPassword ? <Visibility /> : <VisibilityOff />}
                             </IconButton>
                           </InputAdornment>
                         ),
                       }}
                       error={errorMessage.some((error) => error.path[0] === "confirmPassword")}
                       helperText={errorMessage.find((error) => error.path[0] === "confirmPassword")?.message.replace(/"/g,'') || ''}
  
                   />
                    <TextField
                     margin="normal"
                     required
                     fullWidth
                     id="Contact Number"
                     label="Contact Number"
                     name="Contact Number"
                     autoComplete="Contact Number"
                     type="number"
                     value={contact_number}
                     onChange={(e) => setContactNumber(e.target.value)}
                     autoFocus
                     error={errorMessage.some((error) => error.path[0] === "contact_number")}
                     helperText={errorMessage.find((error) => error.path[0] === "contact_number")?.message.replace(/"/g,'') || ''}

                   />
                 <TextField
                     margin="normal"
                     required
                     fullWidth
                     id="Billing Address"
                     label="Billing Address"
                     name="Billing Address"
                     autoComplete="Billing Address"
                     value={billing_address}
                     onChange={(e) => setBillingAddress(e.target.value)}
                     autoFocus
                     error={errorMessage.some((error) => error.path[0] === "billing_address")}
                     helperText={errorMessage.find((error) => error.path[0] === "billing_address")?.message.replace(/"/g,'') || ''}

                   />
                     <FormControl>
                   {/* <InputLabel id="demo-simple-select-label">User type</InputLabel> */}
               <Select
                   id="demo-simple-select"
                   style={{ marginTop:"1rem"}}
                   value={user_type}
                   // labelId="demo-simple-select-label" // Use the same ID as the label
                   label="Select an option"
                   onChange={(event: SelectChangeEvent) => {
                       setUserType(event.target.value);
                     }}
                   >
                       {options.map(option=>{
                           return    <MenuItem value={option} disabled={option === 'User type'}>
                           {option.charAt(0).toUpperCase() + option.slice(1)}
                       </MenuItem>
                           
                       })}
              
                   </Select>
   
                   </FormControl>
   
                   {user_type==="organization"?
                   <>
                     <FormControl>
                   {/* <InputLabel id="demo-simple-select-label">User type</InputLabel> */}
               <Select
                   id="demo-simple-select"
                   style={{ marginTop:"1rem"}}
                   value={business_type}
                   // labelId="demo-simple-select-label" // Use the same ID as the label
                   label="Select a business type"
                   onChange={(event: SelectChangeEvent) => {
                       setBusinessType(event.target.value);
                     }}
                   >
                       {types.map(type=>{
                           return    <MenuItem value={type} disabled={type === 'Producet Type'} >
                             {type}
                       </MenuItem>
                           
                       })}
              
                   </Select>
   
                   </FormControl>
                   <TextField
                     margin="normal"
                     required
                     fullWidth
                     id="Pan Number"
                     label="PAN/VAT Number"
                     name="Pan Number"
                     autoComplete="Pan Number"
                     type="number"
                     value={pan_number}
                     onChange={(e) => setPanNumber(e.target.value)}
                     autoFocus
                     error={errorMessage.some((error) => error.path[0] === "pan_number")}
                     helperText={errorMessage.find((error) => error.path[0] === "pan_number")?.message.replace(/"/g,'') || ''}

                   />
               <TextField
                       margin="normal"
                       required
                       fullWidth
                       id="Contact person name"
                       label="Contact Person's Name"
                       name="Contact person name"
                       autoComplete="Contact person name"
                       value={contact_person_name}
                       onChange={(e) => setContactPersonName(e.target.value)}
                       autoFocus
                       error={errorMessage.some((error) => error.path[0] === "contact_person_name")}
                       helperText={errorMessage.find((error) => error.path[0] === "contact_person_name")?.message.replace(/"/g,'') || ''}
  
                       
             />
               <TextField
             margin="normal"
             required
             fullWidth
             id="Contact person mobile number"
             label="Contact Person's Mobile Number"
             name="Contact person mobile number"
             autoComplete="Contact person mobile number"
             value={contact_person_mobile_number}
             onChange={(e) => setContactPersonMobileNumber(e.target.value)}
             autoFocus
             error={errorMessage.some((error) => error.path[0] === "contact_person_mobile_number")}
             helperText={errorMessage.find((error) => error.path[0] === "contact_person_mobile_number")?.message.replace(/"/g,'') || ''}

             /> 
              <TextField
                       margin="normal"
                       required
                       fullWidth
                       id="Contact person Email"
                       label="Contact Person's Email"
                       name="Contact person Email"
                       autoComplete="Contact person Email"
                       value={contact_person_email}
                       onChange={(e) => setContactPersonEmail(e.target.value)}
                       autoFocus
                       error={errorMessage.some((error) => error.path[0] === "contact_person_email")}
                       helperText={errorMessage.find((error) => error.path[0] === "contact_person_email")?.message.replace(/"/g,'') || ''}
          
             />
               <TextField
                       margin="normal"
                       required
                       fullWidth
                       id="Contact person's Designation"
                       label="Contact Person's Designation"
                       name="Contact person Designation"
                       autoComplete="Contact person Designation"
                       value={contact_person_designation}
                       onChange={(e) => setContactPersonDegination(e.target.value)}
                       autoFocus
                       error={errorMessage.some((error) => error.path[0] === "contact_person_designation")}
                       helperText={errorMessage.find((error) => error.path[0] === "contact_person_designation")?.message.replace(/"/g,'') || ''}
          
             />
             
             
                   </>
                   :null}
           
           </FormControl> 
             <Button
               type="submit"
               fullWidth
               variant="contained"
               sx={{ mt: 3, mb: 2 ,bgcolor:"#e37547 ","&:hover":{bgcolor:"#812c19"}}}
               
             >
               Sign up
             </Button>
             <Grid container justifyContent="center">
               
               <Grid item>
                 <Link href="login" variant="body2">
                   {"Already have an account? Login"}
                 </Link>
               </Grid>
             </Grid>
           </Box>
          
       
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  </ThemeProvider></div>
  )
}

export default Signup