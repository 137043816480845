import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography, MenuItem, FormControl, Select } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import axiosInstance from '../../../utils/axios';
import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { Grid } from '@material-ui/core';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useFetch } from '../../../hooks/useFetch';
// import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
// import 'nepali-datepicker-reactjs/dist/index.css';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';
import NepaliDate from 'nepali-date-converter';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import 'nepali-datepicker-reactjs/dist/index.css';
const UserDetail = ({
  toggleUserDetail,
  showUserDetail,
  user,
  services,
  alias,
  access,
  totalHits,
  setHits,
  realHits,
  setRealHits,
}) => {
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState('option1'); // 'option1' is the default value for "This Week"
  const [newOption, setNewOption] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);
  const [totals, setTotals] = useState('');
  const [serviceData, setServiceData] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const { data } = useFetch(allServices, setAllServices, '/v1/services');
  const [newNepalidate, setNepaliDate] = useState([]);

  const [selectService, setSelectService] = useState('Distance');
  const handleServiceChange = (event) => {
    setSelectService(event.target.value);
  };
  // console.log('selectService', selectService);
  // console.log('selectedTOdat', selectedToDate);
  const getAllData = async () => {
    if (access) {
      try {
        let newFromDate, fromDate, toDate;

        // Determine the date range based on the selected option
        if (selectedOption === 'option1') {
          // For "This Week"
          const today = new Date();
          const startDate = new Date(today);
          startDate.setDate(startDate.getDate() - 7); // Subtract 6 days to get the start date for the last seven days
          const endDate = new Date(today);

          fromDate = startDate.toISOString().split('T')[0];
          toDate = endDate.toISOString().split('T')[0];
          const reversedFromDate = new Date(fromDate.split('T')[0]);
          const reversedToDate = new Date(toDate.split('T')[0]);

          setSelectedFromDate(reversedFromDate);
          setSelectedToDate(reversedToDate);
        } else if (selectedOption === 'option2') {
          const neapliToday = new NepaliDate(Date.now());
          const nepaliStartofMonth = neapliToday.getMonth();
          const nepaliyear = neapliToday.getYear();
          const finaldate = new NepaliDate(
            `${nepaliyear}`,
            `${nepaliStartofMonth}`,
            1,
          );
          console.log('nepaliyear', finaldate);
          const results = finaldate.toJsDate();

          // For "This Month"
          const today = new Date();
          const startOfMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            2,
          );
          // startOfMonth + 1;
          // Set the day to the 1st day of the month

          const endDate = new Date(today); // Use the current date
          endDate.setDate(today.getDate()); // Set the day to the current day of the month
          newFromDate = startOfMonth.toISOString().split('T')[0];
          fromDate = results;
          toDate = endDate.toISOString().split('T')[0];
          const reversedFromDate = new Date(newFromDate.split('T')[0]);
          const reversedToDate = new Date(toDate.split('T')[0]);
          setSelectedFromDate(reversedFromDate);
          setSelectedToDate(reversedToDate);
        } else if (selectedOption === 'option3') {
          const today = new Date();
          const startDate = new Date(today);
          startDate.setDate(startDate.getDate() - today.getDay() - 90); // Get the start of the week (Sunday) one week ago
          const endDate = new Date(today);

          fromDate = startDate.toISOString().split('T')[0];
          toDate = endDate.toISOString().split('T')[0];
          const reversedFromDate = new Date(fromDate.split('T')[0]);
          const reversedToDate = new Date(toDate.split('T')[0]);

          setSelectedFromDate(reversedFromDate);
          setSelectedToDate(reversedToDate);
        }

        const response = await axiosInstance.get(
          `v1/api-keys/${access}/services/hits`,
          {
            params: {
              fromDate,
              toDate,
            },
          },
        );

        if (response.status === 200) {
          // console.log('hiii111', response.data.data);
          const updatedData = response.data.data.map((item) => {
            return {
              totalByDay: item.totalByDay,
              totalServiceHits: item.totalServiceHits,
              successfulServiceHits: item.successfulServiceHits,
              service:
                item.service.charAt(0).toUpperCase() + item.service.slice(1),
              category:
                item.category.charAt(0).toUpperCase() +
                item.category.slice(1).toLowerCase(),
              totalCreditPoints: item.totalCreditPoints,
              total: item.totalCreditPoints * 50,
            };
          });
          const sortedData = updatedData.sort((a, b) =>
            a.service.localeCompare(b.service),
          );

          const grandTotal = updatedData.reduce(
            (totalSum, item) => totalSum + item.total,
            0,
          );
          setTotals(grandTotal);
          setHits(sortedData);
          setRealHits(updatedData);
        }
      } catch (error) {
        // Handle error if necessary
      }
    }
  };
  useEffect(() => {
    getAllData();
  }, [access, selectedOption]);
  const handleFromDateChange = (value) => {
    // Convert the Nepali date to an English date
    const nepaliDate = new NepaliDate(value);
    const englishDate = nepaliDate.toJsDate(); // This converts to a JavaScript Date object
    setSelectedFromDate(englishDate);
  };
  // console.log('selectedFromDate', selectedFromDate);

  const handleToDateChange = (value) => {
    // Convert the Nepali date to an English date
    const nepaliDate = new NepaliDate(value);
    const englishDate = nepaliDate.toJsDate(); // This converts to a JavaScript Date object
    setSelectedToDate(englishDate);
  };
  const handleOption4Click = async () => {
    setNewOption('option4');
    setButtonClicked(true);
    if (access) {
      try {
        let fromDate, toDate;

        fromDate = selectedFromDate;
        toDate = selectedToDate;
        // console.log('selectedFromDate', selectedFromDate);
        const response = await axiosInstance.get(
          `v1/api-keys/${access}/services/hits`,
          {
            params: {
              fromDate,
              toDate,
            },
          },
        );

        if (response.status === 200) {
          // console.log('hiii111', response.data.data);
          const updatedData = response.data.data.map((item) => {
            return {
              totalByDay: item.totalByDay,
              totalServiceHits: item.totalServiceHits,
              successfulServiceHits: item.successfulServiceHits,
              service:
                item.service.charAt(0).toUpperCase() + item.service.slice(1),
              category:
                item.category.charAt(0).toUpperCase() +
                item.category.slice(1).toLowerCase(),
              totalCreditPoints: item.totalCreditPoints,
              total: item.totalCreditPoints * 50,
            };
          });
          const sortedData = updatedData.sort((a, b) =>
            a.service.localeCompare(b.service),
          );

          const grandTotal = updatedData.reduce(
            (totalSum, item) => totalSum + item.total,
            0,
          );
          setTotals(grandTotal);
          setHits(sortedData);
          setRealHits(updatedData);
        }
      } catch (error) {
        // Handle error if necessary
      }
    }
  };
  useEffect(() => {
    handleOption4Click();
  }, [buttonClicked]);
  // console.log('totalHits', totalHits);
  useEffect(() => {
    const data = totalHits.find((item) => item.service === selectService);
    // setServiceData(data);
    if (data) {
      const lastMonth = data.totalByDay.map((entry) => {
        const dateObject = new Date(entry.date);
        const monthName = dateObject.toLocaleDateString(undefined, {
          month: 'short',
        });
        const dayOfMonth = dateObject.toLocaleDateString(undefined, {
          day: '2-digit',
        });
        return { ...entry, dayOfWeek: `${monthName} ${dayOfMonth}` };
      });
      setServiceData(lastMonth);
    } else {
      setServiceData([]);
    }
  }, [selectService, selectedOption, totalHits]);
  useEffect(() => {
    const finaldata = serviceData.map((item) => {
      const formattedDate = new NepaliDate(new Date(item.date));
      const newFormat = formattedDate.format(' DD MMMM', 'en');

      return {
        date: item.date,
        successfulHits: item.successfulHits,
        totalHits: item.totalHits,
        creditPoints: item.creditPoints,
        dayOfWeek: item.dayOfWeek,
        nepaliDate: newFormat,
      };
    });
    setNepaliDate(finaldata);
  }, [serviceData]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const handleFromDateChange = (bsDate) => {
  //   const desiredDate = new Date(bsDate.adDate);
  //   const oneDayBeforeDesiredDate = new Date(desiredDate);
  //   oneDayBeforeDesiredDate.setDate(desiredDate.getDate() - 1);
  //   const formattedDate = oneDayBeforeDesiredDate.toLocaleString('en-US', {
  //     weekday: 'short', // abbreviated day of the week
  //     month: 'short', // abbreviated month name
  //     day: '2-digit', // two-digit day of the month
  //     year: 'numeric', // four-digit year
  //     hour: '2-digit', // two-digit hour (24-hour clock)
  //     minute: '2-digit', // two-digit minute
  //     second: '2-digit', // two-digit second
  //     timeZoneName: 'short', // abbreviated time zone name
  //   });
  //   setSelectedFromDate(formattedDate);
  // };
  // console.log('selectedFromDate', selectedFromDate);
  // const handleToDateChange = (bsDate) => {
  //   const desiredDate = new Date(bsDate.adDate);
  //   const formattedDate = desiredDate.toLocaleString('en-US', {
  //     weekday: 'short', // abbreviated day of the week
  //     month: 'short', // abbreviated month name
  //     day: '2-digit', // two-digit day of the month
  //     year: 'numeric', // four-digit year
  //     hour: '2-digit', // two-digit hour (24-hour clock)
  //     minute: '2-digit', // two-digit minute
  //     second: '2-digit', // two-digit second
  //     timeZoneName: 'short', // abbreviated time zone name
  //   });
  //   setSelectedToDate(formattedDate);
  // };
  // console.log('selectedToDate', selectedToDate);
  const maxDate = new Date();
  const today = new Date();
  const dayBeforeYesterday = new Date();
  dayBeforeYesterday.setDate(today.getDate() - 2);

  const tableContainerStyle = {
    maxHeight: 600,
  };
  const customStyles = {
    datePicker: {
      // Style the DatePicker container
      width: '200px', // Adjust the width to your desired value
      height: '100px',
    },
    input: {
      // Style the input field
      color: 'blue', // Change text color to blue
      height: '100px', // Change the height of the input field
    },
  };

  const containerStyle = {
    display: 'flex',
    flex: 3,
    gap: '20px',
    border: '1px solid #ccc',
    marginTop: '2px',
    // height: '100px',
  };
  const customDatePickerStyle = {
    padding: '1rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '100px',
    height: '50px',
    color: 'red',
  };

  return (
    <div>
      <Dialog
        open={showUserDetail}
        onClose={toggleUserDetail}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='xl'
        fullWidth={true}
      >
        <Grid container>
          <Grid item xs={6}>
            <DialogTitle id='alert-dialog-title'>{`${alias}`}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                <p>User: {user.username}</p>
                <p>Email: {user.email}</p>
                <p>Contact :{user.contact_number}</p>
              </DialogContentText>
            </DialogContent>
            <DialogTitle id='alert-dialog-title'>
              {'Total sign services'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {services.map((service) => {
                  return (
                    <>
                      <p>Name: {service.name} </p>
                    </>
                  );
                })}
              </DialogContentText>
            </DialogContent>
            <DialogTitle id='alert-dialog-title'>
              {'Total successfull hit per services'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {realHits.map((serviceItem, index) => (
                  <div key={index}>
                    <p>
                      {serviceItem.service}: {serviceItem.successfulServiceHits}
                    </p>
                  </div>
                ))}
              </DialogContentText>
            </DialogContent>
            <DialogTitle id='alert-dialog-title'>
              {'Total  hit per services'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {totalHits.map((serviceItem, index) => (
                  <div key={index}>
                    <p>
                      {serviceItem.service}: {serviceItem.totalServiceHits}
                    </p>
                  </div>
                ))}
              </DialogContentText>
            </DialogContent>
          </Grid>
          <Grid item xs={6}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              centered
            >
              <Tab label='Total and successful hits' />
              <Tab label='Date and cost' />
              <Tab label='Service hit' />
            </Tabs>
            {/* Content for each tab */}
            {value === 0 && (
              <div>
                <Grid style={{ marginTop: '1rem' }}>
                  Total successful hit
                  <BarChart
                    width={500}
                    height={300}
                    data={realHits}
                    style={{ marginTop: 5 }}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='service' />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey='successfulServiceHits'
                      fill='#812c19'
                      name='Hit'
                    />
                  </BarChart>
                </Grid>
                <Grid style={{ marginTop: '1rem' }}>
                  Total hit per service
                  <BarChart
                    width={500}
                    height={300}
                    data={totalHits}
                    style={{ marginTop: 5 }}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='service' />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey='totalServiceHits'
                      fill='#812c19'
                      name='Hits'
                    />
                  </BarChart>
                </Grid>
              </div>
            )}
            {value === 1 && (
              <div>
                <>
                  <Box
                    border={0.5}
                    borderColor='#F1B4B4'
                    padding={2}
                    style={{ marginTop: '2rem' }}
                  >
                    <Box style={containerStyle}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          // marginBottom: '1rem',
                        }}
                      >
                        <Typography
                          style={{ fontSize: 22, marginRight: '1rem' }}
                        >
                          From:
                        </Typography>
                        <NepaliDatePicker
                          inputClassName='form-control'
                          className=''
                          onChange={handleFromDateChange}
                          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                        />
                        {/* <NepaliDatePicker
                          inputClassName='form-control'
                          className=''
                          // value={selected}
                          onChange={(value) => setSelectedFromDate(value)}
                          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                        /> */}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          // marginBottom: '1rem',
                        }}
                      >
                        <Typography
                          style={{ fontSize: 22, marginRight: '1rem' }}
                        >
                          To:
                        </Typography>
                        {/* <Calendar onChange={handleToDateChange} theme='blue' /> */}
                        {/* <NepaliDatePicker
                          inputClassName='form-control'
                          className=''
                          // value={selected}
                          onChange={(value) => setSelectedToDate(value)}
                          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                        /> */}
                        <NepaliDatePicker
                          inputClassName='form-control'
                          className=''
                          onChange={handleToDateChange}
                          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                        />
                      </div>

                      <Button
                        onClick={handleOption4Click}
                        variant='contained'
                        style={{
                          fontSize: '1rem',
                          minWidth: '100px',
                          margin: '0 0 10px 0',
                          backgroundColor: '#e37547',
                          marginTop: 10,
                        }}
                      >
                        Filter
                      </Button>
                    </Box>
                    <Box style={containerStyle}>
                      <Button
                        onClick={() => setSelectedOption('option1')}
                        variant='contained'
                        style={{
                          fontSize: '1rem',
                          minWidth: '100px',
                          margin: '0 0 10px 0',
                          backgroundColor: '#e37547',
                          marginTop: 10,
                        }}
                      >
                        This Week
                      </Button>
                      <Button
                        onClick={() => setSelectedOption('option2')}
                        variant='contained'
                        style={{
                          fontSize: '1rem',
                          minWidth: '100px',
                          margin: '0 0 10px 0',
                          backgroundColor: '#e37547',
                          marginTop: 10,
                        }}
                      >
                        This Month
                      </Button>
                      <Button
                        onClick={() => setSelectedOption('option3')}
                        variant='contained'
                        style={{
                          fontSize: '1rem',
                          minWidth: '100px',
                          margin: '0 0 10px 0',
                          backgroundColor: '#e37547',
                          marginTop: 10,
                        }}
                      >
                        Last 90 Days
                      </Button>
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <p style={{ fontSize: 20, marginTop: '1rem' }}>
                          Total credit points used on total request
                        </p>

                        <TableContainer
                          component={Paper}
                          style={tableContainerStyle}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>S.N</TableCell>
                                <TableCell>Service</TableCell>
                                {/* <TableCell>Category</TableCell> */}
                                <TableCell>Total hits</TableCell>
                                <TableCell>Credit point</TableCell>
                                {selectedOption === 'option1' ||
                                selectedOption === 'option2' ||
                                selectedOption === 'option3' ||
                                newOption === 'option4' ? (
                                  <TableCell>Cost</TableCell>
                                ) : null}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {totalHits.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>{row.service}</TableCell>
                                  {/* <TableCell>{row.category}</TableCell> */}
                                  <TableCell>
                                    {row.successfulServiceHits}
                                  </TableCell>
                                  <TableCell>
                                    {row.totalCreditPoints.toFixed(2)}
                                  </TableCell>
                                  {selectedOption === 'option1' ||
                                  selectedOption === 'option2' ||
                                  selectedOption === 'option3' ||
                                  newOption === 'option4' ? (
                                    <TableCell>
                                      {row.total.toFixed(2)}
                                    </TableCell>
                                  ) : null}
                                </TableRow>
                              ))}
                              {selectedOption === 'option1' ||
                              selectedOption === 'option2' ||
                              selectedOption === 'option3' ||
                              newOption === 'option4' ? (
                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  {/* <TableCell></TableCell> */}
                                  <TableCell></TableCell>
                                  <TableCell>Total:</TableCell>
                                  <TableCell>
                                    {typeof totals === 'number'
                                      ? totals.toFixed(2)
                                      : 'N/A'}
                                  </TableCell>
                                </TableRow>
                              ) : null}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              </div>
            )}
            {value === 2 && (
              <div>
                <Box style={containerStyle}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      // marginBottom: '1rem',
                    }}
                  >
                    <Typography style={{ fontSize: 22, marginRight: '1rem' }}>
                      From:
                    </Typography>
                    <NepaliDatePicker
                      inputClassName='form-control'
                      className=''
                      onChange={handleFromDateChange}
                      options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      // marginBottom: '1rem',
                    }}
                  >
                    <Typography style={{ fontSize: 22, marginRight: '1rem' }}>
                      To:
                    </Typography>
                    <NepaliDatePicker
                      inputClassName='form-control'
                      className=''
                      onChange={handleToDateChange}
                      options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                    />{' '}
                  </div>

                  <Button
                    onClick={handleOption4Click}
                    variant='contained'
                    style={{
                      fontSize: '1rem',
                      minWidth: '100px',
                      margin: '0 0 10px 0',
                      backgroundColor: '#e37547',
                      marginTop: 10,
                    }}
                  >
                    Filter
                  </Button>
                </Box>
                <Box style={containerStyle}>
                  <Button
                    onClick={() => setSelectedOption('option1')}
                    variant='contained'
                    style={{
                      fontSize: '1rem',
                      minWidth: '100px',
                      margin: '0 0 10px 0',
                      backgroundColor: '#e37547',
                      marginTop: 10,
                    }}
                  >
                    This Week
                  </Button>
                  <Button
                    onClick={() => setSelectedOption('option2')}
                    variant='contained'
                    style={{
                      fontSize: '1rem',
                      minWidth: '100px',
                      margin: '0 0 10px 0',
                      backgroundColor: '#e37547',
                      marginTop: 10,
                    }}
                  >
                    This Month
                  </Button>
                  <Button
                    onClick={() => setSelectedOption('option3')}
                    variant='contained'
                    style={{
                      fontSize: '1rem',
                      minWidth: '100px',
                      margin: '0 0 10px 0',
                      backgroundColor: '#e37547',
                      marginTop: 10,
                    }}
                  >
                    Last 90 Days
                  </Button>
                </Box>
                <Box
                  border={0.5}
                  borderColor='#F1B4B4'
                  padding={2}
                  style={{ marginTop: '2rem' }}
                >
                  <p style={{ fontSize: 25 }}>Services</p>
                  <Grid container alignItems='center' spacing={2}>
                    <Grid item>
                      <p style={{ fontSize: 15 }}>Select services</p>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <Select
                          labelId='service-label'
                          id='service-select'
                          value={selectService}
                          onChange={handleServiceChange}
                        >
                          {allServices.map((row, index) => (
                            <MenuItem
                              value={
                                row.name.charAt(0).toUpperCase() +
                                row.name.slice(1)
                              }
                              key={index}
                            >
                              {row.name.charAt(0).toUpperCase() +
                                row.name.slice(1)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent='center' alignItems='center'>
                    <Grid item xs={9}>
                      <Grid style={{ marginTop: '2rem' }}>
                        <p style={{ fontSize: 20 }}>{selectService}</p>
                        <BarChart
                          width={600}
                          height={400}
                          data={newNepalidate}
                          style={{ marginTop: 5 }}
                        >
                          <CartesianGrid strokeDasharray='3 3' />
                          <XAxis dataKey='nepaliDate' />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar
                            dataKey='successfulHits'
                            fill='#812c19'
                            name='Hits'
                          />
                        </BarChart>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            )}
          </Grid>
        </Grid>

        <DialogActions>
          <Button onClick={toggleUserDetail} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserDetail;
