import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Card, CardContent } from '@mui/material';

import Button from '@mui/material/Button';
import CreateRate from './CreateRate.tsx';
import axiosInstance from '../../../utils/axios';
import CreateFreeRate from './CreateFreeRate.js';
import Grid from '@mui/system/Unstable_Grid/Grid.js';
import { useAdminAuth } from '../../../hooks/useAdminAuth.js';

const Rate = () => {
  const [open, setOpen] = React.useState(false);
  const [rate, setRate] = useState([]);
  const [date, setDate] = useState('');
  const [newDetail, setDetail] = useState([]);
  const [showFreeRate, setShowFreeRate] = useState(false);
  const [freeRate, setFreeRate] = useState('');
  const toggleFreeRate = () => setShowFreeRate(!showFreeRate);
  const auth = useAdminAuth();
  useEffect(() => {
    const getAllData = async () => {
      const response = await axiosInstance('/v1/rates?limit=1');
      if (response.status === 200) {
        setRate(response.data.data.results);
      }
    };
    getAllData();
  }, []);
  useEffect(() => {
    const getAllData = async () => {
      const response = await axiosInstance('/v1/rates/free/active');
      if (response.status === 200) {
        setFreeRate(response.data.data);
      }
    };
    getAllData();
  }, []);
  console.log('freeRate', freeRate);
  useEffect(() => {
    const newDate = rate.map((rates) => {
      return rates.createdAt;
    });
    setDate(newDate);
  }, [rate]);

  console.log(date);

  useEffect(() => {
    const finalDetail = rate.map((rates) => {
      return rates.details;
    });
    setDetail(finalDetail);
  }, [rate]);
  console.log(newDetail);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const openFreeRate = () => {
    setShowFreeRate(true);
  };

  return (
    <div>
      {open ? (
        <CreateRate
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          rate={rate}
          setRate={setRate}
          handleClickOpen={handleClickOpen}
        />
      ) : null}
      {showFreeRate ? (
        <CreateFreeRate
          showFreeRate={showFreeRate}
          toggleFreeRate={toggleFreeRate}
        />
      ) : null}

      {auth && auth !== 'undefined' ? (
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Button variant='outlined' onClick={handleClickOpen}>
              Create Rate
            </Button>
          </Grid>
          <Grid item>
            <Button variant='outlined' onClick={openFreeRate}>
              Create Free Rate
            </Button>
          </Grid>
        </Grid>
      ) : null}

      <TableContainer style={{ border: '1px solid #ccc', marginTop: 20 }}>
        <Table style={{ minWidth: 650 }}>
          <TableHead style={{ backgroundColor: '#F4EFED ' }}>
            <TableRow>
              <TableCell>Request Minimum</TableCell>
              <TableCell>Request Maximum</TableCell>
              <TableCell> Rate Per Cp</TableCell>
              {/* <TableCell>CreatedAt</TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            <>
              <TableCell>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  style={{ backgroundColor: 'solid #ccc' }}
                >
                  Created at: {date}
                </Typography>
              </TableCell>

              {newDetail &&
                newDetail.map((item, index) => (
                  <>
                    {item.map((items, index) => (
                      <>
                        <TableRow key={index}>
                          <TableCell>{items.minimum_cp}</TableCell>
                          <TableCell>{items.maximum_cp}</TableCell>
                          <TableCell>{items.rate_per_cp}</TableCell>
                        </TableRow>
                      </>
                    ))}
                  </>
                ))}

              <TableRow>
                <TableCell colSpan={5}>
                  <hr
                    style={{
                      height: '2px',
                      backgroundColor: 'solid #ccc',
                      border: 'none',
                    }}
                  />
                </TableCell>
              </TableRow>
            </>
          </TableBody>
        </Table>
      </TableContainer>

      {auth && auth !== 'undefined' ? (
        <Card
          style={{
            marginTop: 20,
            border: '1px solid #ccc', // Add border styling
            borderRadius: '8px', // Rounded corners
            position: 'relative', // For positioning the createdAt text
            width: '300px',
          }}
        >
          <CardContent>
            <Typography variant='h6'>
              Free Credit Point: {freeRate.credit_points}
            </Typography>
            <div
              style={{
                // position: 'absolute',
                top: '5px', // Adjust the vertical position
                right: '5px', // Adjust the horizontal position
                fontSize: '12px', // Adjust the font size
                color: '#666', // Adjust the text color
              }}
            >
              created at {freeRate.createdAt}
            </div>
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
};

export default Rate;
