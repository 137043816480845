import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AddCreditPoint = ({creditId,toggle,show,users,setUsers}) => {
  const [amount, setAmount] = useState('');
  const [points,setCreditPoints]=useState('');
  const [isLoading,setIsLoading]=useState(false);
  const [errors, setErrors] = useState([]);

  // useEffect(()=>{

  // },[])

  const handleAmountChange = async (event) => {
    const value = event.target.value;
    setAmount(value);

    // Make an API call to calculate credit points
    const response = await axiosInstance.post("/v1/calculate-credits", { amount: value });
   console.log(response);
    setCreditPoints(response.data.data.total);
  };
    
    const handleSubmit = () => {
        axiosInstance.post(`/v1/users/${creditId}/credit-points/`, {  points,amount ,method:"admin"}).then(resp => {
         console.log(resp)
          setIsLoading(true);
          if (resp.status===200) {
            setIsLoading(false);
    
            const updatedUsers = [...users];
            const index = updatedUsers.findIndex(i=>i._id===resp.data.data._id)
            let {_id,username,email,role,credit_points,createdAt,updatedAt,payment_type}=resp.data.data;
            const newUsers = {
              _id:_id,
              username: username,
              email:email,
              role: role,
              credit_points:credit_points,  
              createdAt:createdAt,
              updatedAt:updatedAt,
              payment_type:payment_type
             
    
            }
            updatedUsers.splice(index,1,newUsers);
            setUsers(updatedUsers);
            toggle();
            // window.location.reload();
            toast.success('Credit points added successfully')
            axiosInstance.post('/v1/payments/',{username,amount,credit_points:points,type:'admin',email,user:_id})
          }
        }).catch(errors => {
          // console.log(errors.response.data.message);
          if (errors) {
            setIsLoading(false);
    
            setErrors(errors.response.data.message)
          }
    
        })
      }

  return (
    <div>
    <Dialog
     open={show}
     TransitionComponent={Transition}
     keepMounted
     onClose={toggle}
     aria-describedby="alert-dialog-slide-email"
   >
     <DialogTitle>{"Add Credit Points"}</DialogTitle>
     {isLoading?<Box style={{position: 'relative'}}>
        <CircularProgress
        size={40}
        left={-20}
        top={10}
        status={'loading'}
        style={{marginLeft: '50%'}}
        />
      </Box>:<>
       <DialogContent>
       <DialogContentText id="alert-dialog-slide-email">
       </DialogContentText>

         <Box
           sx={{
             display: 'flex',
             alignItems: 'center',
             '& > :not(style)': { m: 2 },
           }}
         >
           <FormControl>
             {errors.map(error => {
               return <Alert severity="error">{error.message}</Alert>

             })}
              <TextField
               placeholder='Enter amount'
               type="number"
               label="Amount"
               value={amount}
               onChange={handleAmountChange}
              />
              <br />


             {/* <TextField
               placeholder='Enter credit points'
               label="Credit Points"
               id="demo-helper-text-aligned-no-helper"
               value={points}
               onChange={(e) => setCreditPoints(e.target.value)}
               disabled={true}

             /> */}
             <p>Credit points:  <span style={{ fontWeight: 'bold' }}>{points}</span>  </p>  
            
            
           </FormControl>


         </Box>
     </DialogContent>
     <DialogActions>
       <Button onClick={handleSubmit}>Add credit point</Button>
       <Button onClick={toggle}>Cancel</Button>
     </DialogActions>
     </>
     }
    
   </Dialog>
 </div>
  )
}

export default AddCreditPoint