import jwt_decode from 'jwt-decode';

export const useEmployeeAuth = () => {
  const token = window.localStorage.getItem('galli_token');
  const decoded = jwt_decode(token);
  // console.log(decoded);
  if (decoded.role === 'employee') {
    return true;
  } else {
    return false;
  }
};
