import React from 'react'
import {  toast } from 'react-toastify';
import axiosInstance from '../../../utils/axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const RegenerateModal = ({keys,setKeys,toggleRegenerateModal,showRegenerateModal,regenerate_id}) => {
    const regenetate =()=>{
      axiosInstance.patch(`/v1/api-keys/${regenerate_id}/regenerate/`).then(resp=>{
        console.log(resp.data.data);
        if (resp.status===200){
          toggleRegenerateModal();
          toast.success('Access key regenerated')
          const newkeys =[...keys];
          const index = newkeys.findIndex(i=>i._id===resp.data.data._id)
  
          const newall ={
            _id:resp.data.data._id,
            access_key:resp.data.data.access_key,
            alias:resp.data.data.alias,
            counter:resp.data.data.counter,
            domain_name:resp.data.data.domain_name,
            expire:resp.data.data.expire,    
            max_hit:resp.data.data.max_hit,
            updatedAt:resp.data.data.updatedAt,
            createdAt:resp.data.data.createdAt,
  
          }
          newkeys.splice(index,1,newall);
          setKeys(newkeys);
  
  
        }
      })
    }
  return (
    <div> <Dialog
    open={showRegenerateModal}
    onClose={toggleRegenerateModal}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"Regenrate api key"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Are you sure want to regenerate
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={regenetate}>Confirm</Button>
      <Button onClick={toggleRegenerateModal} autoFocus>
        Cancel
      </Button>
    </DialogActions>
  </Dialog></div>
  )
}

export default RegenerateModal