import React, { useState,useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl,InputLabel, MenuItem, Select,SelectChangeEvent, Tabs } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { useFetch } from '../../../hooks/useFetch';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// import moment from 'moment'
import moment from "moment";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));



function getStepContent(step) {
  switch (step) {
    case 0:
      return "step 1";
    case 1:
      return 'Step 2';
    case 2:
      return 'Step 3';
    default:
      return 'Unknown step';
  }
}

const CreateApiKey = ({ open, handleClose, keys, setKeys }) => {
  const [alias, setAlias] = useState('');
  const [domain_name, setDomainName] = useState('');
  const [max_hit, setMaxHit] = useState(20000);
  const [errors, setErrors] = useState([]);
  const [checked, setChecked] = useState(false);
  const [expiration_date, setExpirationDate] = useState();
  const [isLoading,setIsLoading]=useState(false);
  const [services,setServices]=useState([]);
  const {data}=useFetch(services,setServices,'/v1/services')
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = ['Create an api key for customer', 'Select the services for customer', 'Complete'];
  const  [users,setUser]=useState([]);
  const [value, setValue] = useState('');
  const [tabId,setTabId]=useState("1");
  const [showSub,setShowSub]=useState(false)
  const [selectChild,setSelectchild]=useState([])
 
  useEffect(()=>{
    const getUser = async()=>{
      const response =await axiosInstance('/v1/users/');
      if(response.status===200){
        setUser(response.data.data)
      }

    }
    getUser()
  },[])

  const handleChanges = (event: React.SyntheticEvent, newValue: string) => {
    setTabId(newValue);
  };
  const isStepOptional = (step) => {
    return step === 1;
  };
  // const handleExpiryDateChange = (date) => {
  //   setExpirationDate(date);
  // };
  useEffect(()=>{
    const defaultDate = new Date();
  defaultDate.setFullYear(defaultDate.getFullYear() + 1);  
  var parsedDate = moment(defaultDate, "M/D/YYYY");
  var formattedDate = parsedDate.format("YYYY-MM-DDTHH:mm");
  setExpirationDate(formattedDate);
    
  },[1])

  
  useEffect(()=>{
    setSelectedItems(services);
  },[1])
  
  

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };



  

  const handleChange = () => {
    setChecked(!checked);

  }
  
  const handleItemClick = (datas) => {
    console.log("datani",datas)
    const index = selectedItems.indexOf(datas);
    
    if (index > -1) {
      // Item already selected, remove it from the array
      setSelectedItems(selectedItems.filter((i) => i !== datas));
      setTotalSum(totalSum - datas.rate );
      setShowSub(false)
      

    } else {
      
      // Item not selected, add it to the array
      setSelectedItems([...selectedItems, datas]);
      setTotalSum(totalSum + datas.rate );
      setShowSub(true)

    }    
  };
 
  // console.log('selectedItems',selectedItems)
  // console.log('totalsum',totalSum)



  const handleSubmit = () => {
    axiosInstance.post('/v1/api-keys/', { alias, domain_name, expiration_date,user:value,services:services }).then(resp => {
      setIsLoading(true);
      if (resp.status===201) {
        setIsLoading(false);

        let{alias,domain_name,max_hit,counter,expire,_id,createdAt,updatedAt,access_key,expiration_date}=resp.data.data
        const newKey = [...keys, {
          alias:alias,
          domain_name:domain_name,
          max_hit:max_hit,
          counter: counter,
          expire: expire,
          _id: _id,
          createdAt: createdAt,
          updatedAt:updatedAt,
          expiration_date:expiration_date,
          access_key: access_key

        }]
        setKeys(newKey);
        handleClose();
        toast.success('Api Keys created successfully')
        axiosInstance.post('/v1/payments/', { alias, amount:totalSum,type:"admin" }).then(resp => {
          console.log(resp)

        })
        
      }
    }).catch(errors => {
      // console.log(errors.response.data.message);
      if (errors) {
        setIsLoading(false);

        setErrors(errors.response.data.message)
      }

    })
  }
  return (
    <div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Create Api Key"}</DialogTitle>
        {isLoading?<Box style={{position: 'relative'}}>
           <CircularProgress
           size={40}
           left={-20}
           top={10}
           status={'loading'}
           style={{marginLeft: '50%'}}
           />
         </Box>:<>
          <DialogContent>
          <div className={classes.root}>
         <Stepper activeStep={activeStep}>
           {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          // if (isStepOptional(index)) {
          //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
          // }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              
              <StepLabel {...labelProps}>{label}
                
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                '& > :not(style)': { m: 2 },
              }}
            >
             
             <FormControl>
                {errors.map(error => {
                  return <Alert severity="error">{error.message}</Alert>

                })}

           


          {activeStep === 0 && (

                <>
                <Grid container spacing={4}>
               <Grid item xs={6} md={6}>
               <TextField
                        placeholder="Enter  Alias"
                        id="demo-helper-text-aligned"
                        label="Alias"
                        value={alias}
                        onChange={(e) => setAlias(e.target.value)}
                        />
                        <br/>
                        <br/>

                        <TextField
                        placeholder="Enter Domain Name "
                        id="demo-helper-text-aligned-no-helper"
                        label="Domain Name"
                        value={domain_name}
                        onChange={(e) => setDomainName(e.target.value)}
                        />
                  <br/>
                  <br/>
                        
                <label>Select User</label>
                <br/>
                <Select
                id="demo-simple-select"
                value={value}
                 onChange={(event: SelectChangeEvent) => {
                    console.log(event.target.value)
                    setValue(event.target.value);
                  }}
                  fullWidth
                >
                    {users.map(user=>{
                        return <MenuItem value={user._id}>{user.username}</MenuItem>
                        
                    })}
           
                </Select>

               
               </Grid>
              <Grid item xs={6}>
                  <TextField
                    id="datetime-local"
                    label="Expiry date"
                    type="datetime-local"
                    // disabled
                    // defaultValue={formattedDate}                   
                    value={expiration_date}
                    onChange={(e) => setExpirationDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /> 
              
              </Grid>
              </Grid>
                       
                           {/* <FormControlLabel 
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label="Select date"
                    /> */}
                                      <br/>


           
                
                {/* {checked ? <>
                  <TextField
                    helperText="Please enter Maximum Hit "
                    id="demo-helper-text-aligned-no-helper"
                    label="Maximum hit"
                    value={max_hit}
                    onChange={(e) => setMaxHit(e.target.value)}

                  />
                  <TextField
                    id="datetime-local"
                    helperText="Add expiry date "
                    type="datetime-local"
                    defaultValue="2017-05-24T10:30"
                    value={expiration_date}
                    onChange={(e) => setExpirationDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                </> : null
                } */}

                </>          
        )}

        {activeStep===1 &&(
        <>
         {/* <label>Select the services</label> */}
         <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabId}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChanges} aria-label="lab API tabs example">
                      <Tab label="Select services" value="1" />
                      {/* <Tab label="Select the plans" value="2" /> */}
                      {/* <Tab label="Item Three" value="3" /> */}
                    </TabList>
                  </Box>
                  <TabPanel value="1">

                  {data.map(datas=>{
                  return <>
                   <FormControlLabel 
                    control={
                      <Checkbox
              
                        checked
                        // onChange={()=>handleItemClick(datas)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                  
                      
                    }
                    label={datas.name} 
                    
                    />
                    <br/>
                    <div style={{marginLeft:20}}>
                    {selectedItems.includes(datas)?<>{datas.sub_services.map(sub=>{
                      return <><FormControlLabel 
                      control={
                        <Checkbox
                        checked
 
                        inputProps={{ "aria-label": "primary checkbox" }}
                        />
                        
                      }
                      label={sub.name} 
                      
                      />
                       <br/>
                      </>
                    })}
                    </>:null}

                   
                   
                    
                    </div>
                  
                    
                  
                  </>
                })}
                <br/>
                  {/* <Grid container  alignItems="center">
                  Total :
                  <Grid item>
                  <label>
                     <h1>{totalSum}</h1>
                    </label>
                  </Grid>
                 </Grid> */}

                  </TabPanel>
                  {/* <TabPanel value="2">Item Two</TabPanel>
                  <TabPanel value="3">Item Three</TabPanel> */}
                </TabContext>
    </Box>
               
                
                
        </>
        )}

      </FormControl>

            </Box>
      
      <div>
        {activeStep === steps.length ? (
          <div>
             <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
            <Button onClick={handleSubmit}>Create an api </Button>

            
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? 'Complete' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
        
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
        </>
        }
       
      </Dialog>
    </div>
  )
}

export default CreateApiKey