import React, { useState } from 'react';
import { useEmployeeFetch } from '../../../../hooks/useEmployeeFetch';
import { Tab, Tabs, Paper } from '@mui/material';
import Request from './Request';
import Inspect from './Inspect';
import Verified from './Verified';
import Rejected from './Rejected';

const AddBusinessRequest = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const token = `${process.env.REACT_APP_ACCESS_TOKEN}`;
  const [requests, setRequests] = useState([]);
  const [verified, setVerified] = useState([]);
  const [inspect, setInspect] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [showdetail, setDetail] = useState(false);
  const toggle = () => setDetail(!showdetail);

  const {
    requestData,
    handlePageChange,
    totalPages,
    currentPage,
    isLoading,
    totalResults,
  } = useEmployeeFetch(
    requests,
    setRequests,
    `business/getValidationNeededBusiness?accessToken=${token}`,
    10,
  );
  const {
    verifiedData,
    handlePageChange: handleVerifiedPageChange,
    totalPages: totalVerifiedPages,
    currentPage: currentVerifiedPage,
    isLoading: isLoadingVerified,
    totalResults: totalVerifiedResults,
  } = useEmployeeFetch(
    verified,
    setVerified,
    `business/getAllactiveBusiness?accessToken=${token}`,
    10,
  );
  const {
    inspectData,
    handlePageChange: handleInspectPageChange,
    totalPages: totalInspectPages,
    currentPage: currentInspectPage,
    isLoading: isLoadingInspect,
    totalResults: totalInspectResults,
  } = useEmployeeFetch(
    inspect,
    setInspect,
    `business/getInquired?accessToken=${token}`,
    10,
  );
  const {
    rejectedData,
    handlePageChange: handleRejectedPageChange,
    totalPages: totalRejectedPages,
    currentPage: currentRejectedPage,
    isLoading: isLoadingRejected,
    totalResults: totalRejectedResults,
  } = useEmployeeFetch(
    rejected,
    setRejected,
    `business/getAllRemovedBusiness?accessToken=${token}`,
    10,
  );
  const roundedNumberStyle = {
    backgroundColor: '#e37547',
    borderRadius: '50%',
    padding: '0.1em 0.3em',
    color: 'white',
    marginLeft: '0.5em',
  };
  const capitalizeStyle = {
    textTransform: 'capitalize',
  };
  // console.log('requests', requests);
  return (
    <div>
      <p style={{ fontSize: '20px' }}>Add Business Request</p>
      <Paper elevation={3} style={{ marginTop: '1rem' }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor='primary'
          textColor='primary'
          // centered
        >
          <Tab
            label={
              <span className='Request'>
                Request
                <span style={roundedNumberStyle}>{totalResults}</span>
              </span>
            }
            style={capitalizeStyle}
          />
          <Tab
            label={
              <span className='Request'>
                Verified
                <span style={roundedNumberStyle}>{totalVerifiedResults}</span>
              </span>
            }
            style={capitalizeStyle}
          />
          <Tab
            label={
              <span className='Request'>
                Inspect
                <span style={roundedNumberStyle}>{totalInspectResults}</span>
              </span>
            }
            style={capitalizeStyle}
          />
          <Tab
            label={
              <span className='Request'>
                Rejected
                <span style={roundedNumberStyle}>{totalRejectedResults}</span>
              </span>
            }
            style={capitalizeStyle}
          />
        </Tabs>

        {selectedTab === 0 && (
          <div>
            <Request
              requests={requests}
              setRequests={setRequests}
              showdetail={showdetail}
              setDetail={setDetail}
              toggle={toggle}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
              isLoading={isLoading}
              currentPage={currentPage}
            />
          </div>
        )}
        {selectedTab === 1 && (
          <div>
            <Verified
              verified={verified}
              setVerified={setVerified}
              showdetail={showdetail}
              setDetail={setDetail}
              toggle={toggle}
              handleVerifiedPageChange={handleVerifiedPageChange}
              totalVerifiedPages={totalVerifiedPages}
              currentVerifiedPage={currentVerifiedPage}
            />
          </div>
        )}
        {selectedTab === 2 && (
          <div>
            <Inspect
              inspect={inspect}
              setInspect={setInspect}
              showdetail={showdetail}
              setDetail={setDetail}
              toggle={toggle}
              handleInspectPageChange={handleInspectPageChange}
              totalInspectPages={totalInspectPages}
              currentInspectPage={currentInspectPage}
            />
          </div>
        )}
        {selectedTab === 3 && (
          <div>
            <Rejected
              rejected={rejected}
              setRejected={setRejected}
              showdetail={showdetail}
              setDetail={setDetail}
              toggle={toggle}
              handleRejectedPageChange={handleRejectedPageChange}
              totalRejectedPages={totalRejectedPages}
              currentRejectedPage={currentRejectedPage}
            />
          </div>
        )}
      </Paper>
    </div>
  );
};

export default AddBusinessRequest;
