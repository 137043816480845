import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import axiosInstance from '../../../utils/axios';
import { toast } from 'react-toastify';

const Reply = ({ open, onClose, setShowDialog, email, name }) => {
  const [editorContent, setEditorContent] = useState('');

  const handleSend = () => {
    // Call the onSend function and pass the editor content
    axiosInstance
      .post('v1/replyContact', { email, content: editorContent, name })
      .then((resp) => {
        if (resp.status === 200) {
          setShowDialog(false);
          toast.success('Mail send successfully');
        }
      });
    console.log(editorContent);
  };

  const handleCancel = () => {
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth='lg'
      fullWidth
      style={{ minHeight: '600px' }}
    >
      <DialogTitle>Compose a reply to {email}</DialogTitle>
      <DialogContent>
        <div className='mail-editor'>
          <CKEditor
            editor={ClassicEditor}
            data={editorContent}
            config={{
              // Add the extraDataStyle option to set custom styles to the editor content area.
              extraDataStyle: 'min-height: 800px; font-size: 30px;',
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEditorContent(data);
            }}
          />
        </div>
      </DialogContent>
      <div
        className='mail-actions'
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px',
          marginTop: '10px',
        }}
      >
        <Button
          style={{ backgroundColor: '#e37547', color: 'white' }}
          onClick={handleSend}
        >
          Send
        </Button>
        <Button variant='contained' onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

export default Reply;
