import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import Button from '@mui/material/Button';
import Detail from './Detail';
import { Pagination } from '@mui/material';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const Verified = ({
  verified,
  setVerified,
  showdetail,
  setDetail,
  toggle,
  handleVerifiedPageChange,
  totalVerifiedPages,
  currentVerifiedPage,
}) => {
  const [detailData, setDetailData] = useState([]);
  const showall = (row) => {
    // console.log('row', row);
    const newRow = { ...row, fromverify: true };

    setDetail(true);
    setDetailData(newRow);
  };
  // console.log('totalVerifiedPages', currentPage2);
  const tableContainerStyle = {
    maxHeight: 610, // Set the maximum height as needed
    overflowY: 'auto',
  };
  // console.log('handlePageChange2', verified);
  const baseUrl = `${process.env.REACT_APP_EMPLOYEE_URL}`;

  return (
    <div>
      {showdetail ? (
        <Detail
          showdetail={showdetail}
          detailData={detailData}
          toggle={toggle}
        />
      ) : null}
      <TableContainer component={Paper} style={tableContainerStyle}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.N</TableCell>
              <TableCell>Business name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Office/Store</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell>More Info</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {verified.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  {index + 1 + (currentVerifiedPage - 1) * 10}
                </TableCell>
                <TableCell>{row.businessName}</TableCell>
                <TableCell>{row.category}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>{row.contactInfo}</TableCell>
                <TableCell>{row.place_office_store ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  {row.photos !== null && row.photos !== undefined ? (
                    <>
                      {JSON.parse(row.photos).length > 0 ? (
                        <Zoom>
                          <img
                            style={{ width: '10rem' }}
                            src={
                              baseUrl +
                              'images/' +
                              JSON.parse(row.photos)[0].image
                            }
                          />
                        </Zoom>
                      ) : (
                        'N/A'
                      )}
                    </>
                  ) : (
                    'N/A'
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={(e) => showall(row)}
                    style={{ width: '140px', height: '35px' }}
                  >
                    View more
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalVerifiedPages}
        page={currentVerifiedPage}
        onChange={handleVerifiedPageChange}
        color='primary'
      />
    </div>
  );
};

export default Verified;
