import { useState,useEffect } from "react";
import axiosInstance from "../utils/axios";

export const useFetch=(data,setData,url)=>{
    const [isLoading,setIsLoading]=useState(false);
    const token = window.localStorage.getItem("galli_token");

    useEffect(()=>{
        setIsLoading(true);
        const getAllData =async()=>{
          const response =await axiosInstance(url);
          if (response.status ===200){
            setData(response.data.data)
            setIsLoading(false)
          }
    
        }
        if (token){
          getAllData()

        }
    
      },[setData])

      return {data,isLoading};
    
}