import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { usePaginate } from '../../../hooks/usePagintation';
import { Pagination } from '@mui/material';
import CreateUser from './CreateUser.tsx';
import Button from '@mui/material/Button';
import { useUserSearch } from '../../../hooks/useUserSearch';
import Input from '@mui/material/Input';
import { useFetch } from '../../../hooks/useFetch';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteModal from './DeleteModal';
import { AiFillDelete } from 'react-icons/ai';
import UpdateModal from './UpdateModal.tsx';
import { GrDocumentUpdate, GrAdd } from 'react-icons/gr';
import AddCreditPoint from './AddCreditPoint.tsx';
import { useToggle } from '../../../hooks/useToggle';
import { CgArrowsExchangeV } from 'react-icons/cg';
import { MdOutlineProductionQuantityLimits } from 'react-icons/md';
import ShowPaymentType from './ShowPaymentType';
import { MdVerifiedUser } from 'react-icons/md';
import axiosInstance from '../../../utils/axios';
import { toast } from 'react-toastify';
import AddLimit from './AddLimit';

const User = () => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const [delete_id, setDeleteId] = useState([]);
  const { data, isLoading } = useFetch(users, setUsers, '/v1/users/');
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const toggleShowModal = () => setShowUpdateModal(!showUpdateModal);
  const [update_id, setUpdateId] = useState([]);
  const [show, toggle, setShow] = useToggle();
  const [creditId, setCreditId] = useState('');
  const [showPaymentType, setPaymentType] = useState(false);
  const toggleShowPaymentType = () => setPaymentType(!showPaymentType);
  const [typeId, setTypeId] = useState('');
  const [showAddLimit, setShowAddLimit] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { totalPages, currentPage, searchResult, handlePageChange } =
    useUserSearch(users, searchTerm, 10);

  // const { totalPages, currentPage, pageData, handlePageChange } = usePaginate(
  //   searchResult,
  //   10,
  // );

  const deleteUser = (user) => {
    setShowDeleteModal(true);
    setDeleteId(user);
  };

  const updateUser = (user) => {
    setUpdateId(user);
    setShowUpdateModal(true);
  };
  const addCreditPoints = (user) => {
    setCreditId(user._id);
    setShow(true);
  };

  const changePaymentType = (user) => {
    setPaymentType(true);
    setTypeId(user._id);
  };
  const verifyEmail = (user) => {
    axiosInstance.put(`v1/verify-Email-Admin/${user._id}`).then((resp) => {
      if (resp.status === 200) {
        toast.success('Email verified');
        // window.location.reload();
        const verifiedUser = users.map((key) => {
          if (key._id === user._id) {
            return { ...key, email_verified: true };
          }
          return key;
        });
        setUsers(verifiedUser);
      }
    });
  };

  const addLimit = (user) => {
    setShowAddLimit(true);
  };

  return (
    <>
      {open ? (
        <CreateUser
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          users={users}
          setUsers={setUsers}
          handleClickOpen={handleClickOpen}
        />
      ) : null}
      {showDeleteModal ? (
        <DeleteModal
          toggleDeleteModal={toggleDeleteModal}
          delete_id={delete_id}
          users={users}
          setUsers={setUsers}
          showDeleteModal={showDeleteModal}
        />
      ) : null}

      {showUpdateModal ? (
        <UpdateModal
          users={users}
          setUsers={setUsers}
          showUpdateModal={showUpdateModal}
          toggleShowModal={toggleShowModal}
          update_id={update_id}
        />
      ) : null}

      {show ? (
        <AddCreditPoint
          creditId={creditId}
          show={show}
          toggle={toggle}
          users={users}
          setUsers={setUsers}
        />
      ) : null}

      {showPaymentType ? (
        <ShowPaymentType
          users={users}
          setUsers={setUsers}
          showPaymentType={showPaymentType}
          toggleShowPaymentType={toggleShowPaymentType}
          typeId={typeId}
        />
      ) : null}
      {showAddLimit ? (
        <AddLimit
          users={users}
          setUsers={setUsers}
          showAddLimit={showAddLimit}
          setShowAddLimit={setShowAddLimit}
        />
      ) : null}
      <Button variant='outlined' onClick={handleClickOpen}>
        Create user
      </Button>
      <Input
        type='search'
        className='form-control'
        style={{ width: 300, float: 'right' }}
        placeholder='Search by email '
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {isLoading ? (
        <Box style={{ position: 'relative' }}>
          <CircularProgress
            size={40}
            left={-20}
            top={10}
            status={'loading'}
            style={{ marginLeft: '50%' }}
          />
        </Box>
      ) : (
        <>
          <TableContainer
            style={{
              border: '1px solid #ccc',
              marginTop: 20,
              overflowX: 'auto',
            }}
          >
            <Table style={{ minWidth: 650 }} size='small'>
              <TableHead style={{ backgroundColor: '#F4EFED ' }}>
                <TableRow>
                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    User Name
                  </TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    Payment Type
                  </TableCell>
                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    CP
                  </TableCell>
                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    Limit
                  </TableCell>

                  <TableCell> Delete</TableCell>
                  <TableCell> Update</TableCell>
                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    Add CP
                  </TableCell>
                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    Set Plan
                  </TableCell>
                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    Verified
                  </TableCell>
                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    User Type
                  </TableCell>

                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    Created At
                  </TableCell>
                  <TableCell
                    style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                  >
                    Updated At
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchResult.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell
                      style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                    >
                      {user.username}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>{user.payment_type}</TableCell>
                    <TableCell>{user.paid_credit_points}</TableCell>
                    <TableCell>{user.limit_credits}</TableCell>

                    <TableCell>
                      <Button>
                        <AiFillDelete
                          onClick={(e) => deleteUser(user)}
                          style={{
                            fontSize: '1.4rem',
                            cursor: 'pointer',
                            color: 'red',
                          }}
                          title='Delete'
                        />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button>
                        <GrDocumentUpdate
                          onClick={(e) => updateUser(user)}
                          style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                          title='Update'
                        />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button>
                        <GrAdd
                          onClick={(e) => addCreditPoints(user)}
                          style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                          title='Add Credit Point'
                        />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button>
                        <CgArrowsExchangeV
                          onClick={(e) => changePaymentType(user)}
                          style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                          title='Change Payment type'
                        />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button>
                        <MdVerifiedUser
                          onClick={(e) => verifyEmail(user)}
                          style={{
                            fontSize: '1.4rem',
                            cursor: 'pointer',
                            color: user.email_verified ? 'green' : 'grey',
                          }}
                          title='Update Email verified'
                        />
                      </Button>
                    </TableCell>

                    <TableCell>{user.user_type}</TableCell>

                    <TableCell
                      style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                    >
                      {user.createdAt && user.createdAt !== 'undefined'
                        ? user.createdAt.split('T')[0]
                        : ''}
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
                    >
                      {user.updatedAt && user.updatedAt !== 'undefined'
                        ? user.updatedAt.split('T')[0]
                        : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color='primary'
          />
        </>
      )}
    </>
  );
};

export default User;
