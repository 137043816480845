import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import{Typography} from '@mui/material';


const Buy = ({toggle,show}) => {
  return (
    <div>
        <Dialog
    open={show}
    onClose={toggle}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"Add Credit Points"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
      <Typography variant="body1" >
      To add credit points, kindly reach out to our dedicated sales team.      You can contact our friendly sales representative using the following contact details,

    </Typography>
    {/* <Typography variant="body1">
    </Typography> */}
   <div style={{textAlign:"center"}}>
   <Typography variant="body1" style={{marginTop:"1.2rem"}}>
      📞 Mobile: +977 9841702323
    </Typography>
    
    <div style={{ marginBottom: '8px' ,textAlign:"center"}}>
            <Typography variant="body1" style={{ fontSize: '1.2rem' }}>
            <span style={{ marginRight: '8px', fontSize: '1.5rem' }}>✉️</span>sales@gallimaps.com
            </Typography>
          </div>
   </div>
    <Typography variant="body1" style={{marginTop:"1rem"}}>
      Our team will be delighted to assist you. Feel free to get in touch with us at your convenience. We look forward to hearing from you!
    </Typography>
    </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={toggle} autoFocus>
        Close
      </Button>
    </DialogActions>
  </Dialog>
    </div>
  )
}

export default Buy