import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import employeeBase from '../../../../utils/employee';

const VerifyBusiness = ({
  showVerfyBusiness,
  toggleVerifyBusiness,
  detailData,
}) => {
  const token = `${process.env.REACT_APP_ACCESS_TOKEN}`;
  //   console.log('detailData', detailData._id);
  const confirm = () => {
    employeeBase
      .post(`business/activateBusiness/${detailData._id}?accessToken=${token}`)
      .then((resp) => {
        if (resp) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <Dialog
        open={showVerfyBusiness}
        onClose={toggleVerifyBusiness}
        // maxWidth='md'
        fullWidth
      >
        <DialogTitle>Verify the business</DialogTitle>
        <DialogContent>
          Are you sure want to verify the business for the addition of places in
          the map?
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleVerifyBusiness}>Cancel</Button>
          <Button onClick={confirm} color='primary'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VerifyBusiness;
