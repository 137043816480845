import React, { useState } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { MdUpdate } from 'react-icons/md';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteModal from './DelateModal';
import { AiFillDelete } from 'react-icons/ai';
import CreateApiKey from './CreateApiKey.tsx';
import RegenerateModal from './RegenerateModal';
import { GrUpdate } from 'react-icons/gr';
import { useToggle } from '../../../hooks/useToggle';
import Buy from './Buy.tsx';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import UpdateDomainName from './UpdateDomainName.js';
import { BiRefresh } from 'react-icons/bi';

const CApiKey = () => {
  const [open, setOpen] = React.useState(false);
  const [keys, setKeys] = useState([]);
  const [delete_id, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const [showRegenerateModal, setRegenerateModal] = useState(false);
  const toggleRegenerateModal = () => setRegenerateModal(!showRegenerateModal);
  const [regenerate_id, setRegenerateId] = useState();
  const { data, isLoading } = useFetch(keys, setKeys, '/v1/getLoginUserApikey');
  const [user, setUser] = useState([]);
  const {} = useFetch(user, setUser, '/v1/getLoginUser');
  const [show, toggle, setShow] = useToggle();
  const [isAccessKeyVisible, setAccessKeyVisible] = useState(true);
  const [showUpdateDomainName, setShowUpdateDomainName] = useState(false);
  const [update_domain, setUpdateDomain] = useState([]);
  const toggleUpdateDomainName = () =>
    setShowUpdateDomainName(!showUpdateDomainName);

  const handleAccessKeyVisibility = () => {
    setAccessKeyVisible(!isAccessKeyVisible);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteKey = (_id) => {
    setShowDeleteModal(true);
    setDeleteId(_id);
    // console.log('_id',_id)
  };
  // console.log('data',keys)
  const regenerateApiKey = (_id) => {
    // console.log('_id',_id)
    setRegenerateId(_id);
    setRegenerateModal(true);
  };
  // console.log('user',user.credit_points);

  const showBuy = () => {
    setShow(true);
  };

  const updateDomain = (key) => {
    setShowUpdateDomainName(true);
    setUpdateDomain(key);
  };
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {/* {user.payment_type === 'pre-paid' ? (
    <Button onClick={showBuy} variant="contained" style={{ fontSize: '1rem', minWidth: '100px', margin: '0 0 10px 0', backgroundColor: '#e37547' }}>
      Buy
    </Button>
  ) : (
    <Button  variant="contained" style={{ fontSize: '1rem', minWidth: '100px', margin: '0 0 10px 0', backgroundColor: '#e37547' }}>
      Pay
    </Button>
  )} */}
      </div>
      {showUpdateDomainName ? (
        <UpdateDomainName
          showUpdateDomainName={showUpdateDomainName}
          toggleUpdateDomainName={toggleUpdateDomainName}
          update_domain={update_domain}
          keys={keys}
          setKeys={setKeys}
        />
      ) : null}

      {open ? (
        <CreateApiKey
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          keys={keys}
          setKeys={setKeys}
          handleClickOpen={handleClickOpen}
        />
      ) : null}

      {showDeleteModal ? (
        <DeleteModal
          toggleDeleteModal={toggleDeleteModal}
          delete_id={delete_id}
          keys={keys}
          setKeys={setKeys}
          showDeleteModal={showDeleteModal}
        />
      ) : null}
      {showRegenerateModal ? (
        <RegenerateModal
          showRegenerateModal={showRegenerateModal}
          toggleRegenerateModal={toggleRegenerateModal}
          keys={keys}
          setKeys={setKeys}
          regenerate_id={regenerate_id}
        />
      ) : null}

      {show ? <Buy show={show} toggle={toggle} /> : null}

      {keys.length < 1 ? (
        <Button variant='outlined' onClick={handleClickOpen}>
          Create Access Token
        </Button>
      ) : null}

      {isLoading ? (
        <Box style={{ position: 'relative' }}>
          <CircularProgress
            size={40}
            left={-20}
            top={10}
            status={'loading'}
            style={{ marginLeft: '50%' }}
          />
        </Box>
      ) : (
        <TableContainer style={{ border: '1px solid #ccc', marginTop: '20px' }}>
          <Table style={{ minWidth: 650 }} size='small'>
            <TableHead style={{ backgroundColor: '#F4EFED ' }}>
              <TableRow>
                <TableCell>S.N</TableCell>
                <TableCell>Alias</TableCell>
                <TableCell>Domain Name</TableCell>
                {/* <TableCell> Counter</TableCell> */}
                {/* <TableCell> Expire</TableCell> */}
                <TableCell> Access Token</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Expire At</TableCell>
                <TableCell> Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {keys.map((key, index) => (
                <TableRow key={key._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{key.alias}</TableCell>
                  <TableCell>{key.domain_name}</TableCell>
                  {/* <TableCell>{key.counter}</TableCell> */}
                  {/* <TableCell>
                    {key.expire ? (
                      <Button>
                        <DoneOutlineIcon />{' '}
                      </Button>
                    ) : (
                      <Button>
                        <RxCross2 size='30' />
                      </Button>
                    )}
                  </TableCell> */}
                  <TableCell>
                    {' '}
                    {isAccessKeyVisible ? (
                      <>
                        <AiFillEyeInvisible
                          onClick={handleAccessKeyVisibility}
                          className='text-blue'
                          style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                          title='Show Access Key'
                        />{' '}
                      </>
                    ) : (
                      <>
                        <AiFillEye
                          onClick={handleAccessKeyVisibility}
                          className='text-blue'
                          style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                          title='Hide Access Key'
                        />
                        {key.access_key}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    {key.createdAt && key.createdAt !== null
                      ? key.createdAt.split('T')[0]
                      : ''}
                  </TableCell>
                  <TableCell>
                    {key.expiration_date && key.expiration_date !== null
                      ? key.expiration_date.split('T')[0]
                      : ''}
                  </TableCell>

                  <TableCell>
                    <Button>
                      <AiFillDelete
                        onClick={(e) => deleteKey(key._id)}
                        style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                        title='Delete'
                      />
                    </Button>
                    <Button>
                      <GrUpdate
                        onClick={(e) => regenerateApiKey(key._id)}
                        className='text-blue'
                        style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                        title='Regenerate'
                      />
                    </Button>
                    <Button>
                      <MdUpdate
                        onClick={(e) => updateDomain(key)}
                        className='text-blue'
                        style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                        title='Update Domain Name'
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default CApiKey;
