import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { TiTickOutline } from 'react-icons/ti';
import { AiOutlineSearch, AiOutlineCloseCircle } from 'react-icons/ai';
import VerifyBusiness from './VerifyBusiness';
import RejectBusiness from './RejectBusiness';
import InspectBusiness from './InspectBusiness';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const Detail = ({ showdetail, detailData, toggle }) => {
  const baseUrl = `${process.env.REACT_APP_EMPLOYEE_URL}`;
  // console.log('detailData', detailData.photo);
  const [showVerfyBusiness, setShowVerifyBusiness] = useState(false);
  const toggleVerifyBusiness = () => setShowVerifyBusiness(!showVerfyBusiness);
  const [showRejectBusiness, setShowRejectBusiness] = useState(false);
  const toggleRejectBusiness = () => setShowRejectBusiness(!showRejectBusiness);
  const [showInspect, setShowInspect] = useState(false);
  const toggleInspect = () => setShowInspect(!showInspect);

  const confirmBusiness = () => {
    setShowVerifyBusiness(true);
  };
  const rejectBusiness = () => {
    setShowRejectBusiness(true);
  };
  const confirmInspect = () => {
    setShowInspect(true);
  };
  return (
    <div>
      {showVerfyBusiness ? (
        <VerifyBusiness
          showVerfyBusiness={showVerfyBusiness}
          toggleVerifyBusiness={toggleVerifyBusiness}
          detailData={detailData}
        />
      ) : null}
      {showRejectBusiness ? (
        <RejectBusiness
          showRejectBusiness={showRejectBusiness}
          toggleRejectBusiness={toggleRejectBusiness}
          detailData={detailData}
        />
      ) : null}
      {showInspect ? (
        <InspectBusiness
          showInspect={showInspect}
          toggleInspect={toggleInspect}
          detailData={detailData}
        />
      ) : null}
      <Dialog
        open={showdetail}
        onClose={toggle}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          style: {
            minWidth: '90%', // Adjust as needed for desired width
            maxWidth: '90%', // Adjust as needed for desired width
            maxHeight: '95vh', // Adjust as needed for desired height
            overflowY: 'auto', // Enable vertical scrolling if needed
          },
        }}
      >
        <DialogTitle id='alert-dialog-title'>{'Business Detail'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <div
              style={{
                border: '.1px solid #999999',
                borderRadius: '20px',
                padding: '10px',
                hight: '10px',
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                    }}
                  >
                    <Grid>Business name</Grid>
                    <Grid> {detailData.businessName}</Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                    }}
                  ></Grid>
                  <Grid>Category</Grid>
                  <Grid> {detailData.category}</Grid>
                </Grid>
              </Grid>
              <p
                style={{
                  fontSize: '20px',
                  marginTop: '1rem',
                  marginBottom: '5px',
                }}
              >
                Address Line 1
              </p>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  Province
                </Grid>
                <Grid item xs={4}>
                  Municipality
                </Grid>
                <Grid item xs={4}>
                  Ward
                </Grid>
              </Grid>
              <p
                style={{
                  fontSize: '20px',
                  marginTop: '1rem',
                  marginBottom: '5px',
                }}
              >
                Address Line 2
              </p>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  Building Name
                </Grid>
                <Grid item xs={4}>
                  Floor
                </Grid>
                <Grid item xs={4}>
                  Apartment Number
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: '10px' }}>
                <Grid item xs={4}>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                    }}
                  ></Grid>
                  <Grid> Longitude and Latitude</Grid>
                  <Grid>
                    {detailData.lng},{detailData.lat}
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  360 Image
                </Grid>
                <Grid item xs={4}>
                  360 Image Pin
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: '3px' }}>
                <Grid item xs={4}>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                    }}
                  ></Grid>
                  <Grid> Contact</Grid>
                  <Grid>{detailData.contactInfo}</Grid>
                </Grid>
                <Grid item xs={4}>
                  Email
                </Grid>
                <Grid item xs={4}>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                    }}
                  ></Grid>
                  <Grid> Website</Grid>
                  <Grid>{detailData.website}</Grid>
                  Website
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: '1px' }}>
                <Grid item xs={4}>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                    }}
                  ></Grid>
                  <Grid> Is it place/office/store?</Grid>
                  <Grid>{detailData.place_office_store ? 'Yes' : 'No'}</Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                    }}
                  ></Grid>
                  <Grid> Delivery Service</Grid>
                  <Grid>{detailData.deliveryService ? 'Yes' : 'No'}</Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                    }}
                  ></Grid>
                  <Grid> Is Parking Service Available?</Grid>
                  <Grid>{detailData.parkingAvailable ? 'Yes' : 'No'}</Grid>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: '1px' }}>
                <Grid item xs={4}>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                    }}
                  ></Grid>
                  <Grid> Opening Days</Grid>
                  <Grid>{detailData.openingDays}</Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                    }}
                  ></Grid>
                  <Grid> Opening Hours</Grid>
                  <Grid>{detailData.openingHours}</Grid>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ marginTop: '3px' }}>
                <Grid item xs={12}>
                  Photo
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={4}
                  style={{ marginTop: '.5rem', marginLeft: '.5rem' }}
                >
                  {detailData.photos !== null &&
                  detailData.photos !== undefined ? (
                    <>
                      {JSON.parse(detailData.photos).map((photo, index) => (
                        <Zoom>
                          <img
                            key={index}
                            src={baseUrl + 'images/' + photo.image}
                            style={{
                              marginLeft: '10px',
                              maxWidth: '100%',
                              height: '30rem',
                              width: '10rem',
                            }}
                          />
                        </Zoom>
                      ))}
                    </>
                  ) : (
                    'N/A'
                  )}
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '.5rem',
          }}
        >
          <div style={{ gap: '4px' }}>
            {detailData.fromverify === true ? (
              <>
                {' '}
                <Button
                  onClick={rejectBusiness}
                  autoFocus
                  style={{
                    backgroundColor: 'white', // Green color from the Material-UI color palette
                    color: '#FF3F2D', // Text color
                    alignItems: 'center',
                    gap: '8px', // Gap between icon and text
                    marginLeft: '5px',
                    paddingLeft: '12px', // Add some padding to the left side
                  }}
                >
                  <AiOutlineCloseCircle /> Reject
                </Button>
              </>
            ) : null}
            {detailData.frominspect === true ? (
              <>
                {' '}
                <Button
                  onClick={confirmBusiness}
                  autoFocus
                  style={{
                    backgroundColor: '#32C949', // Green color from the Material-UI color palette
                    color: 'white', // Text color
                    // display: 'flex',
                    alignItems: 'center',
                    gap: '8px', // Gap between icon and text
                    paddingLeft: '12px', // Add some padding to the left side
                  }}
                >
                  <TiTickOutline /> Verify
                </Button>
                <Button
                  onClick={rejectBusiness}
                  autoFocus
                  style={{
                    backgroundColor: 'white', // Green color from the Material-UI color palette
                    color: '#FF3F2D', // Text color
                    alignItems: 'center',
                    gap: '8px', // Gap between icon and text
                    marginLeft: '5px',
                    paddingLeft: '12px', // Add some padding to the left side
                  }}
                >
                  <AiOutlineCloseCircle /> Reject
                </Button>
              </>
            ) : null}
            {detailData.fromrequest === true ? (
              <>
                <Button
                  onClick={confirmBusiness}
                  autoFocus
                  style={{
                    backgroundColor: '#32C949', // Green color from the Material-UI color palette
                    color: 'white', // Text color
                    // display: 'flex',
                    alignItems: 'center',
                    gap: '8px', // Gap between icon and text
                    paddingLeft: '12px', // Add some padding to the left side
                  }}
                >
                  <TiTickOutline /> Verify
                </Button>
                <Button
                  onClick={confirmInspect}
                  autoFocus
                  style={{
                    backgroundColor: 'white', // Green color from the Material-UI color palette
                    color: '#FFAA00', // Text color
                    border: '2px solid #FFAA00', // Border color and width
                    borderRadius: '4px',
                    alignItems: 'center',
                    marginLeft: '8px',
                    gap: '8px', // Gap between icon and text
                    paddingLeft: '12px', // Add some padding to the left side
                  }}
                >
                  <AiOutlineSearch /> Inspect
                </Button>
                <Button
                  onClick={rejectBusiness}
                  autoFocus
                  style={{
                    backgroundColor: 'white', // Green color from the Material-UI color palette
                    color: '#FF3F2D', // Text color
                    alignItems: 'center',
                    gap: '8px', // Gap between icon and text
                    marginLeft: '5px',
                    paddingLeft: '12px', // Add some padding to the left side
                  }}
                >
                  <AiOutlineCloseCircle /> Reject
                </Button>
              </>
            ) : null}
            {detailData.fromrejected === true ? (
              <>
                {' '}
                <Button
                  onClick={confirmBusiness}
                  autoFocus
                  style={{
                    backgroundColor: '#32C949', // Green color from the Material-UI color palette
                    color: 'white', // Text color
                    // display: 'flex',
                    alignItems: 'center',
                    gap: '8px', // Gap between icon and text
                    paddingLeft: '12px', // Add some padding to the left side
                  }}
                >
                  <TiTickOutline /> Verify
                </Button>
                <Button
                  onClick={confirmInspect}
                  autoFocus
                  style={{
                    backgroundColor: 'white', // Green color from the Material-UI color palette
                    color: '#FFAA00', // Text color
                    border: '2px solid #FFAA00', // Border color and width
                    borderRadius: '4px',
                    alignItems: 'center',
                    marginLeft: '8px',
                    gap: '8px', // Gap between icon and text
                    paddingLeft: '12px', // Add some padding to the left side
                  }}
                >
                  <AiOutlineSearch /> Inspect
                </Button>
              </>
            ) : null}
          </div>
          <Button onClick={toggle} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Detail;
