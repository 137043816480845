import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import axiosInstance from '../../../utils/axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Button from '@mui/material/Button';
import Pay from './Pay';

const AllInvoice = () => {
  const [email, setEmail] = useState('');
  const [results, setResults] = useState([]);
  const [error, setError] = useState('');
  const [showConfirmPayment, setShowConfirmPayment] = useState(false);
  const toggleConfirm = () => setShowConfirmPayment(!showConfirmPayment);
  const [invoiceId, setInvoiceId] = useState('');
  useEffect(() => {
    const searchInvoicesByEmail = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/users/searchUserByEmailForInvoice?email=${email}`,
        );

        // Assuming the response contains the list of invoices
        const { data } = response;
        setResults(data);
      } catch (error) {
        if (error) {
          setError('No data found');
        }
      }
    };
    if (email) {
      searchInvoicesByEmail();
    } else {
      setResults([]);
      setError('');
    }
  }, [email]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const confirmPayment = (_id) => {
    // console.log(_id);
    setShowConfirmPayment(true);
    setInvoiceId(_id);
  };
  return (
    <div>
      {showConfirmPayment ? (
        <Pay
          showConfirmPayment={showConfirmPayment}
          toggleConfirm={toggleConfirm}
          invoiceId={invoiceId}
        />
      ) : null}
      <TextField
        // fullWidth
        style={{ width: '40rem', marginTop: '1rem' }}
        variant='outlined'
        // label='Search'
        placeholder='Search invoice via email address'
        value={email}
        onChange={handleEmailChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {results.length > 0 ? (
        <div style={{ marginTop: '4rem' }}>
          <h2>Search Results:</h2>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>From Date</TableCell>
                  <TableCell>To Date</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((invoice) => (
                  <TableRow key={invoice._id}>
                    <TableCell>{invoice.customer.username}</TableCell>
                    <TableCell>{invoice.customer.email}</TableCell>
                    <TableCell>{invoice.status}</TableCell>
                    <TableCell>
                      {invoice.from_date !== null
                        ? invoice.from_date.split('T')[0]
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {invoice.to_date !== null
                        ? invoice.to_date.split('T')[0]
                        : 'N/A'}
                    </TableCell>

                    <TableCell>
                      {invoice.total !== null
                        ? invoice.total.toFixed(2)
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={(e) => confirmPayment(invoice._id)}
                        variant='contained'
                        style={{
                          fontSize: '.8rem',
                          minWidth: '100px',
                          //   margin: '0 0 10px 0',
                          backgroundColor: '#e37547',
                          marginTop: '1rem',
                        }}
                      >
                        Pay
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <>
          <div style={{ textAlign: 'center', marginTop: '4rem' }}>
            <p>{error}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default AllInvoice;
