import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ConfirmDelete from './ConfirmDelete';
const ShowAllDepartments = ({
  showallDepartment,
  department,
  setDepartment,
  toggleShowAllDepartments,
}) => {
  const baseUrl = `${process.env.REACT_APP_EMPLOYEE_URL}`;
  const [deleteId, setDeleteId] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const toggleDelete = () => setShowDelete(!showDelete);

  const handleDelete = (_id) => {
    setDeleteId(_id);
    setShowDelete(true);
  };

  return (
    <div>
      {showDelete ? (
        <ConfirmDelete
          showDelete={showDelete}
          toggleDelete={toggleDelete}
          deleteId={deleteId}
          department={department}
          setDepartment={setDepartment}
        />
      ) : null}
      <Dialog
        open={showallDepartment}
        keepMounted
        onClose={toggleShowAllDepartments}
        maxWidth='lg'
        fullWidth
        style={{ minHeight: '600px' }}
        aria-describedby='alert-dialog-slide-description'
      >
        <Grid container spacing={2}>
          {department.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <Card
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: '2rem',
                }}
              >
                <IconButton
                  style={{ marginLeft: 'auto', marginTop: '2rem' }}
                  onClick={() => handleDelete(item._id)} // Replace with your delete function
                >
                  <DeleteIcon style={{ color: 'red' }} />
                </IconButton>
                {item.logo !== null && item.logo !== undefined ? (
                  <>
                    {item.logo && item.logo.length > 0 ? (
                      <>
                        <Zoom>
                          <CardMedia
                            component='img'
                            alt={item.actualname}
                            height='100'
                            image={baseUrl + 'images/' + item.logo[0].image}
                            style={{ objectFit: 'contain' }}
                          />
                        </Zoom>
                      </>
                    ) : null}
                  </>
                ) : null}

                <CardContent style={{ flex: 1 }}>
                  <Typography variant='h5' component='div'>
                    {item.actualname}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <DialogActions>
          <Button onClick={toggleShowAllDepartments}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShowAllDepartments;
