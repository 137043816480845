import React,{useState,useEffect} from 'react'
import { Dialog } from '@material-ui/core';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { FormControl } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import axiosInstance from '../../../utils/axios';
import esewa from "./Esewa.png"
import { v4 as uuidv4 } from 'uuid';
import Khalti from './Khalti';
import KhaltiCheckout from "khalti-checkout-web";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Buy = ({show,toggle}) => {

  const [amount, setAmount] = useState('');
  const [points,setCreditPoints]=useState(0);
  const [isLoading,setIsLoading]=useState(false);
  const [errors, setErrors] = useState([]);
  const uniqueId = uuidv4();
  const [showKhalti,setShowKhalti]=useState(false);
  const toggleKhalti =()=>setShowKhalti(!showKhalti);

  const handlePointChange = (event) => {
    const value = event.target.value;
    setCreditPoints(value);
  };
  
  useEffect(() => {
    const calculateCredits = async () => {
      try {
        // Make an API call to calculate credit points
        const response = await axiosInstance.post('/v1/calculate-credits?calculate=amount', { amount: points });
        setAmount(response.data.data.total);
      } catch (error) {
        console.error('Error calculating credits:', error);
      }
    };
  
    calculateCredits();
  }, [points]);

  const displayKhalti=()=>{
    // setShowKhalti(true);
    let config = {
      // replace this key with yours
      "publicKey": "test_public_key_dc74e0fd57cb46cd93832aee0a390234",
      "productIdentity": "1234567890",
      "productName": "Drogon",
      "productUrl": "http://gameofthrones.com/buy/Dragons",
      "eventHandler": {
          onSuccess (payload) {
              // hit merchant api for initiating verfication
              console.log(payload);
          },
          // onError handler is optional
          onError (error) {
              // handle errors
              console.log(error);
          },
          onClose () {
              console.log('widget is closing');
          }
      },
      "paymentPreference": ["KHALTI", "EBANKING","MOBILE_BANKING", "CONNECT_IPS", "SCT"],
  };
  
  let checkout = new KhaltiCheckout(config);
  checkout.show({amount: 1000});

  }

  return (
    <div>
      {showKhalti?<Khalti showKhalti={showKhalti} toggleKhalti={toggleKhalti} amount={amount} points={points}/>:null}
    <Dialog
     open={show}
     TransitionComponent={Transition}
     keepMounted
     onClose={toggle}
     aria-describedby="alert-dialog-slide-email"
   >
     <DialogTitle>{"Enter credit points needed to calculate price"}</DialogTitle>
     {isLoading?<Box style={{position: 'relative'}}>
        <CircularProgress
        size={40}
        left={-20}
        top={10}
        status={'loading'}
        style={{marginLeft: '50%'}}
        />
      </Box>:<>
       <DialogContent>
       <DialogContentText id="alert-dialog-slide-email">
       </DialogContentText>

         <Box
           sx={{
             display: 'flex',
             alignItems: 'center',
             '& > :not(style)': { m: 2 },
           }}
         >
           <FormControl>
             {errors.map(error => {
               return <Alert severity="error">{error.message}</Alert>

             })}
              <TextField
               placeholder='Enter credit points'
               type="number"
               label="Enter credit point needed"
               value={points}
               onChange={handlePointChange}
              />
              <br />

              <p>{points} Credit points costs:  <span style={{ fontSize:"1.5rem" }}>Rs {amount}</span>  </p>  
             <br/>
              <p>Pay via esewa</p>
              <form action="https://uat.esewa.com.np/epay/main" method="POST">
                  <input value={amount} name="tAmt" type="hidden" />
                  <input value={amount} name="amt" type="hidden" />
                  <input value="0" name="txAmt" type="hidden" />
                  <input value="0" name="psc" type="hidden" />
                  <input value="0" name="pdc" type="hidden" />
                  <input value="EPAYTEST" name="scd" type="hidden"/>
                  <input value={uniqueId} name="pid" type="hidden"/>
                  <input value={`${process.env.REACT_APP_BASE_URL}esewa?q=su&points=${points}`} type="hidden" name="su"/>
                  <input value={`${process.env.REACT_APP_BASE_URL}esewa_fail?q=fu`} type="hidden" name="fu"/>
                  <label htmlFor="submitButton">
                  <img src={esewa} alt="Submit" style={{ width: '6rem', height: '6rem' }}/>
                  <input id="submitButton" type="submit" style={{ display: 'none' }} />
                  </label>
              </form>
              {/* <button onClick={displayKhalti}>Khalti</button> */}
           </FormControl>
           


         </Box>
     </DialogContent>
     <DialogActions>
     {/* <Button  variant="contained" style={{ fontSize: '1rem', minWidth: '100px', margin: '0 0 10px 0' , backgroundColor: '#e37547'
 }}>
    Buy
  </Button> */}
       <Button style={{ fontSize: '1rem'}} onClick={toggle}>Cancel</Button>
     </DialogActions>
     </>
     }
    
   </Dialog>
 </div>
  )
}

export default Buy